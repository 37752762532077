@import "../foundation/setting";


.c-color-main {
	color: $color-maintone01;
}
.c-color-white {
	color: $color-white;
}
.c-color-black {
	color: $color-black;
}
.c-color-yellow {
	color: $color-yellow;
}
.c-color-orange {
	color: $color-orange;
}
.c-color-attention {
	color: $color-red;
}
.u-color-graytone01 {
	color: $color-graytone01;
}
.u-color-graytone02 {
	color: $color-graytone02;
}
.u-color-graytone04 {
	color: $color-graytone04;
}