@import "../../foundation/setting";

.mfp-bg {
	z-index: 10001;
}

.mfp-wrap {
	z-index: 10001;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 1;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: auto;
}

.mfp-container {
	display: table;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	&::before {
		display: none;
	}
}

.mfp-content {
	position: relative;
	display: table-cell;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	padding: 10rem 0;
	vertical-align: middle;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	&.o-wrapper {
		width: 100%;
		@include mq('min') {
			max-width: calc(960px + 60px);
			margin-right: auto;
			margin-left: auto;
		}
	}
}

.mfp-figure-wrap {
	text-align: center;
}

.mfp-figure {
	position: relative;
	display: inline-block;
}

.mfp-figure:after {
	top: 0;
	bottom: 0;
	display: none;
}

img.mfp-img {
	padding-top: 0;
	padding-bottom: 0;
}

.mfp-bottom-bar {
	position: relative;
	margin-top: 1rem;
	line-height: 1.6;
}

.mfp-title {
	color: $color-white;
}

.mfp-counter {
	position: absolute;
	position: absolute;
	top: 44px;
	right: 80px;
	display: flex;
	align-items: center;
	color: $color-white;
	font-size: 1rem;
	line-height: inherit;
	@include mq('max') {
		top: 38px;
	}
	.line {
		width: 12px;
		height: 1px;
		margin: 0 7px;
		background: #999999;
		opacity: 0.2;
	}
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
	user-select: none;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: default;
}

.mfp-iframe-scaler {
	position: relative;
}

.mfp-iframe-scaler-wrap {
	position: relative;
}

.mfp-title {
	margin-top: 1.5rem;
	padding-right: 0;
	font-size: 1rem;
	line-height: inherit;
}

.mfp-inner {
	width: 100%;
	max-width: calc(96rem + 6rem);
	margin: auto;
	padding-right: 3rem;
	padding-left: 3rem;
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}
