@import "../foundation/setting";


.u-float-l-pc {
	@include mq('min') {
		float: left !important;
	}
}

.u-float-r-pc {
	@include mq('min') {
		float: right !important;
	}
}

.u-float-l-sp {
	@include mq('max') {
		float: left !important;
	}
}

.u-float-r-sp {
	@include mq('max') {
		float: right !important;
	}
}

.u-float-l {
	float: left !important;
}

.u-float-r {
	float: right !important;
}
