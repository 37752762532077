@import "../foundation/setting";

.c-arrow-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	color: $color-white;
	font-size: 1rem;
	background-color: $color-maintone01;
	border-radius: 50%;
	@include mq('max') {
		width: 1.8rem;
		height: 1.8rem;
		.c-icon-arrow-left,
		.c-icon-arrow-right,
		.c-icon-arrow-top {
			transform-origin: center center;
		}
		.c-icon-arrow-left,
		.c-icon-arrow-right {
			transform: scale(.75);
		}
		.c-icon-arrow-top {
			transform: scale(.65);
		}
	}
}
