@import "../foundation/setting";

.c-link-icon {
	display: flex;
	align-items: center;
    justify-content: center;
	transition: all 0.2s;
	.c-link-icon__text {
		padding-right: 1.8rem;
	}
	i {
		width: 3.2rem;
		height: 3.2rem;
		font-size: 0.8rem;
		color: $color-white;
		background-color: $color-maintone01;
		border-radius: 50%;
		position: relative;
		flex-shrink: 0;
		&::before {
			position: absolute;
			top: 50%;
			right: 50%;
			transform: translate(50%, -50%);
		}
	}
	&:hover {
		opacity: 0.7;
	}
}

.c-link-underline {
	color: $color-maintone01;
	text-decoration: underline;
	transition: all .3s;
	&:hover {
		opacity: .7;
	}
}

.c-text-link {
	&:hover {
		text-decoration: underline;
		color: $color-maintone01;
	}
	i {
		padding-right: 1rem;
	}
}