@import "../../foundation/setting";

/* fade */
.swiper-fade {
  &.swiper-free-mode {
    .swiper-slide {
      transition-timing-function: ease-out;
    }
  }
  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    .swiper-slide {
      pointer-events: none;
    }
  }
  .swiper-slide-active {
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}

/* container */
.p-swiper {
  margin: 0 3rem;
  position: relative;
  @include mq('max') {
    margin: 0 1.5rem;
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    text-align: left;
    bottom: 4rem;
    left: 7rem;
    z-index: 1;
    @include mq('max') {
      bottom: 3.5rem;
      right: 50%;
      transform: translateX(50%);
    }
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  .swiper-pagination-bullet {
    display: block;
    line-height: 0;
    background: none;
    position: relative;
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid $color-white;
      background: $color-white;
      position: absolute;
      top: 10.5px;
      left: 10.5px;
      transition: all .3s;
    }
    svg {
      transform: rotate(-90deg);
      circle {
        fill: transparent;
        stroke: $color-maintone01;
        stroke-width: 1;
        stroke-dasharray: 0 94.2;
      }
    }
    &-active {
      &::before {
        border: 1px solid $color-maintone01;
        background: $color-maintone01;
      }
      svg {
        circle {
          animation: circle 2.5s forwards;
        }
      }
    }
  }
}

@keyframes circle { // 0以外の数字計算「直径 × 3．14」
  0% {
    stroke-dasharray: 0 94.2;
  }
  100% {
    stroke-dasharray: 94.2 94.2;
  }
}