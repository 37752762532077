@import "../foundation/setting";

.l-header {
	position: absolute;
    top: 0;
    left: 0;
	z-index: 999;
	width: 100%;
	transition: transform 0.25s cubic-bezier(0.17, 0.84, 0.44, 1);
	background-color: $color-white;
	@include mq("max") {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.is-scroll-down {
	@include mq("max") {
	}
}

.l-header__inner {
	z-index: 9999;
}

.l-header__content {
	display: flex;
	flex: 1 1 0%;
	align-items: center;
	justify-content: space-between;
	width: 100%;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
	margin-right: auto;
	margin-left: auto;
	background-color: $color-white;
	&[data-scroll="true"] {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		.l-header__body-inner {
			flex-direction: row-reverse;
			align-items: center;
		}
		.l-header__gnav-list {
			margin-top: 0;
			margin-right: 0;
		}
		.l-header__logo {
			img {
				@include mq("min") {
					width: 5rem;
					margin: 2rem;
				}
			}
		}
	}
	&.is-down {
		animation: down 0.5s forwards;
	}
	@include mq("max") {
		position: relative;
		align-items: center;
		min-height: 60px;
		padding-right: 20px;
		padding-left: 20px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

@keyframes down{
	from {
	  	opacity: 0;
		transform: translateY(-100px);
	}
	to {
	  	opacity: 1;
		transform: translateY(0);
	}
}
  

.l-header__body {
	width: 100%;
	@include mq("max") {
		width: fit-content;
	}
}

.l-header__body-inner {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: inherit;
	@include mq("max") {
		display: none;
	}
}


/*logo
---------------------------------------------------------- */
.l-header__logo {
	z-index: 9999;
	width: 4rem;
	@include mq('min') {
		position: absolute;
		top: 0;
		left: 0;
		background-color: $color-white;
		box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
		border-radius: 0 0 2rem 0;
		width: auto;
		img {
			margin: 3rem;
		}
	}
	.c-header__logo-link {
		display: block;
		width: 100%;
		height: 100%;
	}
}

/*button-list
---------------------------------------------------------- */
.l-header__button-list {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.l-header__button {
	padding: .5rem 2rem;
	font-size: 1.5rem;
	font-weight: bold;
	color: $color-white;
	border: 1px solid $color-red;
	border-radius: 50px;
	background-color: $color-red;
	display: flex;
	align-items: center;
	margin: 0 auto;
	margin-right: 1.8rem;
	transition: all 0.2s;
	position: relative;
	i {
		font-size: 2.5rem;
		padding-right: 1rem;
	}
	&.\-outline {
		background-color: $color-white;
		color: $color-red;
	}
	&.\-bg-blue {
		background-color: $color-maintone01;
		border: 1px solid $color-maintone01;
	}
	&.\-blue {
		background-color: $color-white;
		color: $color-maintone01;			
		border: 1px solid $color-maintone01;
		i {
			font-size: 1.5rem;
			position: absolute;
			top: 50%;
			right: 1rem;
			transform: translateY(-50%);
			@include mq("max") {
				font-size: 1rem;
				right: 0.5rem;
			}
		}
	}
	&.\-blue-icon {
		background-color: $color-white;
		color: $color-maintone01;			
		border: 1px solid $color-maintone01;
	}
	&:hover {
		opacity: 0.7;
	}
	@include mq("max") {
		margin-right: 0;
		padding: 1.5rem 2rem;
	}
}

/*gnav
---------------------------------------------------------- */
.l-header__gnav-list {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: -3.3rem;
	margin-right: -3.3rem;
	margin-top: 2rem;
	font-size: 1.6rem;
	font-weight: 800;
	.l-header__gnav-item {
		padding-left: 3.3rem;
		padding-right: 3.3rem;
		transition: all .2s;
		line-height: 1;
		&:hover {
			color: $color-maintone01;
		}
	}
}

.l-header__contact {
	@include mq("min") {
		margin-left: 40px;
	}
}
.c-contact-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border: 1px solid $color-maintone01;
	border-radius: 50%;
	@include mq("max") {
		width: 60%;
		min-height: 50px;
		margin: 0 auto;
		border: 1px solid $color-white;
		border-radius: 0;
		color: $color-white;
	}
}

/*l-header__sp
---------------------------------------------------------- */
.l-header__sp {
	display: flex;
	@include mq("min") {
		display: none;
	}
}	

.l-header__sp-button-list {
	display: flex;
	font-size: 4.5rem;
	color: $color-maintone01;
	li {
		margin-right: 3rem;
	}
	font-size: 3rem;
	@media screen and ( max-width: 350px ) {
		li {
			margin-right: 1.5rem;
		}
	}
}

/*hamburger
---------------------------------------------------------- */
.l-header__hamburger {
	position: relative;
	z-index: 9999;
	display: block;
	width: 5rem;
	height: 5rem;
	margin: auto;
	text-align: center;
	background-color: $color-maintone01;
	border-radius: 50%;
	@include mq("min") {
		display: none;
	}
}

.l-header__hamburger {
	&[aria-expanded="true"] {
		.l-header__hamburger-bar {
			&:nth-child(1) {
				transform: translate(0) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translate(0) rotate(45deg);
			}
		}
	}
}

.l-header__hamburger-button {
	text-align: center;
	display: block;
	background-color: $color-maintone01;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	@include mq('min', 'lg') {
		margin: auto;
	}
	@include mq('max', 'md') {
		width: 60px;
		height: 60px;
	}
	@media screen and (max-width: 1536px) and (min-width: 1025px) {
		width: 60px;
		height: 60px;
	}
	&[aria-expanded="true"] {
		.l-header__hamburger-bar {
			&:nth-child(1) {
				transform: translate(0) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translate(0) rotate(45deg);
			}
		}
	}
}

.l-header__hamburger-bars {
	position: relative;
	display: block;
	width: 3rem;
	height: 2.6rem;
	margin: auto;
	@include mq('max') {
		width: 2.2rem;
		height: 1.8rem;
	}
}

.l-header__hamburger-bar {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 2px;
	margin-top: -1px;
	background-color: $color-white;
	transition: 0.3s;
	transition-property: opacity, transform;
	&:nth-child(1) {
		transform: translateY(-1.2rem);
		@include mq('max') {
			transform: translateY(-0.8rem);
		}
	}
	&:nth-child(3) {
		transform: translateY(1.2rem);
		@include mq('max') {
			transform: translateY(0.8rem);
		}
	}
}

.l-header__menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	transition: all .3s;
	background: $color-maintone03;
	visibility: hidden;
	opacity: 0;
	overflow: scroll;
	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
	}
	.l-header__button-list {
		flex-direction: column;
		.l-header__button-item {
			width: 100%;
			margin-bottom: 1rem;
			.l-header__button {
				justify-content: center;
			}
		}
	}
}

.l-header__menu-inner {
	padding: 100px 20px;
}

.l-header__menu-list {
	margin-top: 25px;
	margin-bottom: 50px;
	font-size: 2.1rem;
	font-weight: 500;
	line-height: 1;
	z-index: 1;
}
.l-header__menu-item {
	margin-bottom: 40px;
	a {
		display: flex;
		align-items: baseline;
	}
	.c-link-strong__text {
		position: relative;
		padding-left: 2.4rem;
		&::after {
			content: '';
			display: block;
			width: 1.3rem;
			height: 0.4rem;
			background-color: #1999B8;
			border-radius: 50px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}
}