@import "../foundation/setting";

/*size
---------------------------------------------------------- */
.c-text-24 {
	font-size: 2,4rem;
	@include line-height(18, 12);
	@include mq("max") {
		font-size: 1.1rem;
		@include line-height(15, 11);
	}
}

.c-text-14 {
	font-size: 1.4rem;
	@include line-height(22, 14);
	@include mq("max") {
		font-size: 1.2rem;
		@include line-height(17, 12);
	}
}

.c-text-12 {
	font-size: 1.2rem;
	@include line-height(18, 12);
	@include mq("max") {
		font-size: 1.1rem;
		@include line-height(15, 11);
	}
}

@include mq("min") {
	.c-text-14-pc {
		font-size: 1.4rem;
		@include line-height(24, 14);
	}
	.c-text-12-pc {
		font-size: 1.2rem;
		@include line-height(18, 12);
	}
}

@include mq("max") {
	.c-text-14-sp {
		font-size: 1.2rem;
		@include line-height(17, 12);
	}
	.c-text-12-sp {
		font-size: 1.1rem;
		@include line-height(15, 11);
	}
}