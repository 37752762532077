@import "../foundation/setting";


.u-block {
	display: block;
}

.u-inline-block {
	display: inline-block;
}

.u-table {
	display: table;
}

.u-table-cell {
	display: table-cell;
}

.u-flex {
	display: flex;
}

.u-flex-justify-c {
	justify-content: center;
}

.u-flex-justify-sb {
	justify-content: space-between;
}

.u-flex-justify-e {
	justify-content: flex-end;
}

.u-flex-align-items-c {
	align-items: center;
}

.u-flex-align-items-s {
	align-items: stretch;
}

.u-flex-align-items-e {
	align-items: flex-end;
}

.u-flex-align-content-c {
	align-content: center;
}

.u-flex-align-content-sb {
	align-content: space-between;
}

.u-flex-align-self {
	align-self: flex-end;
}

.u-flex-wrap {
	flex-wrap: wrap;
}


@include mq('min') {
	.u-block-pc {
		display: block;
	}
	.u-inline-block-pc {
		display: inline-block;
	}
	.u-table-pc {
		display: table;
	}
	.u-table-cell-pc {
		display: table-cell;
	}
	.u-flex-pc {
		display: flex;
	}
	.u-flex-justify-c-pc {
		justify-content: center;
	}
	.u-flex-justify-sb-pc {
		justify-content: space-between;
	}
	.u-flex-align-items-c-pc {
		align-items: center;
	}
	.u-flex-align-items-s-pc {
		align-items: stretch;
	}
	.u-flex-align-items-e-pc {
		align-items: flex-end;
	}
	.u-flex-align-content-c-pc {
		align-content: center;
	}
	.u-flex-align-content-sb-pc {
		align-content: space-between;
	}
	.u-flex-align-self-pc {
		align-self: flex-end;
	}
	.u-flex-wrap-pc {
		flex-wrap: wrap;
	}
	.u-vertical-t-pc {
		vertical-align: top;
	}
	.u-vertical-m-pc {
		vertical-align: middle;
	}
	.u-vertical-bl-pc {
		vertical-align: baseline;
	}
	.u-vertical-b-pc {
		vertical-align: bottom;
	}
}

@include mq('max') {
	.u-block-sp {
		display: block;
	}
	.u-inline-block-sp {
		display: inline-block;
	}
	.u-table-sp {
		display: table;
	}
	.u-table-cell-sp {
		display: table-cell;
	}
	.u-flex-sp {
		display: flex;
	}
	.u-flex-justify-c-sp {
		justify-content: center;
	}
	.u-flex-justify-sb-sp {
		justify-content: space-between;
	}
	.u-flex-align-items-c-sp {
		align-items: center;
	}
	.u-flex-align-items-s-sp {
		align-items: stretch;
	}
	.u-flex-align-items-e-sp {
		align-items: flex-end;
	}
	.u-flex-align-content-c-sp {
		align-content: center;
	}
	.u-flex-align-content-sb-sp {
		align-content: space-between;
	}
	.u-flex-align-self-sp {
		align-self: flex-end;
	}
	.u-flex-wrap-sp {
		flex-wrap: wrap;
	}
	.u-vertical-t-sp {
		vertical-align: top;
	}
	.u-vertical-m-sp {
		vertical-align: middle;
	}
	.u-vertical-bl-sp {
		vertical-align: baseline;
	}
	.u-vertical-b-sp {
		vertical-align: bottom;
	}
}