@import "../foundation/setting";

.c-card {
    border-radius: 20px;
    overflow: hidden;
    &.\-gray {
        border: 3px solid $color-graytone02;
        .c-card__title {
            background-color: $color-graytone02;
        }
    }
    &.\-blue {
        border: 3px solid $color-maintone01;
        .c-card__title {
            color: $color-white;
            background-color: $color-maintone01;
        }
    }
}

.c-card__title {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1.5rem;
    text-align: center;
    @include mq('max') {
        font-size: 1.6rem;
    }
}

.c-card__content {
    padding: 3rem;
    @include mq('max') {
        padding: 2rem 1.5rem;
    }
}