@use "foundation/setting" as *;

/*フェードイン
---------------------------------------------------------- */
.o-effect-fade-in {
    will-change: opacity, transform;
    opacity: 0;
    transition: opacity .6s ease;
    transition-delay: .3s;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    &.is-active {
        opacity: 1;
    }
}

/*下からフェードイン
---------------------------------------------------------- */
.o-effect-fade-in-up {
    will-change: opacity, transform;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.6s ease, opacity 0.6s ease;
    transform: translate3d(0,30%,0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    &.is-active {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0,0,0);
        -webkit-backface-visibility:hidden;
        backface-visibility:hidden;
    }
}


/*一文字ずつ下からフェードイン
---------------------------------------------------------- */
.o-effect-sprit-word-wrap:not(.c-page-title .o-effect-sprit-word-wrap) {
    width: 100%;
    &.is-active {
        .o-effect-sprit-word { 
            opacity: 1;
            visibility: visible;
            transform: translate3d(0,0,0);
            -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
        }
    }
}

.o-effect-sprit-word {
    will-change: opacity, transform;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: transform 1.5s, opacity 0.3s;
    transition-timing-function: $cubic-bezier;
    transform: translate3d(0,100%,0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}