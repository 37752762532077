@use "foundation/setting" as *;

.o-grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	&[data-columns~="auto,1fr"] {
		grid-template-columns: auto 1fr;
	}
	&[data-columns~="10,1fr"] {
		grid-template-columns: repeat(10, 1fr);
	}
	&[data-columns~="5,1fr"] {
		grid-template-columns: repeat(5, 1fr);
	}
	&[data-columns~="4,1fr"] {
		grid-template-columns: repeat(4, 1fr);
	}
	&[data-columns~="3,1fr"] {
		grid-template-columns: repeat(3, 1fr);
	}
	&[data-columns~="2,1fr"] {
		grid-template-columns: repeat(2, 1fr);
	}
	&[data-columns~="1fr,auto"] {
		grid-template-columns: 1fr auto;
	}
	&[data-columns~="auto,auto"] {
		grid-template-columns: auto auto;
	}
	&[data-columns~="auto,auto,auto"] {
		grid-template-columns: auto auto auto;
	}
	&[data-columns~="auto,auto,auto,auto"] {
		grid-template-columns: auto auto auto auto;
	}
	&[data-gap~="40"] {
		grid-row-gap: 40px;
		grid-column-gap: 40px;
	}
	&[data-gap~="30"] {
		grid-row-gap: 30px;
		grid-column-gap: 30px;
	}
	&[data-gap~="20"] {
		grid-row-gap: 20px;
		grid-column-gap: 20px;
	}
	&[data-gap~="15"] {
		grid-row-gap: 15px;
		grid-column-gap: 15px;
	}
	&[data-gap~="10"] {
		grid-row-gap: 10px;
		grid-column-gap: 10px;
	}
	&[data-gap~="4"] {
		grid-row-gap: 4px;
		grid-column-gap: 4px;
	}
	@include mq("min") {
		&[data-columns~="pc:auto,1fr"] {
			grid-template-columns: auto 1fr;
		}
		&[data-columns~="pc:1fr,auto"] {
			grid-template-columns: 1fr auto;
		}
		&[data-columns~="pc:2,1fr"] {
			grid-template-columns: repeat(2, 1fr);
		}
		&[data-columns~="pc:3,1fr"] {
			grid-template-columns: repeat(3, 1fr);
		}
		&[data-columns~="pc:auto,auto"] {
			grid-template-columns: auto auto;
		}
		&[data-columns~="pc:auto,auto,auto"] {
			grid-template-columns: auto auto auto;
		}
		&[data-columns~="pc:auto,auto,auto,auto"] {
			grid-template-columns: auto auto auto auto;
		}
		&[data-gap~="pc:70"] {
			grid-row-gap: 70px;
			grid-column-gap: 70px;
		}
		&[data-gap~="pc:60"] {
			grid-row-gap: 60px;
			grid-column-gap: 60px;
		}
		&[data-gap~="pc:50"] {
			grid-row-gap: 50px;
			grid-column-gap: 50px;
		}
		&[data-gap~="pc:40"] {
			grid-row-gap: 40px;
			grid-column-gap: 40px;
		}
		&[data-gap~="pc:30"] {
			grid-row-gap: 30px;
			grid-column-gap: 30px;
		}
		&[data-gap~="pc:20"] {
			grid-row-gap: 20px;
			grid-column-gap: 20px;
		}
		&[data-gap~="pc:15"] {
			grid-row-gap: 15px;
			grid-column-gap: 15px;
		}
		&[data-gap~="pc:10"] {
			grid-row-gap: 10px;
			grid-column-gap: 10px;
		}
		&[data-gap~="pc:5"] {
			grid-row-gap: 5px;
			grid-column-gap: 5px;
		}
	}
	@include mq("max") {
		&[data-columns~="sp:1fr"] {
			grid-template-columns: 1fr;
		}
		&[data-columns~="sp:2,1fr"] {
			grid-template-columns: repeat(2, 1fr);
		}
		&[data-columns~="sp:auto,1fr"] {
			grid-template-columns: auto 1fr;
		}
		&[data-columns~="sp:1fr,auto"] {
			grid-template-columns: 1fr auto;
		}
		&[data-columns~="sp:auto,auto"] {
			grid-template-columns: auto auto;
		}
		&[data-columns~="sp:auto,auto,auto"] {
			grid-template-columns: auto auto auto;
		}
		&[data-columns~="sp:auto,auto,auto,auto"] {
			grid-template-columns: auto auto auto auto;
		}
		&[data-gap~="sp:50"] {
			grid-row-gap: 50px;
			grid-column-gap: 50px;
		}
		&[data-gap~="sp:40"] {
			grid-row-gap: 40px;
			grid-column-gap: 40px;
		}
		&[data-gap~="sp:40,0"] {
			grid-row-gap: 40px;
			grid-column-gap: 0px;
		}
		&[data-gap~="sp:30"] {
			grid-row-gap: 30px;
			grid-column-gap: 30px;
		}
		&[data-gap~="sp:20"] {
			grid-row-gap: 20px;
			grid-column-gap: 20px;
		}
		&[data-gap~="sp:15"] {
			grid-row-gap: 15px;
			grid-column-gap: 15px;
		}
		&[data-gap~="sp:10"] {
			grid-row-gap: 10px;
			grid-column-gap: 10px;
		}
		&[data-gap~="sp:5"] {
			grid-row-gap: 5px;
			grid-column-gap: 5px;
		}
	}
	@include mq('max', 'sm') {
		&[data-columns~="smsp:1fr"] {
			grid-template-columns: 1fr;
		}
		&[data-gap~="smsp:15"] {
			grid-row-gap: 15px;
			grid-column-gap: 15px;
		}
		&[data-gap~="smsp:10"] {
			grid-row-gap: 10px;
			grid-column-gap: 10px;
		}
		&[data-gap~="smsp:5"] {
			grid-row-gap: 5px;
			grid-column-gap: 5px;
		}
	}
}

.o-grid-item-1 {
	grid-column: auto/span 1;
}
.o-grid-item-2 {
	grid-column: auto/span 2;
}
.o-grid-item-3 {
	grid-column: auto/span 3;
}
.o-grid-item-4 {
	grid-column: auto/span 4;
}
.o-grid-item-5 {
	grid-column: auto/span 5;
}
.o-grid-item-6 {
	grid-column: auto/span 6;
}
.o-grid-item-7 {
	grid-column: auto/span 7;
}
.o-grid-item-8 {
	grid-column: auto/span 8;
}
.o-grid-item-9 {
	grid-column: auto/span 9;
}
.o-grid-item-10 {
	grid-column: auto/span 10;
}
.o-grid-item-11 {
	grid-column: auto/span 11;
}
.o-grid-item-12 {
	grid-column: auto/span 12;
}
.o-grid-item-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
.o-grid-item-start {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.o-grid-item-end {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@include mq("min") {
	.o-grid-item-1-pc {
		grid-column: auto/span 1;
	}
	.o-grid-item-2-pc {
		grid-column: auto/span 2;
	}
	.o-grid-item-3-pc {
		grid-column: auto/span 3;
	}
	.o-grid-item-4-pc {
		grid-column: auto/span 4;
	}
	.o-grid-item-5-pc {
		grid-column: auto/span 5;
	}
	.o-grid-item-6-pc {
		grid-column: auto/span 6;
	}
	.o-grid-item-7-pc {
		grid-column: auto/span 7;
	}
	.o-grid-item-8-pc {
		grid-column: auto/span 8;
	}
	.o-grid-item-9-pc {
		grid-column: auto/span 9;
	}
	.o-grid-item-10-pc {
		grid-column: auto/span 10;
	}
	.o-grid-item-11-pc {
		grid-column: auto/span 11;
	}
	.o-grid-item-12-pc {
		grid-column: auto/span 12;
	}
	.o-grid-item-center-pc {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.o-grid-item-start-pc {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.o-grid-item-end-pc {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

@include mq("max") {
	.o-grid-item-1-sp {
		grid-column: auto/span 1;
	}
	.o-grid-item-2-sp {
		grid-column: auto/span 2;
	}
	.o-grid-item-3-sp {
		grid-column: auto/span 3;
	}
	.o-grid-item-4-sp {
		grid-column: auto/span 4;
	}
	.o-grid-item-5-sp {
		grid-column: auto/span 5;
	}
	.o-grid-item-6-sp {
		grid-column: auto/span 6;
	}
	.o-grid-item-7-sp {
		grid-column: auto/span 7;
	}
	.o-grid-item-8-sp {
		grid-column: auto/span 8;
	}
	.o-grid-item-9-sp {
		grid-column: auto/span 9;
	}
	.o-grid-item-10-sp {
		grid-column: auto/span 10;
	}
	.o-grid-item-11-sp {
		grid-column: auto/span 11;
	}
	.o-grid-item-12-sp {
		grid-column: auto/span 12;
	}
	.o-grid-item-center-sp {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.o-grid-item-start-sp {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.o-grid-item-end-sp {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
