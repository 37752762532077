@import "../foundation/setting";

/*横幅100vwコンテンツ
---------------------------------------------------------- */
.u-w100vw {
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    z-index: 9;
}

.u-width-10  { width:  1rem !important; }
.u-width-20  { width:  2rem !important; }
.u-width-30  { width:  3rem !important; }
.u-width-40  { width:  4rem !important; }
.u-width-50  { width:  5rem !important; }
.u-width-60  { width:  6rem !important; }
.u-width-70  { width:  7rem !important; }
.u-width-80  { width:  8rem !important; }
.u-width-90  { width:  9rem !important; }
.u-width-100 { width: 10rem !important; }
.u-width-110 { width: 11rem !important; }
.u-width-120 { width: 12rem !important; }
.u-width-130 { width: 13rem !important; }
.u-width-140 { width: 14rem !important; }
.u-width-150 { width: 15rem !important; }
.u-width-160 { width: 16rem !important; }
.u-width-170 { width: 17rem !important; }
.u-width-180 { width: 18rem !important; }
.u-width-190 { width: 19rem !important; }
.u-width-200 { width: 20rem !important; }
.u-width-210 { width: 21rem !important; }
.u-width-220 { width: 22rem !important; }
.u-width-230 { width: 23rem !important; }
.u-width-240 { width: 24rem !important; }
.u-width-250 { width: 25rem !important; }
.u-width-260 { width: 26rem !important; }
.u-width-270 { width: 27rem !important; }
.u-width-280 { width: 28rem !important; }
.u-width-290 { width: 29rem !important; }
.u-width-300 { width: 30rem !important; }
.u-width-310 { width: 31rem !important; }
.u-width-320 { width: 32rem !important; }
.u-width-330 { width: 33rem !important; }
.u-width-340 { width: 34rem !important; }
.u-width-350 { width: 35rem !important; }
.u-width-360 { width: 36rem !important; }
.u-width-370 { width: 37rem !important; }
.u-width-380 { width: 38rem !important; }
.u-width-390 { width: 39rem !important; }
.u-width-400 { width: 40rem !important; }
.u-width-410 { width: 41rem !important; }
.u-width-420 { width: 42rem !important; }
.u-width-430 { width: 43rem !important; }
.u-width-440 { width: 44rem !important; }
.u-width-450 { width: 45rem !important; }
.u-width-460 { width: 46rem !important; }
.u-width-470 { width: 47rem !important; }
.u-width-480 { width: 48rem !important; }
.u-width-490 { width: 49rem !important; }
.u-width-500 { width: 50rem !important; }
.u-width-510 { width: 51rem !important; }
.u-width-520 { width: 52rem !important; }
.u-width-530 { width: 53rem !important; }
.u-width-540 { width: 54rem !important; }
.u-width-550 { width: 55rem !important; }
.u-width-560 { width: 56rem !important; }
.u-width-570 { width: 57rem !important; }
.u-width-580 { width: 58rem !important; }
.u-width-590 { width: 59rem !important; }
.u-width-600 { width: 60rem !important; }
.u-width-610 { width: 61rem !important; }
.u-width-620 { width: 62rem !important; }
.u-width-630 { width: 63rem !important; }
.u-width-640 { width: 64rem !important; }
.u-width-650 { width: 65rem !important; }
.u-width-660 { width: 66rem !important; }
.u-width-670 { width: 67rem !important; }
.u-width-680 { width: 68rem !important; }
.u-width-690 { width: 69rem !important; }
.u-width-700 { width: 70rem !important; }
.u-width-710 { width: 71rem !important; }
.u-width-720 { width: 72rem !important; }
.u-width-730 { width: 73rem !important; }
.u-width-740 { width: 74rem !important; }
.u-width-750 { width: 75rem !important; }
.u-width-760 { width: 76rem !important; }
.u-width-770 { width: 77rem !important; }
.u-width-780 { width: 78rem !important; }
.u-width-790 { width: 79rem !important; }
.u-width-800 { width: 80rem !important; }
.u-width-810 { width: 81rem !important; }
.u-width-820 { width: 82rem !important; }
.u-width-830 { width: 83rem !important; }
.u-width-840 { width: 84rem !important; }
.u-width-850 { width: 85rem !important; }
.u-width-860 { width: 86rem !important; }
.u-width-870 { width: 87rem !important; }
.u-width-880 { width: 88rem !important; }
.u-width-890 { width: 89rem !important; }
.u-width-900 { width: 90rem !important; }
.u-width-910 { width: 91rem !important; }
.u-width-920 { width: 92rem !important; }
.u-width-930 { width: 93rem !important; }
.u-width-940 { width: 94rem !important; }
.u-width-950 { width: 95rem !important; }
.u-width-960 { width: 96rem !important; }
.u-width-970 { width: 97rem !important; }
.u-width-980 { width: 98rem !important; }
.u-width-990 { width: 99rem !important; }
.u-width-1000 { width: 100rem !important; }

.u-max-width-10  { max-width:  1rem !important; }
.u-max-width-20  { max-width:  2rem !important; }
.u-max-width-30  { max-width:  3rem !important; }
.u-max-width-40  { max-width:  4rem !important; }
.u-max-width-50  { max-width:  5rem !important; }
.u-max-width-60  { max-width:  6rem !important; }
.u-max-width-70  { max-width:  7rem !important; }
.u-max-width-80  { max-width:  8rem !important; }
.u-max-width-90  { max-width:  9rem !important; }
.u-max-width-100 { max-width: 10rem !important; }
.u-max-width-110 { max-width: 11rem !important; }
.u-max-width-120 { max-width: 12rem !important; }
.u-max-width-130 { max-width: 13rem !important; }
.u-max-width-140 { max-width: 14rem !important; }
.u-max-width-150 { max-width: 15rem !important; }
.u-max-width-160 { max-width: 16rem !important; }
.u-max-width-170 { max-width: 17rem !important; }
.u-max-width-180 { max-width: 18rem !important; }
.u-max-width-190 { max-width: 19rem !important; }
.u-max-width-200 { max-width: 20rem !important; }
.u-max-width-210 { max-width: 21rem !important; }
.u-max-width-220 { max-width: 22rem !important; }
.u-max-width-230 { max-width: 23rem !important; }
.u-max-width-240 { max-width: 24rem !important; }
.u-max-width-250 { max-width: 25rem !important; }
.u-max-width-260 { max-width: 26rem !important; }
.u-max-width-270 { max-width: 27rem !important; }
.u-max-width-280 { max-width: 28rem !important; }
.u-max-width-290 { max-width: 29rem !important; }
.u-max-width-300 { max-width: 30rem !important; }
.u-max-width-310 { max-width: 31rem !important; }
.u-max-width-320 { max-width: 32rem !important; }
.u-max-width-330 { max-width: 33rem !important; }
.u-max-width-340 { max-width: 34rem !important; }
.u-max-width-350 { max-width: 35rem !important; }
.u-max-width-360 { max-width: 36rem !important; }
.u-max-width-370 { max-width: 37rem !important; }
.u-max-width-380 { max-width: 38rem !important; }
.u-max-width-390 { max-width: 39rem !important; }
.u-max-width-400 { max-width: 40rem !important; }
.u-max-width-410 { max-width: 41rem !important; }
.u-max-width-420 { max-width: 42rem !important; }
.u-max-width-430 { max-width: 43rem !important; }
.u-max-width-440 { max-width: 44rem !important; }
.u-max-width-450 { max-width: 45rem !important; }
.u-max-width-460 { max-width: 46rem !important; }
.u-max-width-470 { max-width: 47rem !important; }
.u-max-width-480 { max-width: 48rem !important; }
.u-max-width-490 { max-width: 49rem !important; }
.u-max-width-500 { max-width: 50rem !important; }
.u-max-width-510 { max-width: 51rem !important; }
.u-max-width-520 { max-width: 52rem !important; }
.u-max-width-530 { max-width: 53rem !important; }
.u-max-width-540 { max-width: 54rem !important; }
.u-max-width-550 { max-width: 55rem !important; }
.u-max-width-560 { max-width: 56rem !important; }
.u-max-width-570 { max-width: 57rem !important; }
.u-max-width-580 { max-width: 58rem !important; }
.u-max-width-590 { max-width: 59rem !important; }
.u-max-width-600 { max-width: 60rem !important; }
.u-max-width-610 { max-width: 61rem !important; }
.u-max-width-620 { max-width: 62rem !important; }
.u-max-width-630 { max-width: 63rem !important; }
.u-max-width-640 { max-width: 64rem !important; }
.u-max-width-650 { max-width: 65rem !important; }
.u-max-width-660 { max-width: 66rem !important; }
.u-max-width-670 { max-width: 67rem !important; }
.u-max-width-680 { max-width: 68rem !important; }
.u-max-width-690 { max-width: 69rem !important; }
.u-max-width-700 { max-width: 70rem !important; }
.u-max-width-710 { max-width: 71rem !important; }
.u-max-width-720 { max-width: 72rem !important; }
.u-max-width-730 { max-width: 73rem !important; }
.u-max-width-740 { max-width: 74rem !important; }
.u-max-width-750 { max-width: 75rem !important; }
.u-max-width-760 { max-width: 76rem !important; }
.u-max-width-770 { max-width: 77rem !important; }
.u-max-width-780 { max-width: 78rem !important; }
.u-max-width-790 { max-width: 79rem !important; }
.u-max-width-800 { max-width: 80rem !important; }
.u-max-width-810 { max-width: 81rem !important; }
.u-max-width-820 { max-width: 82rem !important; }
.u-max-width-830 { max-width: 83rem !important; }
.u-max-width-840 { max-width: 84rem !important; }
.u-max-width-850 { max-width: 85rem !important; }
.u-max-width-860 { max-width: 86rem !important; }
.u-max-width-870 { max-width: 87rem !important; }
.u-max-width-880 { max-width: 88rem !important; }
.u-max-width-890 { max-width: 89rem !important; }
.u-max-width-900 { max-width: 90rem !important; }
.u-max-width-910 { max-width: 91rem !important; }
.u-max-width-920 { max-width: 92rem !important; }
.u-max-width-930 { max-width: 93rem !important; }
.u-max-width-940 { max-width: 94rem !important; }
.u-max-width-950 { max-width: 95rem !important; }
.u-max-width-960 { max-width: 96rem !important; }
.u-max-width-970 { max-width: 97rem !important; }
.u-max-width-980 { max-width: 98rem !important; }
.u-max-width-990 { max-width: 99rem !important; }
.u-max-width-1000 { max-width: 100rem !important; }

.u-width-1per  { width:  1% !important; }
.u-width-2per  { width:  2% !important; }
.u-width-3per  { width:  3% !important; }
.u-width-4per  { width:  4% !important; }
.u-width-5per  { width:  5% !important; }
.u-width-6per  { width:  6% !important; }
.u-width-7per  { width:  7% !important; }
.u-width-8per  { width:  8% !important; }
.u-width-9per  { width:  9% !important; }
.u-width-10per { width: 10% !important; }
.u-width-11per { width: 11% !important; }
.u-width-12per { width: 12% !important; }
.u-width-13per { width: 13% !important; }
.u-width-14per { width: 14% !important; }
.u-width-15per { width: 15% !important; }
.u-width-16per { width: 16% !important; }
.u-width-17per { width: 17% !important; }
.u-width-18per { width: 18% !important; }
.u-width-19per { width: 19% !important; }
.u-width-20per { width: 20% !important; }
.u-width-21per { width: 21% !important; }
.u-width-22per { width: 22% !important; }
.u-width-23per { width: 23% !important; }
.u-width-24per { width: 24% !important; }
.u-width-25per { width: 25% !important; }
.u-width-26per { width: 26% !important; }
.u-width-27per { width: 27% !important; }
.u-width-28per { width: 28% !important; }
.u-width-29per { width: 29% !important; }
.u-width-30per { width: 30% !important; }
.u-width-31per { width: 31% !important; }
.u-width-32per { width: 32% !important; }
.u-width-33per { width: 33% !important; }
.u-width-34per { width: 34% !important; }
.u-width-35per { width: 35% !important; }
.u-width-36per { width: 36% !important; }
.u-width-37per { width: 37% !important; }
.u-width-38per { width: 38% !important; }
.u-width-39per { width: 39% !important; }
.u-width-40per { width: 40% !important; }
.u-width-41per { width: 41% !important; }
.u-width-42per { width: 42% !important; }
.u-width-43per { width: 43% !important; }
.u-width-44per { width: 44% !important; }
.u-width-45per { width: 45% !important; }
.u-width-46per { width: 46% !important; }
.u-width-47per { width: 47% !important; }
.u-width-48per { width: 48% !important; }
.u-width-49per { width: 49% !important; }
.u-width-50per { width: 50% !important; }
.u-width-51per { width: 51% !important; }
.u-width-52per { width: 52% !important; }
.u-width-53per { width: 53% !important; }
.u-width-54per { width: 54% !important; }
.u-width-55per { width: 55% !important; }
.u-width-56per { width: 56% !important; }
.u-width-57per { width: 57% !important; }
.u-width-58per { width: 58% !important; }
.u-width-59per { width: 59% !important; }
.u-width-60per { width: 60% !important; }
.u-width-61per { width: 61% !important; }
.u-width-62per { width: 62% !important; }
.u-width-63per { width: 63% !important; }
.u-width-64per { width: 64% !important; }
.u-width-65per { width: 65% !important; }
.u-width-66per { width: 66% !important; }
.u-width-67per { width: 67% !important; }
.u-width-68per { width: 68% !important; }
.u-width-69per { width: 69% !important; }
.u-width-70per { width: 70% !important; }
.u-width-71per { width: 71% !important; }
.u-width-72per { width: 72% !important; }
.u-width-73per { width: 73% !important; }
.u-width-74per { width: 74% !important; }
.u-width-75per { width: 75% !important; }
.u-width-76per { width: 76% !important; }
.u-width-77per { width: 77% !important; }
.u-width-78per { width: 78% !important; }
.u-width-79per { width: 79% !important; }
.u-width-80per { width: 80% !important; }
.u-width-81per { width: 81% !important; }
.u-width-82per { width: 82% !important; }
.u-width-83per { width: 83% !important; }
.u-width-84per { width: 84% !important; }
.u-width-85per { width: 85% !important; }
.u-width-86per { width: 86% !important; }
.u-width-87per { width: 87% !important; }
.u-width-88per { width: 88% !important; }
.u-width-89per { width: 89% !important; }
.u-width-90per { width: 90% !important; }
.u-width-91per { width: 91% !important; }
.u-width-92per { width: 92% !important; }
.u-width-93per { width: 93% !important; }
.u-width-94per { width: 94% !important; }
.u-width-95per { width: 95% !important; }
.u-width-96per { width: 96% !important; }
.u-width-97per { width: 97% !important; }
.u-width-98per { width: 98% !important; }
.u-width-99per { width: 99% !important; }
.u-width-100per{ width: 100% !important; }
.u-width-auto  { width: auto !important; }

.u-max-width-1per  { max-width:  1% !important; }
.u-max-width-2per  { max-width:  2% !important; }
.u-max-width-3per  { max-width:  3% !important; }
.u-max-width-4per  { max-width:  4% !important; }
.u-max-width-5per  { max-width:  5% !important; }
.u-max-width-6per  { max-width:  6% !important; }
.u-max-width-7per  { max-width:  7% !important; }
.u-max-width-8per  { max-width:  8% !important; }
.u-max-width-9per  { max-width:  9% !important; }
.u-max-width-10per { max-width: 10% !important; }
.u-max-width-11per { max-width: 11% !important; }
.u-max-width-12per { max-width: 12% !important; }
.u-max-width-13per { max-width: 13% !important; }
.u-max-width-14per { max-width: 14% !important; }
.u-max-width-15per { max-width: 15% !important; }
.u-max-width-16per { max-width: 16% !important; }
.u-max-width-17per { max-width: 17% !important; }
.u-max-width-18per { max-width: 18% !important; }
.u-max-width-19per { max-width: 19% !important; }
.u-max-width-20per { max-width: 20% !important; }
.u-max-width-21per { max-width: 21% !important; }
.u-max-width-22per { max-width: 22% !important; }
.u-max-width-23per { max-width: 23% !important; }
.u-max-width-24per { max-width: 24% !important; }
.u-max-width-25per { max-width: 25% !important; }
.u-max-width-26per { max-width: 26% !important; }
.u-max-width-27per { max-width: 27% !important; }
.u-max-width-28per { max-width: 28% !important; }
.u-max-width-29per { max-width: 29% !important; }
.u-max-width-30per { max-width: 30% !important; }
.u-max-width-31per { max-width: 31% !important; }
.u-max-width-32per { max-width: 32% !important; }
.u-max-width-33per { max-width: 33% !important; }
.u-max-width-34per { max-width: 34% !important; }
.u-max-width-35per { max-width: 35% !important; }
.u-max-width-36per { max-width: 36% !important; }
.u-max-width-37per { max-width: 37% !important; }
.u-max-width-38per { max-width: 38% !important; }
.u-max-width-39per { max-width: 39% !important; }
.u-max-width-40per { max-width: 40% !important; }
.u-max-width-41per { max-width: 41% !important; }
.u-max-width-42per { max-width: 42% !important; }
.u-max-width-43per { max-width: 43% !important; }
.u-max-width-44per { max-width: 44% !important; }
.u-max-width-45per { max-width: 45% !important; }
.u-max-width-46per { max-width: 46% !important; }
.u-max-width-47per { max-width: 47% !important; }
.u-max-width-48per { max-width: 48% !important; }
.u-max-width-49per { max-width: 49% !important; }
.u-max-width-50per { max-width: 50% !important; }
.u-max-width-51per { max-width: 51% !important; }
.u-max-width-52per { max-width: 52% !important; }
.u-max-width-53per { max-width: 53% !important; }
.u-max-width-54per { max-width: 54% !important; }
.u-max-width-55per { max-width: 55% !important; }
.u-max-width-56per { max-width: 56% !important; }
.u-max-width-57per { max-width: 57% !important; }
.u-max-width-58per { max-width: 58% !important; }
.u-max-width-59per { max-width: 59% !important; }
.u-max-width-60per { max-width: 60% !important; }
.u-max-width-61per { max-width: 61% !important; }
.u-max-width-62per { max-width: 62% !important; }
.u-max-width-63per { max-width: 63% !important; }
.u-max-width-64per { max-width: 64% !important; }
.u-max-width-65per { max-width: 65% !important; }
.u-max-width-66per { max-width: 66% !important; }
.u-max-width-67per { max-width: 67% !important; }
.u-max-width-68per { max-width: 68% !important; }
.u-max-width-69per { max-width: 69% !important; }
.u-max-width-70per { max-width: 70% !important; }
.u-max-width-71per { max-width: 71% !important; }
.u-max-width-72per { max-width: 72% !important; }
.u-max-width-73per { max-width: 73% !important; }
.u-max-width-74per { max-width: 74% !important; }
.u-max-width-75per { max-width: 75% !important; }
.u-max-width-76per { max-width: 76% !important; }
.u-max-width-77per { max-width: 77% !important; }
.u-max-width-78per { max-width: 78% !important; }
.u-max-width-79per { max-width: 79% !important; }
.u-max-width-80per { max-width: 80% !important; }
.u-max-width-81per { max-width: 81% !important; }
.u-max-width-82per { max-width: 82% !important; }
.u-max-width-83per { max-width: 83% !important; }
.u-max-width-84per { max-width: 84% !important; }
.u-max-width-85per { max-width: 85% !important; }
.u-max-width-86per { max-width: 86% !important; }
.u-max-width-87per { max-width: 87% !important; }
.u-max-width-88per { max-width: 88% !important; }
.u-max-width-89per { max-width: 89% !important; }
.u-max-width-90per { max-width: 90% !important; }
.u-max-width-91per { max-width: 91% !important; }
.u-max-width-92per { max-width: 92% !important; }
.u-max-width-93per { max-width: 93% !important; }
.u-max-width-94per { max-width: 94% !important; }
.u-max-width-95per { max-width: 95% !important; }
.u-max-width-96per { max-width: 96% !important; }
.u-max-width-97per { max-width: 97% !important; }
.u-max-width-98per { max-width: 98% !important; }
.u-max-width-99per { max-width: 99% !important; }
.u-max-width-100per { max-width: 100% !important; }
.u-max-width-auto  { max-width: auto !important; }


@include mq('min') {
	
	.u-width-pc-10  { width:  1rem !important; }
	.u-width-pc-20  { width:  2rem !important; }
	.u-width-pc-30  { width:  3rem !important; }
	.u-width-pc-40  { width:  4rem !important; }
	.u-width-pc-50  { width:  5rem !important; }
	.u-width-pc-60  { width:  6rem !important; }
	.u-width-pc-70  { width:  7rem !important; }
	.u-width-pc-80  { width:  8rem !important; }
	.u-width-pc-90  { width:  9rem !important; }
	.u-width-pc-100 { width: 10rem !important; }
	.u-width-pc-110 { width: 11rem !important; }
	.u-width-pc-120 { width: 12rem !important; }
	.u-width-pc-130 { width: 13rem !important; }
	.u-width-pc-140 { width: 14rem !important; }
	.u-width-pc-150 { width: 15rem !important; }
	.u-width-pc-160 { width: 16rem !important; }
	.u-width-pc-170 { width: 17rem !important; }
	.u-width-pc-180 { width: 18rem !important; }
	.u-width-pc-190 { width: 19rem !important; }
	.u-width-pc-200 { width: 20rem !important; }
	.u-width-pc-210 { width: 21rem !important; }
	.u-width-pc-220 { width: 22rem !important; }
	.u-width-pc-230 { width: 23rem !important; }
	.u-width-pc-240 { width: 24rem !important; }
	.u-width-pc-250 { width: 25rem !important; }
	.u-width-pc-260 { width: 26rem !important; }
	.u-width-pc-270 { width: 27rem !important; }
	.u-width-pc-280 { width: 28rem !important; }
	.u-width-pc-290 { width: 29rem !important; }
	.u-width-pc-300 { width: 30rem !important; }
	.u-width-pc-310 { width: 31rem !important; }
	.u-width-pc-320 { width: 32rem !important; }
	.u-width-pc-330 { width: 33rem !important; }
	.u-width-pc-340 { width: 34rem !important; }
	.u-width-pc-350 { width: 35rem !important; }
	.u-width-pc-360 { width: 36rem !important; }
	.u-width-pc-370 { width: 37rem !important; }
	.u-width-pc-380 { width: 38rem !important; }
	.u-width-pc-390 { width: 39rem !important; }
	.u-width-pc-400 { width: 40rem !important; }
	.u-width-pc-410 { width: 41rem !important; }
	.u-width-pc-420 { width: 42rem !important; }
	.u-width-pc-430 { width: 43rem !important; }
	.u-width-pc-440 { width: 44rem !important; }
	.u-width-pc-450 { width: 45rem !important; }
	.u-width-pc-460 { width: 46rem !important; }
	.u-width-pc-470 { width: 47rem !important; }
	.u-width-pc-480 { width: 48rem !important; }
	.u-width-pc-490 { width: 49rem !important; }
	.u-width-pc-500 { width: 50rem !important; }
	.u-width-pc-510 { width: 51rem !important; }
	.u-width-pc-520 { width: 52rem !important; }
	.u-width-pc-530 { width: 53rem !important; }
	.u-width-pc-540 { width: 54rem !important; }
	.u-width-pc-550 { width: 55rem !important; }
	.u-width-pc-560 { width: 56rem !important; }
	.u-width-pc-570 { width: 57rem !important; }
	.u-width-pc-580 { width: 58rem !important; }
	.u-width-pc-590 { width: 59rem !important; }
	.u-width-pc-600 { width: 60rem !important; }
	.u-width-pc-610 { width: 61rem !important; }
	.u-width-pc-620 { width: 62rem !important; }
	.u-width-pc-625 { width: 62.5rem !important; }
	.u-width-pc-630 { width: 63rem !important; }
	.u-width-pc-640 { width: 64rem !important; }
	.u-width-pc-650 { width: 65rem !important; }
	.u-width-pc-660 { width: 66rem !important; }
	.u-width-pc-670 { width: 67rem !important; }
	.u-width-pc-680 { width: 68rem !important; }
	.u-width-pc-690 { width: 69rem !important; }
	.u-width-pc-700 { width: 70rem !important; }
	.u-width-pc-710 { width: 71rem !important; }
	.u-width-pc-720 { width: 72rem !important; }
	.u-width-pc-730 { width: 73rem !important; }
	.u-width-pc-740 { width: 74rem !important; }
	.u-width-pc-750 { width: 75rem !important; }
	.u-width-pc-760 { width: 76rem !important; }
	.u-width-pc-770 { width: 77rem !important; }
	.u-width-pc-780 { width: 78rem !important; }
	.u-width-pc-790 { width: 79rem !important; }
	.u-width-pc-800 { width: 80rem !important; }
	.u-width-pc-810 { width: 81rem !important; }
	.u-width-pc-820 { width: 82rem !important; }
	.u-width-pc-830 { width: 83rem !important; }
	.u-width-pc-840 { width: 84rem !important; }
	.u-width-pc-850 { width: 85rem !important; }
	.u-width-pc-860 { width: 86rem !important; }
	.u-width-pc-870 { width: 87rem !important; }
	.u-width-pc-880 { width: 88rem !important; }
	.u-width-pc-890 { width: 89rem !important; }
	.u-width-pc-900 { width: 90rem !important; }
	.u-width-pc-910 { width: 91rem !important; }
	.u-width-pc-920 { width: 92rem !important; }
	.u-width-pc-930 { width: 93rem !important; }
	.u-width-pc-940 { width: 94rem !important; }
	.u-width-pc-950 { width: 95rem !important; }
	.u-width-pc-960 { width: 96rem !important; }
	.u-width-pc-970 { width: 97rem !important; }
	.u-width-pc-980 { width: 98rem !important; }
	.u-width-pc-990 { width: 99rem !important; }
	.u-width-pc-1000 { width: 100rem !important; }

	.u-max-width-pc-10  { max-width:  1rem !important; }
	.u-max-width-pc-20  { max-width:  2rem !important; }
	.u-max-width-pc-30  { max-width:  3rem !important; }
	.u-max-width-pc-40  { max-width:  4rem !important; }
	.u-max-width-pc-50  { max-width:  5rem !important; }
	.u-max-width-pc-60  { max-width:  6rem !important; }
	.u-max-width-pc-70  { max-width:  7rem !important; }
	.u-max-width-pc-80  { max-width:  8rem !important; }
	.u-max-width-pc-90  { max-width:  9rem !important; }
	.u-max-width-pc-100 { max-width: 10rem !important; }
	.u-max-width-pc-110 { max-width: 11rem !important; }
	.u-max-width-pc-120 { max-width: 12rem !important; }
	.u-max-width-pc-130 { max-width: 13rem !important; }
	.u-max-width-pc-140 { max-width: 14rem !important; }
	.u-max-width-pc-150 { max-width: 15rem !important; }
	.u-max-width-pc-160 { max-width: 16rem !important; }
	.u-max-width-pc-170 { max-width: 17rem !important; }
	.u-max-width-pc-180 { max-width: 18rem !important; }
	.u-max-width-pc-190 { max-width: 19rem !important; }
	.u-max-width-pc-200 { max-width: 20rem !important; }
	.u-max-width-pc-210 { max-width: 21rem !important; }
	.u-max-width-pc-220 { max-width: 22rem !important; }
	.u-max-width-pc-230 { max-width: 23rem !important; }
	.u-max-width-pc-240 { max-width: 24rem !important; }
	.u-max-width-pc-250 { max-width: 25rem !important; }
	.u-max-width-pc-260 { max-width: 26rem !important; }
	.u-max-width-pc-270 { max-width: 27rem !important; }
	.u-max-width-pc-280 { max-width: 28rem !important; }
	.u-max-width-pc-290 { max-width: 29rem !important; }
	.u-max-width-pc-300 { max-width: 30rem !important; }
	.u-max-width-pc-310 { max-width: 31rem !important; }
	.u-max-width-pc-320 { max-width: 32rem !important; }
	.u-max-width-pc-330 { max-width: 33rem !important; }
	.u-max-width-pc-340 { max-width: 34rem !important; }
	.u-max-width-pc-350 { max-width: 35rem !important; }
	.u-max-width-pc-360 { max-width: 36rem !important; }
	.u-max-width-pc-370 { max-width: 37rem !important; }
	.u-max-width-pc-380 { max-width: 38rem !important; }
	.u-max-width-pc-390 { max-width: 39rem !important; }
	.u-max-width-pc-400 { max-width: 40rem !important; }
	.u-max-width-pc-410 { max-width: 41rem !important; }
	.u-max-width-pc-420 { max-width: 42rem !important; }
	.u-max-width-pc-430 { max-width: 43rem !important; }
	.u-max-width-pc-440 { max-width: 44rem !important; }
	.u-max-width-pc-450 { max-width: 45rem !important; }
	.u-max-width-pc-460 { max-width: 46rem !important; }
	.u-max-width-pc-470 { max-width: 47rem !important; }
	.u-max-width-pc-480 { max-width: 48rem !important; }
	.u-max-width-pc-490 { max-width: 49rem !important; }
	.u-max-width-pc-500 { max-width: 50rem !important; }
	.u-max-width-pc-510 { max-width: 51rem !important; }
	.u-max-width-pc-520 { max-width: 52rem !important; }
	.u-max-width-pc-530 { max-width: 53rem !important; }
	.u-max-width-pc-540 { max-width: 54rem !important; }
	.u-max-width-pc-550 { max-width: 55rem !important; }
	.u-max-width-pc-560 { max-width: 56rem !important; }
	.u-max-width-pc-570 { max-width: 57rem !important; }
	.u-max-width-pc-580 { max-width: 58rem !important; }
	.u-max-width-pc-590 { max-width: 59rem !important; }
	.u-max-width-pc-600 { max-width: 60rem !important; }
	.u-max-width-pc-610 { max-width: 61rem !important; }
	.u-max-width-pc-620 { max-width: 62rem !important; }
	.u-max-width-pc-625 { max-width: 62.5rem !important; }
	.u-max-width-pc-630 { max-width: 63rem !important; }
	.u-max-width-pc-640 { max-width: 64rem !important; }
	.u-max-width-pc-650 { max-width: 65rem !important; }
	.u-max-width-pc-660 { max-width: 66rem !important; }
	.u-max-width-pc-670 { max-width: 67rem !important; }
	.u-max-width-pc-680 { max-width: 68rem !important; }
	.u-max-width-pc-690 { max-width: 69rem !important; }
	.u-max-width-pc-700 { max-width: 70rem !important; }
	.u-max-width-pc-710 { max-width: 71rem !important; }
	.u-max-width-pc-720 { max-width: 72rem !important; }
	.u-max-width-pc-730 { max-width: 73rem !important; }
	.u-max-width-pc-740 { max-width: 74rem !important; }
	.u-max-width-pc-750 { max-width: 75rem !important; }
	.u-max-width-pc-760 { max-width: 76rem !important; }
	.u-max-width-pc-770 { max-width: 77rem !important; }
	.u-max-width-pc-780 { max-width: 78rem !important; }
	.u-max-width-pc-790 { max-width: 79rem !important; }
	.u-max-width-pc-800 { max-width: 80rem !important; }
	.u-max-width-pc-810 { max-width: 81rem !important; }
	.u-max-width-pc-820 { max-width: 82rem !important; }
	.u-max-width-pc-830 { max-width: 83rem !important; }
	.u-max-width-pc-840 { max-width: 84rem !important; }
	.u-max-width-pc-850 { max-width: 85rem !important; }
	.u-max-width-pc-860 { max-width: 86rem !important; }
	.u-max-width-pc-870 { max-width: 87rem !important; }
	.u-max-width-pc-880 { max-width: 88rem !important; }
	.u-max-width-pc-890 { max-width: 89rem !important; }
	.u-max-width-pc-900 { max-width: 90rem !important; }
	.u-max-width-pc-910 { max-width: 91rem !important; }
	.u-max-width-pc-920 { max-width: 92rem !important; }
	.u-max-width-pc-930 { max-width: 93rem !important; }
	.u-max-width-pc-940 { max-width: 94rem !important; }
	.u-max-width-pc-950 { max-width: 95rem !important; }
	.u-max-width-pc-960 { max-width: 96rem !important; }
	.u-max-width-pc-970 { max-width: 97rem !important; }
	.u-max-width-pc-980 { max-width: 98rem !important; }
	.u-max-width-pc-990 { max-width: 99rem !important; }
	.u-max-width-pc-1000 { max-width: 100rem !important; }

	.u-width-pc-1per  { width:  1% !important; }
	.u-width-pc-2per  { width:  2% !important; }
	.u-width-pc-3per  { width:  3% !important; }
	.u-width-pc-4per  { width:  4% !important; }
	.u-width-pc-5per  { width:  5% !important; }
	.u-width-pc-6per  { width:  6% !important; }
	.u-width-pc-7per  { width:  7% !important; }
	.u-width-pc-8per  { width:  8% !important; }
	.u-width-pc-9per  { width:  9% !important; }
	.u-width-pc-10per { width: 10% !important; }
	.u-width-pc-11per { width: 11% !important; }
	.u-width-pc-12per { width: 12% !important; }
	.u-width-pc-13per { width: 13% !important; }
	.u-width-pc-14per { width: 14% !important; }
	.u-width-pc-15per { width: 15% !important; }
	.u-width-pc-16per { width: 16% !important; }
	.u-width-pc-17per { width: 17% !important; }
	.u-width-pc-18per { width: 18% !important; }
	.u-width-pc-19per { width: 19% !important; }
	.u-width-pc-20per { width: 20% !important; }
	.u-width-pc-21per { width: 21% !important; }
	.u-width-pc-22per { width: 22% !important; }
	.u-width-pc-23per { width: 23% !important; }
	.u-width-pc-24per { width: 24% !important; }
	.u-width-pc-25per { width: 25% !important; }
	.u-width-pc-26per { width: 26% !important; }
	.u-width-pc-27per { width: 27% !important; }
	.u-width-pc-28per { width: 28% !important; }
	.u-width-pc-29per { width: 29% !important; }
	.u-width-pc-30per { width: 30% !important; }
	.u-width-pc-31per { width: 31% !important; }
	.u-width-pc-32per { width: 32% !important; }
	.u-width-pc-33per { width: 33% !important; }
	.u-width-pc-34per { width: 34% !important; }
	.u-width-pc-35per { width: 35% !important; }
	.u-width-pc-36per { width: 36% !important; }
	.u-width-pc-37per { width: 37% !important; }
	.u-width-pc-38per { width: 38% !important; }
	.u-width-pc-39per { width: 39% !important; }
	.u-width-pc-40per { width: 40% !important; }
	.u-width-pc-41per { width: 41% !important; }
	.u-width-pc-42per { width: 42% !important; }
	.u-width-pc-43per { width: 43% !important; }
	.u-width-pc-44per { width: 44% !important; }
	.u-width-pc-45per { width: 45% !important; }
	.u-width-pc-46per { width: 46% !important; }
	.u-width-pc-47per { width: 47% !important; }
	.u-width-pc-48per { width: 48% !important; }
	.u-width-pc-49per { width: 49% !important; }
	.u-width-pc-50per { width: 50% !important; }
	.u-width-pc-51per { width: 51% !important; }
	.u-width-pc-52per { width: 52% !important; }
	.u-width-pc-53per { width: 53% !important; }
	.u-width-pc-54per { width: 54% !important; }
	.u-width-pc-55per { width: 55% !important; }
	.u-width-pc-56per { width: 56% !important; }
	.u-width-pc-57per { width: 57% !important; }
	.u-width-pc-58per { width: 58% !important; }
	.u-width-pc-59per { width: 59% !important; }
	.u-width-pc-60per { width: 60% !important; }
	.u-width-pc-61per { width: 61% !important; }
	.u-width-pc-62per { width: 62% !important; }
	.u-width-pc-63per { width: 63% !important; }
	.u-width-pc-64per { width: 64% !important; }
	.u-width-pc-65per { width: 65% !important; }
	.u-width-pc-66per { width: 66% !important; }
	.u-width-pc-67per { width: 67% !important; }
	.u-width-pc-68per { width: 68% !important; }
	.u-width-pc-69per { width: 69% !important; }
	.u-width-pc-70per { width: 70% !important; }
	.u-width-pc-71per { width: 71% !important; }
	.u-width-pc-72per { width: 72% !important; }
	.u-width-pc-73per { width: 73% !important; }
	.u-width-pc-74per { width: 74% !important; }
	.u-width-pc-75per { width: 75% !important; }
	.u-width-pc-76per { width: 76% !important; }
	.u-width-pc-77per { width: 77% !important; }
	.u-width-pc-78per { width: 78% !important; }
	.u-width-pc-79per { width: 79% !important; }
	.u-width-pc-80per { width: 80% !important; }
	.u-width-pc-81per { width: 81% !important; }
	.u-width-pc-82per { width: 82% !important; }
	.u-width-pc-83per { width: 83% !important; }
	.u-width-pc-84per { width: 84% !important; }
	.u-width-pc-85per { width: 85% !important; }
	.u-width-pc-86per { width: 86% !important; }
	.u-width-pc-87per { width: 87% !important; }
	.u-width-pc-88per { width: 88% !important; }
	.u-width-pc-89per { width: 89% !important; }
	.u-width-pc-90per { width: 90% !important; }
	.u-width-pc-91per { width: 91% !important; }
	.u-width-pc-92per { width: 92% !important; }
	.u-width-pc-93per { width: 93% !important; }
	.u-width-pc-94per { width: 94% !important; }
	.u-width-pc-95per { width: 95% !important; }
	.u-width-pc-96per { width: 96% !important; }
	.u-width-pc-97per { width: 97% !important; }
	.u-width-pc-98per { width: 98% !important; }
	.u-width-pc-99per { width: 99% !important; }
	.u-width-pc-100per{ width: 100% !important; }
	.u-width-pc-auto  { width: auto !important; }

	.u-max-width-pc-1per  { max-width:  1% !important; }
	.u-max-width-pc-2per  { max-width:  2% !important; }
	.u-max-width-pc-3per  { max-width:  3% !important; }
	.u-max-width-pc-4per  { max-width:  4% !important; }
	.u-max-width-pc-5per  { max-width:  5% !important; }
	.u-max-width-pc-6per  { max-width:  6% !important; }
	.u-max-width-pc-7per  { max-width:  7% !important; }
	.u-max-width-pc-8per  { max-width:  8% !important; }
	.u-max-width-pc-9per  { max-width:  9% !important; }
	.u-max-width-pc-10per { max-width: 10% !important; }
	.u-max-width-pc-11per { max-width: 11% !important; }
	.u-max-width-pc-12per { max-width: 12% !important; }
	.u-max-width-pc-13per { max-width: 13% !important; }
	.u-max-width-pc-14per { max-width: 14% !important; }
	.u-max-width-pc-15per { max-width: 15% !important; }
	.u-max-width-pc-16per { max-width: 16% !important; }
	.u-max-width-pc-17per { max-width: 17% !important; }
	.u-max-width-pc-18per { max-width: 18% !important; }
	.u-max-width-pc-19per { max-width: 19% !important; }
	.u-max-width-pc-20per { max-width: 20% !important; }
	.u-max-width-pc-21per { max-width: 21% !important; }
	.u-max-width-pc-22per { max-width: 22% !important; }
	.u-max-width-pc-23per { max-width: 23% !important; }
	.u-max-width-pc-24per { max-width: 24% !important; }
	.u-max-width-pc-25per { max-width: 25% !important; }
	.u-max-width-pc-26per { max-width: 26% !important; }
	.u-max-width-pc-27per { max-width: 27% !important; }
	.u-max-width-pc-28per { max-width: 28% !important; }
	.u-max-width-pc-29per { max-width: 29% !important; }
	.u-max-width-pc-30per { max-width: 30% !important; }
	.u-max-width-pc-31per { max-width: 31% !important; }
	.u-max-width-pc-32per { max-width: 32% !important; }
	.u-max-width-pc-33per { max-width: 33% !important; }
	.u-max-width-pc-34per { max-width: 34% !important; }
	.u-max-width-pc-35per { max-width: 35% !important; }
	.u-max-width-pc-36per { max-width: 36% !important; }
	.u-max-width-pc-37per { max-width: 37% !important; }
	.u-max-width-pc-38per { max-width: 38% !important; }
	.u-max-width-pc-39per { max-width: 39% !important; }
	.u-max-width-pc-40per { max-width: 40% !important; }
	.u-max-width-pc-41per { max-width: 41% !important; }
	.u-max-width-pc-42per { max-width: 42% !important; }
	.u-max-width-pc-43per { max-width: 43% !important; }
	.u-max-width-pc-44per { max-width: 44% !important; }
	.u-max-width-pc-45per { max-width: 45% !important; }
	.u-max-width-pc-46per { max-width: 46% !important; }
	.u-max-width-pc-47per { max-width: 47% !important; }
	.u-max-width-pc-48per { max-width: 48% !important; }
	.u-max-width-pc-49per { max-width: 49% !important; }
	.u-max-width-pc-50per { max-width: 50% !important; }
	.u-max-width-pc-51per { max-width: 51% !important; }
	.u-max-width-pc-52per { max-width: 52% !important; }
	.u-max-width-pc-53per { max-width: 53% !important; }
	.u-max-width-pc-54per { max-width: 54% !important; }
	.u-max-width-pc-55per { max-width: 55% !important; }
	.u-max-width-pc-56per { max-width: 56% !important; }
	.u-max-width-pc-57per { max-width: 57% !important; }
	.u-max-width-pc-58per { max-width: 58% !important; }
	.u-max-width-pc-59per { max-width: 59% !important; }
	.u-max-width-pc-60per { max-width: 60% !important; }
	.u-max-width-pc-61per { max-width: 61% !important; }
	.u-max-width-pc-62per { max-width: 62% !important; }
	.u-max-width-pc-63per { max-width: 63% !important; }
	.u-max-width-pc-64per { max-width: 64% !important; }
	.u-max-width-pc-65per { max-width: 65% !important; }
	.u-max-width-pc-66per { max-width: 66% !important; }
	.u-max-width-pc-67per { max-width: 67% !important; }
	.u-max-width-pc-68per { max-width: 68% !important; }
	.u-max-width-pc-69per { max-width: 69% !important; }
	.u-max-width-pc-70per { max-width: 70% !important; }
	.u-max-width-pc-71per { max-width: 71% !important; }
	.u-max-width-pc-72per { max-width: 72% !important; }
	.u-max-width-pc-73per { max-width: 73% !important; }
	.u-max-width-pc-74per { max-width: 74% !important; }
	.u-max-width-pc-75per { max-width: 75% !important; }
	.u-max-width-pc-76per { max-width: 76% !important; }
	.u-max-width-pc-77per { max-width: 77% !important; }
	.u-max-width-pc-78per { max-width: 78% !important; }
	.u-max-width-pc-79per { max-width: 79% !important; }
	.u-max-width-pc-80per { max-width: 80% !important; }
	.u-max-width-pc-81per { max-width: 81% !important; }
	.u-max-width-pc-82per { max-width: 82% !important; }
	.u-max-width-pc-83per { max-width: 83% !important; }
	.u-max-width-pc-84per { max-width: 84% !important; }
	.u-max-width-pc-85per { max-width: 85% !important; }
	.u-max-width-pc-86per { max-width: 86% !important; }
	.u-max-width-pc-87per { max-width: 87% !important; }
	.u-max-width-pc-88per { max-width: 88% !important; }
	.u-max-width-pc-89per { max-width: 89% !important; }
	.u-max-width-pc-90per { max-width: 90% !important; }
	.u-max-width-pc-91per { max-width: 91% !important; }
	.u-max-width-pc-92per { max-width: 92% !important; }
	.u-max-width-pc-93per { max-width: 93% !important; }
	.u-max-width-pc-94per { max-width: 94% !important; }
	.u-max-width-pc-95per { max-width: 95% !important; }
	.u-max-width-pc-96per { max-width: 96% !important; }
	.u-max-width-pc-97per { max-width: 97% !important; }
	.u-max-width-pc-98per { max-width: 98% !important; }
	.u-max-width-pc-99per { max-width: 99% !important; }
	.u-max-width-pc-100per { max-width: 100% !important; }
	.u-max-width-pc-auto  { max-width: auto !important; }
}

@include mq('max') {
	
	.u-width-sp-10  { width:  1rem !important; }
	.u-width-sp-20  { width:  2rem !important; }
	.u-width-sp-30  { width:  3rem !important; }
	.u-width-sp-40  { width:  4rem !important; }
	.u-width-sp-50  { width:  5rem !important; }
	.u-width-sp-60  { width:  6rem !important; }
	.u-width-sp-70  { width:  7rem !important; }
	.u-width-sp-80  { width:  8rem !important; }
	.u-width-sp-90  { width:  9rem !important; }
	.u-width-sp-100 { width: 10rem !important; }
	.u-width-sp-110 { width: 11rem !important; }
	.u-width-sp-120 { width: 12rem !important; }
	.u-width-sp-130 { width: 13rem !important; }
	.u-width-sp-140 { width: 14rem !important; }
	.u-width-sp-150 { width: 15rem !important; }
	.u-width-sp-160 { width: 16rem !important; }
	.u-width-sp-170 { width: 17rem !important; }
	.u-width-sp-180 { width: 18rem !important; }
	.u-width-sp-190 { width: 19rem !important; }
	.u-width-sp-200 { width: 20rem !important; }
	.u-width-sp-210 { width: 21rem !important; }
	.u-width-sp-220 { width: 22rem !important; }
	.u-width-sp-230 { width: 23rem !important; }
	.u-width-sp-240 { width: 24rem !important; }
	.u-width-sp-250 { width: 25rem !important; }
	.u-width-sp-260 { width: 26rem !important; }
	.u-width-sp-270 { width: 27rem !important; }
	.u-width-sp-280 { width: 28rem !important; }
	.u-width-sp-290 { width: 29rem !important; }
	.u-width-sp-300 { width: 30rem !important; }
	.u-width-sp-310 { width: 31rem !important; }
	.u-width-sp-320 { width: 32rem !important; }
	.u-width-sp-330 { width: 33rem !important; }
	.u-width-sp-340 { width: 34rem !important; }
	.u-width-sp-350 { width: 35rem !important; }
	.u-width-sp-360 { width: 36rem !important; }
	.u-width-sp-370 { width: 37rem !important; }
	.u-width-sp-380 { width: 38rem !important; }
	.u-width-sp-390 { width: 39rem !important; }
	.u-width-sp-400 { width: 40rem !important; }
	.u-width-sp-410 { width: 41rem !important; }
	.u-width-sp-420 { width: 42rem !important; }
	.u-width-sp-430 { width: 43rem !important; }
	.u-width-sp-440 { width: 44rem !important; }
	.u-width-sp-450 { width: 45rem !important; }
	.u-width-sp-460 { width: 46rem !important; }
	.u-width-sp-470 { width: 47rem !important; }
	.u-width-sp-480 { width: 48rem !important; }
	.u-width-sp-490 { width: 49rem !important; }
	.u-width-sp-500 { width: 50rem !important; }
	.u-width-sp-510 { width: 51rem !important; }
	.u-width-sp-520 { width: 52rem !important; }
	.u-width-sp-530 { width: 53rem !important; }
	.u-width-sp-540 { width: 54rem !important; }
	.u-width-sp-550 { width: 55rem !important; }
	.u-width-sp-560 { width: 56rem !important; }
	.u-width-sp-570 { width: 57rem !important; }
	.u-width-sp-580 { width: 58rem !important; }
	.u-width-sp-590 { width: 59rem !important; }
	.u-width-sp-600 { width: 60rem !important; }
	.u-width-sp-610 { width: 61rem !important; }
	.u-width-sp-620 { width: 62rem !important; }
	.u-width-sp-630 { width: 63rem !important; }
	.u-width-sp-640 { width: 64rem !important; }
	.u-width-sp-650 { width: 65rem !important; }
	.u-width-sp-660 { width: 66rem !important; }
	.u-width-sp-670 { width: 67rem !important; }
	.u-width-sp-680 { width: 68rem !important; }
	.u-width-sp-690 { width: 69rem !important; }
	.u-width-sp-700 { width: 70rem !important; }
	.u-width-sp-710 { width: 71rem !important; }
	.u-width-sp-720 { width: 72rem !important; }
	.u-width-sp-730 { width: 73rem !important; }
	.u-width-sp-740 { width: 74rem !important; }
	.u-width-sp-750 { width: 75rem !important; }
	.u-width-sp-760 { width: 76rem !important; }
	.u-width-sp-770 { width: 77rem !important; }
	.u-width-sp-780 { width: 78rem !important; }
	.u-width-sp-790 { width: 79rem !important; }
	.u-width-sp-800 { width: 80rem !important; }
	.u-width-sp-810 { width: 81rem !important; }
	.u-width-sp-820 { width: 82rem !important; }
	.u-width-sp-830 { width: 83rem !important; }
	.u-width-sp-840 { width: 84rem !important; }
	.u-width-sp-850 { width: 85rem !important; }
	.u-width-sp-860 { width: 86rem !important; }
	.u-width-sp-870 { width: 87rem !important; }
	.u-width-sp-880 { width: 88rem !important; }
	.u-width-sp-890 { width: 89rem !important; }
	.u-width-sp-900 { width: 90rem !important; }
	.u-width-sp-910 { width: 91rem !important; }
	.u-width-sp-920 { width: 92rem !important; }
	.u-width-sp-930 { width: 93rem !important; }
	.u-width-sp-940 { width: 94rem !important; }
	.u-width-sp-950 { width: 95rem !important; }
	.u-width-sp-960 { width: 96rem !important; }
	.u-width-sp-970 { width: 97rem !important; }
	.u-width-sp-980 { width: 98rem !important; }
	.u-width-sp-990 { width: 99rem !important; }
	.u-width-sp-1000 { width: 100rem !important; }

	.u-max-width-sp-10  { max-width:  1rem !important; }
	.u-max-width-sp-20  { max-width:  2rem !important; }
	.u-max-width-sp-30  { max-width:  3rem !important; }
	.u-max-width-sp-40  { max-width:  4rem !important; }
	.u-max-width-sp-50  { max-width:  5rem !important; }
	.u-max-width-sp-60  { max-width:  6rem !important; }
	.u-max-width-sp-70  { max-width:  7rem !important; }
	.u-max-width-sp-80  { max-width:  8rem !important; }
	.u-max-width-sp-90  { max-width:  9rem !important; }
	.u-max-width-sp-100 { max-width: 10rem !important; }
	.u-max-width-sp-110 { max-width: 11rem !important; }
	.u-max-width-sp-120 { max-width: 12rem !important; }
	.u-max-width-sp-130 { max-width: 13rem !important; }
	.u-max-width-sp-140 { max-width: 14rem !important; }
	.u-max-width-sp-150 { max-width: 15rem !important; }
	.u-max-width-sp-160 { max-width: 16rem !important; }
	.u-max-width-sp-170 { max-width: 17rem !important; }
	.u-max-width-sp-180 { max-width: 18rem !important; }
	.u-max-width-sp-190 { max-width: 19rem !important; }
	.u-max-width-sp-200 { max-width: 20rem !important; }
	.u-max-width-sp-210 { max-width: 21rem !important; }
	.u-max-width-sp-220 { max-width: 22rem !important; }
	.u-max-width-sp-230 { max-width: 23rem !important; }
	.u-max-width-sp-240 { max-width: 24rem !important; }
	.u-max-width-sp-250 { max-width: 25rem !important; }
	.u-max-width-sp-260 { max-width: 26rem !important; }
	.u-max-width-sp-270 { max-width: 27rem !important; }
	.u-max-width-sp-280 { max-width: 28rem !important; }
	.u-max-width-sp-290 { max-width: 29rem !important; }
	.u-max-width-sp-300 { max-width: 30rem !important; }
	.u-max-width-sp-310 { max-width: 31rem !important; }
	.u-max-width-sp-320 { max-width: 32rem !important; }
	.u-max-width-sp-330 { max-width: 33rem !important; }
	.u-max-width-sp-340 { max-width: 34rem !important; }
	.u-max-width-sp-350 { max-width: 35rem !important; }
	.u-max-width-sp-360 { max-width: 36rem !important; }
	.u-max-width-sp-370 { max-width: 37rem !important; }
	.u-max-width-sp-380 { max-width: 38rem !important; }
	.u-max-width-sp-390 { max-width: 39rem !important; }
	.u-max-width-sp-400 { max-width: 40rem !important; }
	.u-max-width-sp-410 { max-width: 41rem !important; }
	.u-max-width-sp-420 { max-width: 42rem !important; }
	.u-max-width-sp-430 { max-width: 43rem !important; }
	.u-max-width-sp-440 { max-width: 44rem !important; }
	.u-max-width-sp-450 { max-width: 45rem !important; }
	.u-max-width-sp-460 { max-width: 46rem !important; }
	.u-max-width-sp-470 { max-width: 47rem !important; }
	.u-max-width-sp-480 { max-width: 48rem !important; }
	.u-max-width-sp-490 { max-width: 49rem !important; }
	.u-max-width-sp-500 { max-width: 50rem !important; }
	.u-max-width-sp-510 { max-width: 51rem !important; }
	.u-max-width-sp-520 { max-width: 52rem !important; }
	.u-max-width-sp-530 { max-width: 53rem !important; }
	.u-max-width-sp-540 { max-width: 54rem !important; }
	.u-max-width-sp-550 { max-width: 55rem !important; }
	.u-max-width-sp-560 { max-width: 56rem !important; }
	.u-max-width-sp-570 { max-width: 57rem !important; }
	.u-max-width-sp-580 { max-width: 58rem !important; }
	.u-max-width-sp-590 { max-width: 59rem !important; }
	.u-max-width-sp-600 { max-width: 60rem !important; }
	.u-max-width-sp-610 { max-width: 61rem !important; }
	.u-max-width-sp-620 { max-width: 62rem !important; }
	.u-max-width-sp-630 { max-width: 63rem !important; }
	.u-max-width-sp-640 { max-width: 64rem !important; }
	.u-max-width-sp-650 { max-width: 65rem !important; }
	.u-max-width-sp-660 { max-width: 66rem !important; }
	.u-max-width-sp-670 { max-width: 67rem !important; }
	.u-max-width-sp-680 { max-width: 68rem !important; }
	.u-max-width-sp-690 { max-width: 69rem !important; }
	.u-max-width-sp-700 { max-width: 70rem !important; }
	.u-max-width-sp-710 { max-width: 71rem !important; }
	.u-max-width-sp-720 { max-width: 72rem !important; }
	.u-max-width-sp-730 { max-width: 73rem !important; }
	.u-max-width-sp-740 { max-width: 74rem !important; }
	.u-max-width-sp-750 { max-width: 75rem !important; }
	.u-max-width-sp-760 { max-width: 76rem !important; }
	.u-max-width-sp-770 { max-width: 77rem !important; }
	.u-max-width-sp-780 { max-width: 78rem !important; }
	.u-max-width-sp-790 { max-width: 79rem !important; }
	.u-max-width-sp-800 { max-width: 80rem !important; }
	.u-max-width-sp-810 { max-width: 81rem !important; }
	.u-max-width-sp-820 { max-width: 82rem !important; }
	.u-max-width-sp-830 { max-width: 83rem !important; }
	.u-max-width-sp-840 { max-width: 84rem !important; }
	.u-max-width-sp-850 { max-width: 85rem !important; }
	.u-max-width-sp-860 { max-width: 86rem !important; }
	.u-max-width-sp-870 { max-width: 87rem !important; }
	.u-max-width-sp-880 { max-width: 88rem !important; }
	.u-max-width-sp-890 { max-width: 89rem !important; }
	.u-max-width-sp-900 { max-width: 90rem !important; }
	.u-max-width-sp-910 { max-width: 91rem !important; }
	.u-max-width-sp-920 { max-width: 92rem !important; }
	.u-max-width-sp-930 { max-width: 93rem !important; }
	.u-max-width-sp-940 { max-width: 94rem !important; }
	.u-max-width-sp-950 { max-width: 95rem !important; }
	.u-max-width-sp-960 { max-width: 96rem !important; }
	.u-max-width-sp-970 { max-width: 97rem !important; }
	.u-max-width-sp-980 { max-width: 98rem !important; }
	.u-max-width-sp-990 { max-width: 99rem !important; }
	.u-max-width-sp-1000 { max-width: 100rem !important; }

	.u-width-sp-1per  { width:  1% !important; }
	.u-width-sp-2per  { width:  2% !important; }
	.u-width-sp-3per  { width:  3% !important; }
	.u-width-sp-4per  { width:  4% !important; }
	.u-width-sp-5per  { width:  5% !important; }
	.u-width-sp-6per  { width:  6% !important; }
	.u-width-sp-7per  { width:  7% !important; }
	.u-width-sp-8per  { width:  8% !important; }
	.u-width-sp-9per  { width:  9% !important; }
	.u-width-sp-10per { width: 10% !important; }
	.u-width-sp-11per { width: 11% !important; }
	.u-width-sp-12per { width: 12% !important; }
	.u-width-sp-13per { width: 13% !important; }
	.u-width-sp-14per { width: 14% !important; }
	.u-width-sp-15per { width: 15% !important; }
	.u-width-sp-16per { width: 16% !important; }
	.u-width-sp-17per { width: 17% !important; }
	.u-width-sp-18per { width: 18% !important; }
	.u-width-sp-19per { width: 19% !important; }
	.u-width-sp-20per { width: 20% !important; }
	.u-width-sp-21per { width: 21% !important; }
	.u-width-sp-22per { width: 22% !important; }
	.u-width-sp-23per { width: 23% !important; }
	.u-width-sp-24per { width: 24% !important; }
	.u-width-sp-25per { width: 25% !important; }
	.u-width-sp-26per { width: 26% !important; }
	.u-width-sp-27per { width: 27% !important; }
	.u-width-sp-28per { width: 28% !important; }
	.u-width-sp-29per { width: 29% !important; }
	.u-width-sp-30per { width: 30% !important; }
	.u-width-sp-31per { width: 31% !important; }
	.u-width-sp-32per { width: 32% !important; }
	.u-width-sp-33per { width: 33% !important; }
	.u-width-sp-34per { width: 34% !important; }
	.u-width-sp-35per { width: 35% !important; }
	.u-width-sp-36per { width: 36% !important; }
	.u-width-sp-37per { width: 37% !important; }
	.u-width-sp-38per { width: 38% !important; }
	.u-width-sp-39per { width: 39% !important; }
	.u-width-sp-40per { width: 40% !important; }
	.u-width-sp-41per { width: 41% !important; }
	.u-width-sp-42per { width: 42% !important; }
	.u-width-sp-43per { width: 43% !important; }
	.u-width-sp-44per { width: 44% !important; }
	.u-width-sp-45per { width: 45% !important; }
	.u-width-sp-46per { width: 46% !important; }
	.u-width-sp-47per { width: 47% !important; }
	.u-width-sp-48per { width: 48% !important; }
	.u-width-sp-49per { width: 49% !important; }
	.u-width-sp-50per { width: 50% !important; }
	.u-width-sp-51per { width: 51% !important; }
	.u-width-sp-52per { width: 52% !important; }
	.u-width-sp-53per { width: 53% !important; }
	.u-width-sp-54per { width: 54% !important; }
	.u-width-sp-55per { width: 55% !important; }
	.u-width-sp-56per { width: 56% !important; }
	.u-width-sp-57per { width: 57% !important; }
	.u-width-sp-58per { width: 58% !important; }
	.u-width-sp-59per { width: 59% !important; }
	.u-width-sp-60per { width: 60% !important; }
	.u-width-sp-61per { width: 61% !important; }
	.u-width-sp-62per { width: 62% !important; }
	.u-width-sp-63per { width: 63% !important; }
	.u-width-sp-64per { width: 64% !important; }
	.u-width-sp-65per { width: 65% !important; }
	.u-width-sp-66per { width: 66% !important; }
	.u-width-sp-67per { width: 67% !important; }
	.u-width-sp-68per { width: 68% !important; }
	.u-width-sp-69per { width: 69% !important; }
	.u-width-sp-70per { width: 70% !important; }
	.u-width-sp-71per { width: 71% !important; }
	.u-width-sp-72per { width: 72% !important; }
	.u-width-sp-73per { width: 73% !important; }
	.u-width-sp-74per { width: 74% !important; }
	.u-width-sp-75per { width: 75% !important; }
	.u-width-sp-76per { width: 76% !important; }
	.u-width-sp-77per { width: 77% !important; }
	.u-width-sp-78per { width: 78% !important; }
	.u-width-sp-79per { width: 79% !important; }
	.u-width-sp-80per { width: 80% !important; }
	.u-width-sp-81per { width: 81% !important; }
	.u-width-sp-82per { width: 82% !important; }
	.u-width-sp-83per { width: 83% !important; }
	.u-width-sp-84per { width: 84% !important; }
	.u-width-sp-85per { width: 85% !important; }
	.u-width-sp-86per { width: 86% !important; }
	.u-width-sp-87per { width: 87% !important; }
	.u-width-sp-88per { width: 88% !important; }
	.u-width-sp-89per { width: 89% !important; }
	.u-width-sp-90per { width: 90% !important; }
	.u-width-sp-91per { width: 91% !important; }
	.u-width-sp-92per { width: 92% !important; }
	.u-width-sp-93per { width: 93% !important; }
	.u-width-sp-94per { width: 94% !important; }
	.u-width-sp-95per { width: 95% !important; }
	.u-width-sp-96per { width: 96% !important; }
	.u-width-sp-97per { width: 97% !important; }
	.u-width-sp-98per { width: 98% !important; }
	.u-width-sp-99per { width: 99% !important; }
	.u-width-sp-100per{ width: 100% !important; }
	.u-width-sp-auto  { width: auto !important; }

	.u-max-width-sp-1per  { max-width:  1% !important; }
	.u-max-width-sp-2per  { max-width:  2% !important; }
	.u-max-width-sp-3per  { max-width:  3% !important; }
	.u-max-width-sp-4per  { max-width:  4% !important; }
	.u-max-width-sp-5per  { max-width:  5% !important; }
	.u-max-width-sp-6per  { max-width:  6% !important; }
	.u-max-width-sp-7per  { max-width:  7% !important; }
	.u-max-width-sp-8per  { max-width:  8% !important; }
	.u-max-width-sp-9per  { max-width:  9% !important; }
	.u-max-width-sp-10per { max-width: 10% !important; }
	.u-max-width-sp-11per { max-width: 11% !important; }
	.u-max-width-sp-12per { max-width: 12% !important; }
	.u-max-width-sp-13per { max-width: 13% !important; }
	.u-max-width-sp-14per { max-width: 14% !important; }
	.u-max-width-sp-15per { max-width: 15% !important; }
	.u-max-width-sp-16per { max-width: 16% !important; }
	.u-max-width-sp-17per { max-width: 17% !important; }
	.u-max-width-sp-18per { max-width: 18% !important; }
	.u-max-width-sp-19per { max-width: 19% !important; }
	.u-max-width-sp-20per { max-width: 20% !important; }
	.u-max-width-sp-21per { max-width: 21% !important; }
	.u-max-width-sp-22per { max-width: 22% !important; }
	.u-max-width-sp-23per { max-width: 23% !important; }
	.u-max-width-sp-24per { max-width: 24% !important; }
	.u-max-width-sp-25per { max-width: 25% !important; }
	.u-max-width-sp-26per { max-width: 26% !important; }
	.u-max-width-sp-27per { max-width: 27% !important; }
	.u-max-width-sp-28per { max-width: 28% !important; }
	.u-max-width-sp-29per { max-width: 29% !important; }
	.u-max-width-sp-30per { max-width: 30% !important; }
	.u-max-width-sp-31per { max-width: 31% !important; }
	.u-max-width-sp-32per { max-width: 32% !important; }
	.u-max-width-sp-33per { max-width: 33% !important; }
	.u-max-width-sp-34per { max-width: 34% !important; }
	.u-max-width-sp-35per { max-width: 35% !important; }
	.u-max-width-sp-36per { max-width: 36% !important; }
	.u-max-width-sp-37per { max-width: 37% !important; }
	.u-max-width-sp-38per { max-width: 38% !important; }
	.u-max-width-sp-39per { max-width: 39% !important; }
	.u-max-width-sp-40per { max-width: 40% !important; }
	.u-max-width-sp-41per { max-width: 41% !important; }
	.u-max-width-sp-42per { max-width: 42% !important; }
	.u-max-width-sp-43per { max-width: 43% !important; }
	.u-max-width-sp-44per { max-width: 44% !important; }
	.u-max-width-sp-45per { max-width: 45% !important; }
	.u-max-width-sp-46per { max-width: 46% !important; }
	.u-max-width-sp-47per { max-width: 47% !important; }
	.u-max-width-sp-48per { max-width: 48% !important; }
	.u-max-width-sp-49per { max-width: 49% !important; }
	.u-max-width-sp-50per { max-width: 50% !important; }
	.u-max-width-sp-51per { max-width: 51% !important; }
	.u-max-width-sp-52per { max-width: 52% !important; }
	.u-max-width-sp-53per { max-width: 53% !important; }
	.u-max-width-sp-54per { max-width: 54% !important; }
	.u-max-width-sp-55per { max-width: 55% !important; }
	.u-max-width-sp-56per { max-width: 56% !important; }
	.u-max-width-sp-57per { max-width: 57% !important; }
	.u-max-width-sp-58per { max-width: 58% !important; }
	.u-max-width-sp-59per { max-width: 59% !important; }
	.u-max-width-sp-60per { max-width: 60% !important; }
	.u-max-width-sp-61per { max-width: 61% !important; }
	.u-max-width-sp-62per { max-width: 62% !important; }
	.u-max-width-sp-63per { max-width: 63% !important; }
	.u-max-width-sp-64per { max-width: 64% !important; }
	.u-max-width-sp-65per { max-width: 65% !important; }
	.u-max-width-sp-66per { max-width: 66% !important; }
	.u-max-width-sp-67per { max-width: 67% !important; }
	.u-max-width-sp-68per { max-width: 68% !important; }
	.u-max-width-sp-69per { max-width: 69% !important; }
	.u-max-width-sp-70per { max-width: 70% !important; }
	.u-max-width-sp-71per { max-width: 71% !important; }
	.u-max-width-sp-72per { max-width: 72% !important; }
	.u-max-width-sp-73per { max-width: 73% !important; }
	.u-max-width-sp-74per { max-width: 74% !important; }
	.u-max-width-sp-75per { max-width: 75% !important; }
	.u-max-width-sp-76per { max-width: 76% !important; }
	.u-max-width-sp-77per { max-width: 77% !important; }
	.u-max-width-sp-78per { max-width: 78% !important; }
	.u-max-width-sp-79per { max-width: 79% !important; }
	.u-max-width-sp-80per { max-width: 80% !important; }
	.u-max-width-sp-81per { max-width: 81% !important; }
	.u-max-width-sp-82per { max-width: 82% !important; }
	.u-max-width-sp-83per { max-width: 83% !important; }
	.u-max-width-sp-84per { max-width: 84% !important; }
	.u-max-width-sp-85per { max-width: 85% !important; }
	.u-max-width-sp-86per { max-width: 86% !important; }
	.u-max-width-sp-87per { max-width: 87% !important; }
	.u-max-width-sp-88per { max-width: 88% !important; }
	.u-max-width-sp-89per { max-width: 89% !important; }
	.u-max-width-sp-90per { max-width: 90% !important; }
	.u-max-width-sp-91per { max-width: 91% !important; }
	.u-max-width-sp-92per { max-width: 92% !important; }
	.u-max-width-sp-93per { max-width: 93% !important; }
	.u-max-width-sp-94per { max-width: 94% !important; }
	.u-max-width-sp-95per { max-width: 95% !important; }
	.u-max-width-sp-96per { max-width: 96% !important; }
	.u-max-width-sp-97per { max-width: 97% !important; }
	.u-max-width-sp-98per { max-width: 98% !important; }
	.u-max-width-sp-99per { max-width: 99% !important; }
	.u-max-width-sp-100per { max-width: 100% !important; }
	.u-max-width-sp-auto  { max-width: auto !important; }
}
