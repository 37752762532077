@import "../foundation/setting";

.c-anchor-link__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1.9rem;
    grid-row-gap: 3rem;
    @include mq('max') {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mq('max', 'md') {
        grid-template-columns: repeat(1, 1fr);
    }
}

.c-anchor-link__list-item {
    position: relative;
    padding: 1rem 3rem 1rem 1rem;;
    border-bottom: 1px solid #8f94ae;
    img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    &:hover {
        img {
            animation: hover-down 500ms forwards ease;
        }
    }
}

.c-anchor-list__title-en {
    font-family: $font-en;
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 1rem;
    @include mq('max', 'xxl') {
        font-size: 1.8rem;
        margin-bottom: 0;
    }
}

.c-anchor-list__title-ja {
    @include mq('max', 'xxl') {
        font-size: 1.2rem;
    }
}