@import "../foundation/setting";

/*lv
---------------------------------------------------------- */
.c-title-lv1 {
	.c-title-lv1__text-en {
        font-family: $font-en;
        font-size: 2rem;
        color: $color-maintone01;
        padding-left:  2.3rem;
        position: relative;
        font-weight: bold;
        &:before {
            content: '';
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            background-color: $color-yellow;
            border-radius: 50%;
            position: absolute;
            top: 50%; 
            left: 0;
            transform: translateY(-50%);
        }
    }
    .c-title-lv1__text-ja {
        font-family: $font-base;
        font-size: 3.2rem;
        display: block;
        font-weight: $weight-bold;
        line-height: 1.2;
    }
		@include mq("max") {
            .c-title-lv1__text-en {
                padding-right: 0;
                font-size: 1.6rem;
            }
            .c-title-lv1__text-ja {
                font-size: 2rem;
            }
		}
}

.c-title-lv2 {
    border-bottom: 1px solid $color-graytone01;
    padding-bottom: 1.8rem;
    position: relative;
    &::before {
        content: '';
        width: 8rem;
        height: 0.4rem;
        border-radius: 50px 0 0 50px;
        background-color: $color-maintone01;
        position: absolute;
        bottom: -0.2rem;
        left: 0;
    }
    &::after {
        content: '';
        width: 4rem;
        height: 0.4rem;
        border-radius: 0 50px 50px 0;
        background-color: $color-yellow;
        position: absolute;
        bottom: -0.2rem;
        left: 8rem;
    }
    .c-title-lv2__text-ja {
        font-family: $font-base;
        font-size: 2.4rem;
        display: block;
        font-weight: $weight-bold;
        line-height: 1.5;
    }
    @include mq("max") {
        padding-bottom: 1rem;
        .c-title-lv2__text-ja {
            font-size: 1.8rem;
        }
    }
}


.c-title-lv3 {
    position: relative;
    padding-left: 2rem;
    font-size: 2rem;
    font-weight: bold;
    &:before {
        content: '';
        display: block;
        width: 0.4rem;
        height: 80%;
        background-color: $color-maintone01;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    @include mq("max") {
        font-size: 1.8rem;
    }
}

.c-title-lv4 {
    position: relative;
    padding-left: 2.6rem;
    font-size: 1.8rem;
    font-weight: bold;
    &:before {
        content: '';
        display: block;
        width: 1.3rem;
        height: 0.4rem;
        background-color: $color-maintone01;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    @include mq("max") {
        font-size: 1.6rem;
    }
}