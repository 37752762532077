@import "../foundation/setting";


.u-cf {
	zoom: 1;
}

.u-cf:before {
	display: table;
	content: '';
}

.u-cf:after {
	display: table;
	clear: both;
	content: '';
}



@include mq('min') {
	.u-cf-pc {
		zoom: 1;
	}
	.u-cf-pc:before {
		display: table;
		content: '';
	}
	.u-cf-pc:after {
		display: table;
		clear: both;
		content: '';
	}
}

@include mq('max') {
	.u-cf-sp {
		zoom: 1;
	}
	.u-cf-sp:before {
		display: table;
		content: '';
	}
	.u-cf-sp:after {
		display: table;
		clear: both;
		content: '';
	}
}

