@import "../foundation/setting";

.p-works__mv-youtube {
    display: flex;
    justify-content: right;
    padding: 10rem 0;
	padding-right: 24rem;
    padding-left: 24rem;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.p-works__mv-youtube-wrap {
    position: relative;
	max-width: 980px;
    max-height: 551px;
    margin-right: 0;
	background-color: #fff;
	overflow: hidden;
	backface-visibility: hidden;
	border: 3px solid #fff;
	.p-works__mv-youtube-thumbnail {
		transform: scale(1);
	}
	&:hover:not(.-nohover) {
		.p-works__mv-youtube-thumbnail {
			transform: scale(1.1);
			opacity: 0.3;
			cursor: pointer;
		}
	}
	img {
		display: inline-block;
		transition: 0.3s ease;
	}
	.p-works__mv-youtube-icon {
		height: auto;
		width: 20%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 2;
		cursor: pointer;
	}
	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}
.p-works__mv-youtube-content {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.80);
	z-index: 99999;
	.p-works__mv-youtube-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 90%;
		max-width: 900px;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		.p-works__mv-youtube-btn {
			position: absolute;
			top: -8rem;
			right: 0;
			width: 7rem;
			height: 7rem;
			background-color: $color-maintone01;
			z-index: 2;
			cursor: pointer;
			&::before,&::after {
				content: "";
				position: absolute;
				width: 2px;
				height: 20px;
				top: 50%;
				left: 50%;
				background-color: #fff;
			}
			&::before {
				transform: translate(-50%,-50%) rotate(-45deg);
			}
			&::after {
				transform: translate(-50%,-50%) rotate(45deg);
			}
		}
	}
}
