@import "../foundation/setting";

/*common
---------------------------------------------------------- */
html {
	font-size: calc(10px * 1);
	@media screen and (max-width: 1536px) and (min-width: 1025px) {
		font-size: 0.625vw;
	}
	@media screen and (max-width: 1024px) {
		//font-size: 2.625vw;
	}
}

body {
	color: #333333;
	font-size: 1.4rem;
	font-family: Noto Sans JP, sans-serif;
	background: $color-white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	text-rendering: geometricPrecision;
	overscroll-behavior-y: none;
	@include line-height(32, 16);
	@include mq('max') {
		font-size: 1.2rem;
		@include line-height(24, 12);
	}
}

@font-face {
	font-weight: normal;
	font-family: "icomoon";
	font-style: normal;
	src: url("https://kiml.co.jp/kimal/fonts/icomoon/icomoon.eot?etnesh");
	src: url("https://kiml.co.jp/kimal/fonts/icomoon/icomoon.eot?etnesh#iefix")
			format("embedded-opentype"),
		url("https://kiml.co.jp/kimal/fonts/icomoon/icomoon.ttf?etnesh") format("truetype"),
		url("https://kiml.co.jp/kimal/fonts/icomoon/icomoon.woff?etnesh") format("woff"),
		url("https://kiml.co.jp/kimal/fonts/icomoon/icomoon.svg?etnesh#icomoon") format("svg");
	font-display: swap;
}
