@import "../foundation/setting";

.u-show-pc {
	@include mq('min') {
		display: block !important;
	}
}

.u-show-sp {
	@include mq('max') {
		display: block !important;
	}
}

.u-hide-pc {
	@include mq('min') {
		display: none !important;
	}
}

.u-hide-sp {
	@include mq('max') {
		display: none !important;
	}
}

.u-hide {
	display: none !important;
}

.u-show-pc--xxl {
	@include mq('min', 'xxl') {
		display: none !important;
	}
}

.u-hide-pc--xxl {
	@include mq('max', 'xxl') {
		display: none !important;
	}
}

.u-show-pc--xl {
	@include mq('min', 'xl') {
		display: none !important;
	}
}

.u-hide-pc--xl {
	@include mq('max', 'xl') {
		display: none !important;
	}
}

.u-visually-hidden {
	@include visually-hidden();
}
