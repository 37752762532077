body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td,
menu {
	margin: 0;
	padding: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
fieldset,
img {
	border: 0;
}
address,
caption,
cite,
code,
dfn,
th,
var {
	font-weight: normal;
	font-style: normal;
}
ol,
ul {
	list-style: none;
}
caption,
th {
	text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	font-size: 100%;
}
q:before,
q:after {
	content: "";
}
abbr,
acronym {
	font-variant: normal;
	border: 0;
}
input,
textarea,
select {
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
}
input,
textarea,
select {
	*font-size: 100%;
}
legend {
	color: #000;
}
#yui3-css-stamp.cssreset {
	display: none;
}
img {
	height: auto;
	font-size: 0;
	line-height: 0;
	vertical-align: top;
}

img,
picture {
	max-width: 100%;
	vertical-align: middle;
}

picture {
	display: block;
}

a img {
	border: none;
	outline: none;
}

figure {
	margin: 0;
}

table {
	border: none;
	border-collapse: collapse;
	border-spacing: 0;
}

input,
textarea {
	text-align: left;
	vertical-align: top;
}

button {
	border: none;
}

button,
label {
	text-align: inherit;
	background-color: transparent;
	cursor: pointer;
}

button[disabled],
input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	vertical-align: middle;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

input[type="number"] {
	-moz-appearance: textfield;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

input[type="search"] {
	box-sizing: border-box;
}

input:-webkit-autofill {
	transition: background-color 50000s ease-in-out 0s;
	animation-name: onAutoFillStart;
}

small {
	font-size: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

input,
select,
textarea {
	font-size: 16px;
}

a,
input,
button,
textarea,
select {
	background-color: transparent;
	border: none;
	outline: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	touch-action: manipulation;
}

input,
textarea {
	appearance: none;
}

input,
button,
textarea,
select,
option {
	color: inherit;
	line-height: inherit;
	letter-spacing: inherit;
}

:focus {
	outline: none;
}

* {
	box-sizing: border-box;
	min-width: 0;
	min-height: 0;
}
