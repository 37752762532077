@import "../foundation/setting";

.u-font-n {
	font-weight: normal !important;
}

.u-font-b {
	font-weight: bold !important;
}

.u-font-en {
	font-family: $font-en;
}

.u-font-ja {
	font-family: $font-base;
}

.u-font-through {
	text-decoration: line-through;
}



@include mq('min') {
	.u-font-n-pc {
		font-weight: normal !important;
	}
	.u-font-b-pc {
		font-weight: bold !important;
	}
}

@include mq('max') {
	.u-font-n-sp {
		font-weight: normal !important;
	}
	.u-font-b-sp {
		font-weight: bold !important;
	}
}

/*size
---------------------------------------------------------- */
.u-font-10 {
	font-size: 1rem;
}

.u-font-11 {
	font-size: 1.1rem;
}

.u-font-12 {
	font-size: 1.2rem;
}

.u-font-13 {
	font-size: 1.3rem;
}

.u-font-14 {
	font-size: 1.4rem;
}

.u-font-15 {
	font-size: 1.5rem;
}

.u-font-16 {
	font-size: 1.6rem;
}

.u-font-17 {
	font-size: 1.7rem;
}

.u-font-18 {
	font-size: 1.8rem;
}

.u-font-19 {
	font-size: 1.9rem;
}

.u-font-20 {
	font-size: 2rem;
}

.u-font-21 {
	font-size: 2.1rem;
}

.u-font-22 {
	font-size: 2.2rem;
}

.u-font-23 {
	font-size: 2.3rem;
}

.u-font-24 {
	font-size: 2.4rem;
}

.u-font-25 {
	font-size: 2.5rem;
}

.u-font-26 {
	font-size: 2.6rem;
}

.u-font-27 {
	font-size: 2.7rem;
}

.u-font-28 {
	font-size: 2.8rem;
}

.u-font-29 {
	font-size: 2.9rem;
}

.u-font-30 {
	font-size: 3rem;
}

.u-font-31 {
	font-size: 3.1rem;
}

.u-font-32 {
	font-size: 3.2rem;
}

.u-font-33 {
	font-size: 3.3rem;
}

.u-font-34 {
	font-size: 3.4rem;
}

.u-font-35 {
	font-size: 3.5rem;
}

.u-font-36 {
	font-size: 3.6rem;
}

.u-font-37 {
	font-size: 3.7rem;
}

.u-font-38 {
	font-size: 3.8rem;
}

.u-font-39 {
	font-size: 3.9rem;
}

.u-font-40 {
	font-size: 4rem;
}

.u-font-41 {
	font-size: 4.1rem;
}

.u-font-42 {
	font-size: 4.2rem;
}

.u-font-43 {
	font-size: 4.3rem;
}

.u-font-44 {
	font-size: 4.4rem;
}

.u-font-45 {
	font-size: 4.5rem;
}

.u-font-46 {
	font-size: 4.6rem;
}

.u-font-47 {
	font-size: 4.7rem;
}

.u-font-48 {
	font-size: 4.8rem;
}

.u-font-49 {
	font-size: 4.9rem;
}

.u-font-50 {
	font-size: 5rem;
}

@include mq("min") {
	.u-font-10-pc {
		font-size: 1rem;
	}

	.u-font-11-pc {
		font-size: 1.1rem;
	}

	.u-font-12-pc {
		font-size: 1.2rem;
	}

	.u-font-13-pc {
		font-size: 1.3rem;
	}

	.u-font-14-pc {
		font-size: 1.4rem;
	}

	.u-font-15-pc {
		font-size: 1.5rem;
	}

	.u-font-16-pc {
		font-size: 1.6rem;
	}

	.u-font-17-pc {
		font-size: 1.7rem;
	}

	.u-font-18-pc {
		font-size: 1.8rem;
	}

	.u-font-19-pc {
		font-size: 1.9rem;
	}

	.u-font-20-pc {
		font-size: 2rem;
	}

	.u-font-21-pc {
		font-size: 2.1rem;
	}

	.u-font-22-pc {
		font-size: 2.2rem;
	}

	.u-font-23-pc {
		font-size: 2.3rem;
	}

	.u-font-24-pc {
		font-size: 2.4rem;
	}

	.u-font-25-pc {
		font-size: 2.5rem;
	}

	.u-font-26-pc {
		font-size: 2.6rem;
	}

	.u-font-27-pc {
		font-size: 2.7rem;
	}

	.u-font-28-pc {
		font-size: 2.8rem;
	}

	.u-font-29-pc {
		font-size: 2.9rem;
	}

	.u-font-30-pc {
		font-size: 3rem;
	}

	.u-font-31-pc {
		font-size: 3.1rem;
	}

	.u-font-32-pc {
		font-size: 3.2rem;
	}

	.u-font-33-pc {
		font-size: 3.3rem;
	}

	.u-font-34-pc {
		font-size: 3.4rem;
	}

	.u-font-35-pc {
		font-size: 3.5rem;
	}

	.u-font-36-pc {
		font-size: 3.6rem;
	}

	.u-font-37-pc {
		font-size: 3.7rem;
	}

	.u-font-38-pc {
		font-size: 3.8rem;
	}

	.u-font-39-pc {
		font-size: 3.9rem;
	}

	.u-font-40-pc {
		font-size: 4rem;
	}

	.u-font-41-pc {
		font-size: 4.1rem;
	}

	.u-font-42-pc {
		font-size: 4.2rem;
	}

	.u-font-43-pc {
		font-size: 4.3rem;
	}

	.u-font-44-pc {
		font-size: 4.4rem;
	}

	.u-font-45-pc {
		font-size: 4.5rem;
	}

	.u-font-46-pc {
		font-size: 4.6rem;
	}

	.u-font-47-pc {
		font-size: 4.7rem;
	}

	.u-font-48-pc {
		font-size: 4.8rem;
	}

	.u-font-49-pc {
		font-size: 4.9rem;
	}

	.u-font-50-pc {
		font-size: 5rem;
	}
}

@include mq("max") {
	.u-font-10-sp {
		font-size: 1rem;
	}

	.u-font-11-sp {
		font-size: 1.1rem;
	}

	.u-font-12-sp {
		font-size: 1.2rem;
	}

	.u-font-13-sp {
		font-size: 1.3rem;
	}

	.u-font-14-sp {
		font-size: 1.4rem;
	}

	.u-font-15-sp {
		font-size: 1.5rem;
	}

	.u-font-16-sp {
		font-size: 1.6rem;
	}

	.u-font-17-sp {
		font-size: 1.7rem;
	}

	.u-font-18-sp {
		font-size: 1.8rem;
	}

	.u-font-19-sp {
		font-size: 1.9rem;
	}

	.u-font-20-sp {
		font-size: 2rem;
	}

	.u-font-21-sp {
		font-size: 2.1rem;
	}

	.u-font-22-sp {
		font-size: 2.2rem;
	}

	.u-font-23-sp {
		font-size: 2.3rem;
	}

	.u-font-24-sp {
		font-size: 2.4rem;
	}

	.u-font-25-sp {
		font-size: 2.5rem;
	}

	.u-font-26-sp {
		font-size: 2.6rem;
	}

	.u-font-27-sp {
		font-size: 2.7rem;
	}

	.u-font-28-sp {
		font-size: 2.8rem;
	}

	.u-font-29-sp {
		font-size: 2.9rem;
	}

	.u-font-30-sp {
		font-size: 3rem;
	}

	.u-font-31-sp {
		font-size: 3.1rem;
	}

	.u-font-32-sp {
		font-size: 3.2rem;
	}

	.u-font-33-sp {
		font-size: 3.3rem;
	}

	.u-font-34-sp {
		font-size: 3.4rem;
	}

	.u-font-35-sp {
		font-size: 3.5rem;
	}

	.u-font-36-sp {
		font-size: 3.6rem;
	}

	.u-font-37-sp {
		font-size: 3.7rem;
	}

	.u-font-38-sp {
		font-size: 3.8rem;
	}

	.u-font-39-sp {
		font-size: 3.9rem;
	}

	.u-font-40-sp {
		font-size: 4rem;
	}

	.u-font-41-sp {
		font-size: 4.1rem;
	}

	.u-font-42-sp {
		font-size: 4.2rem;
	}

	.u-font-43-sp {
		font-size: 4.3rem;
	}

	.u-font-44-sp {
		font-size: 4.4rem;
	}

	.u-font-45-sp {
		font-size: 4.5rem;
	}

	.u-font-46-sp {
		font-size: 4.6rem;
	}

	.u-font-47-sp {
		font-size: 4.7rem;
	}

	.u-font-48-sp {
		font-size: 4.8rem;
	}

	.u-font-49-sp {
		font-size: 4.9rem;
	}

	.u-font-50-sp {
		font-size: 5rem;
	}
}

/*weight
---------------------------------------------------------- */
.u-font-r {
	font-weight: 400 !important;
}

.u-font-m {
	font-weight: 500 !important;
}

.u-font-sb {
	font-weight: 600 !important;
}

.u-font-b {
	font-weight: 700 !important;
}

.u-font-default {
	font-family: inherit !important;
	font-style: inherit !important;
}

@include mq("min") {
	.u-font-r-pc {
		font-weight: 400 !important;
	}
	.u-font-m-pc {
		font-weight: 500 !important;
	}
	.u-font-sb-pc {
		font-weight: 600 !important;
	}
	.u-font-b-pc {
		font-weight: 700 !important;
	}
}

@include mq("max") {
	.u-font-r-sp {
		font-weight: 400 !important;
	}
	.u-font-m-sp {
		font-weight: 500 !important;
	}
	.u-font-sb-sp {
		font-weight: 600 !important;
	}
	.u-font-b-sp {
		font-weight: 700 !important;
	}
}

