@use "foundation/setting" as *;

[class*="o-box"] {
	position: relative;
	display: block;
	background-color: transparent;
	border: 1px solid transparent;
	@include mq("min") {
		&[data-spacer~="pc:60"] {
			padding: 60px;
		}
		&[data-spacer~="pc:50"] {
			padding: 50px;
		}
	}
	@include mq("max") {
		&[data-spacer~="sp:30,20"] {
			padding: 30px 20px;
		}
	}
}

.o-box-r-20 {
	border-radius: 20px;
}

.o-box-100 {
	padding: 100px;
	@include mq("max") {
		padding: 50px;
	}
}
.o-box-90 {
	padding: 90px;
	@include mq("max") {
		padding: 45px;
	}
}
.o-box-80 {
	padding: 80px;
	@include mq("max") {
		padding: 40px;
	}
}
.o-box-70 {
	padding: 70px;
	@include mq("max") {
		padding: 35px;
	}
}
.o-box-60 {
	padding: 60px;
	@include mq("max") {
		padding: 30px;
	}
}
.o-box-50 {
	padding: 50px;
	@include mq("max") {
		padding: 25px;
	}
}
.o-box-40 {
	padding: 40px;
	@include mq("max") {
		padding: 20px;
	}
}
.o-box-30 {
	padding: 30px;
	@include mq("max") {
		padding: 15px;
	}
}
.o-box-20 {
	padding: 20px;
	@include mq("max") {
		padding: 10px;
	}
}
.o-box-15 {
	padding: 15px;
	@include mq("max") {
		padding: 7.5px;
	}
}

@include mq("min") {
	.o-box-100-pc {
		padding: 100px;
	}
	.o-box-90-pc {
		padding: 90px;
	}
	.o-box-80-pc {
		padding: 80px;
	}
	.o-box-70-pc {
		padding: 70px;
	}
	.o-box-60-pc {
		padding: 60px;
	}
	.o-box-50-pc {
		padding: 50px;
	}
	.o-box-40-pc {
		padding: 40px;
	}
	.o-box-30-pc {
		padding: 30px;
	}
	.o-box-20-pc {
		padding: 20px;
	}
	.o-box-15-pc {
		padding: 15px;
	}
}

@include mq("max") {
	.o-box-100-sp {
		padding: 50px;
	}
	.o-box-90-sp {
		padding: 45px;
	}
	.o-box-80-sp {
		padding: 40px;
	}
	.o-box-70-sp {
		padding: 35px;
	}
	.o-box-60-sp {
		padding: 30px;
	}
	.o-box-50-sp {
		padding: 25px;
	}
	.o-box-40-sp {
		padding: 20px;
	}
	.o-box-30-sp {
		padding: 15px;
	}
	.o-box-20-sp {
		padding: 10px;
	}
	.o-box-15-sp {
		padding: 7.5px;
	}
}
