@import "../foundation/setting";
/*2列
---------------------------------------------------------- */
.c-button__wrap {
	display: flex;
	justify-content: center;
	align-items: stretch;
	.c-button-lv1,
	.c-button-lv2,
	.c-button-lv3 {
		&:nth-child(1) {
			margin-right: 2rem;
		}
		&:nth-child(2) {
			margin-left: 2rem;
		}
	}
	@media screen and (max-width: 600px) {
		display: block;
		.c-button-lv1,
		.c-button-lv2,
		.c-button-lv3 {
			&:nth-child(1) {
				margin-right: auto;
				margin-bottom: 1rem;
			}
			&:nth-child(2) {
				margin-left: auto;
			}
		}
	}
}
 
/*lv1
---------------------------------------------------------- */
.c-button-lv1 {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 560px;
	min-height: 6rem;
	margin: 0 auto;
	font-weight: 700;
	display: block;
	@include line-height(26, 16);
	@include mq('max') {
		min-height: 5rem;
	}
	&.\-disable {
		.c-button-lv1__inner {
			background-color: #8f94ae;
		}
	}
	&.\-outline {
		.c-button-lv1__inner {
			border: 2px solid $color-maintone01;
			background-color: transparent;
			color: $color-maintone01;
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.657%22%20height%3D%2211.314%22%20viewBox%3D%220%200%2012.657%2011.314%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(0%200)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(2.657%204.657)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(5.657%2011.314)%20rotate(-135)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(6%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
				right: auto;
				left: 2rem;
			}
		}
	}
	&.\-external {
		.c-button-lv1__inner {
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.657%22%20height%3D%229.657%22%20viewBox%3D%220%200%209.657%209.657%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(-1366.837%20-58.933)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(919%201014)%22%20fill%3D%22%23fff%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(-452.858%20955.603)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(929%201019)%22%20fill%3D%22%23fff%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(935%201013)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E')			}
		}
	}
	&.\-gray {
		.c-button-lv1__inner {
			border: 2px solid $color-graytone01;
		}
	}
}

.c-button-lv1__inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
	padding: 1rem 3rem;
	color: $color-white;
	text-align: center;
	text-decoration: none;
	background-color: $color-maintone01;
	border-radius: 99em;
	transition: 0.3s;
	font-size: 18px;
	letter-spacing: 0.04em;
	height: 100%;
	@include mq('min') {
		&:hover {
		}
	}
	.c-button-lv1__subtxt {
		font-size: 14px;
		display: block;
		width: 100%;
		font-weight: $weight-medium;
		font-family: $font-base;
	}
	&::after {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.657' height='11.314' viewBox='0 0 12.657 11.314'%3E%3Cg id='グループ_87' data-name='グループ 87' transform='translate(-919 -1009.343)'%3E%3Crect id='長方形_38' data-name='長方形 38' width='10' height='2' transform='translate(919 1014)' fill='%23fff'/%3E%3Cg id='グループ_21' data-name='グループ 21' transform='translate(-452.858 955.603) rotate(-45)'%3E%3Crect id='長方形_39' data-name='長方形 39' width='8' height='2' transform='translate(929 1019)' fill='%23fff'/%3E%3Crect id='長方形_40' data-name='長方形 40' width='2' height='8' transform='translate(935 1013)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		width: 12px;
		height: 11px;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
	}
	&:hover {
		background-color: $color-black;
	}
	@include mq('max', 'md') {
		font-size: 14px;
		.c-button-lv1__subtxt {
			font-size: 10px;
		}
	}
	@include mq('max', 'sm') {
		padding: 1rem;
		&::after {
			right: 0.5rem;
		}
	}
}

/*lv2
---------------------------------------------------------- */
.c-button-lv2 {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 360px;
	min-height: 6rem;
	margin: 0 auto;
	font-weight: 700;
	display: block;
	@include line-height(26, 16);
	@include mq('max') {
		max-width: 52rem;
		min-height: 5rem;
	}
	&.\-disable {
		.c-button-lv2__inner {
			background-color: #8f94ae;
		}
	}
	&.\-outline {
		.c-button-lv2__inner {
			border: 2px solid $color-maintone01;
			background-color: transparent;
			color: $color-maintone01;
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.657%22%20height%3D%2211.314%22%20viewBox%3D%220%200%2012.657%2011.314%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(0%200)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(2.657%204.657)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(5.657%2011.314)%20rotate(-135)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(6%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
				right: auto;
				left: 2rem;
			}
		}
	}
	&.\-external {
		.c-button-lv2__inner {
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.657%22%20height%3D%229.657%22%20viewBox%3D%220%200%209.657%209.657%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(-1366.837%20-58.933)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(919%201014)%22%20fill%3D%22%23fff%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(-452.858%20955.603)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(929%201019)%22%20fill%3D%22%23fff%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(935%201013)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E')			}
		}
	}
	&.\-gray {
		.c-button-lv2__inner {
			border: 2px solid $color-graytone01;
		}
	}
}

.c-button-lv2__inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
	padding: 1rem 3rem;
	color: $color-white;
	text-align: center;
	text-decoration: none;
	background-color: $color-maintone01;
	border-radius: 99em;
	transition: 0.3s;
	font-size: 18px;
	letter-spacing: 0.04em;
	height: 100%;
	@include mq('min') {
		&:hover {
		}
	}
	.c-button-lv2__subtxt {
		font-size: 14px;
		display: block;
		width: 100%;
		font-weight: $weight-medium;
		font-family: $font-base;
	}
	&::after {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.657' height='11.314' viewBox='0 0 12.657 11.314'%3E%3Cg id='グループ_87' data-name='グループ 87' transform='translate(-919 -1009.343)'%3E%3Crect id='長方形_38' data-name='長方形 38' width='10' height='2' transform='translate(919 1014)' fill='%23fff'/%3E%3Cg id='グループ_21' data-name='グループ 21' transform='translate(-452.858 955.603) rotate(-45)'%3E%3Crect id='長方形_39' data-name='長方形 39' width='8' height='2' transform='translate(929 1019)' fill='%23fff'/%3E%3Crect id='長方形_40' data-name='長方形 40' width='2' height='8' transform='translate(935 1013)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		width: 12px;
		height: 11px;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
	}
	&:hover {
		background-color: $color-black;
	}
	@include mq('max', 'md') {
		font-size: 14px;
		.c-button-lv2__subtxt {
			font-size: 10px;
		}
	}
	@include mq('max', 'sm') {
		padding: 1rem;
		&::after {
			right: 0.5rem;
		}
	}
}

/*lv3
---------------------------------------------------------- */
.c-button-lv3 {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 280px;
	min-height: 6rem;
	margin: 0 auto;
	font-weight: 700;
	display: block;
	@include line-height(26, 16);
	@include mq('max') {
		max-width: 38rem;
		min-height: 5rem;
	}
	&.\-disable {
		.c-button-lv3__inner {
			background-color: #8f94ae;
		}
	}
	&.\-outline {
		.c-button-lv3__inner {
			border: 2px solid $color-maintone01;
			background-color: transparent;
			color: $color-maintone01;
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.657%22%20height%3D%2211.314%22%20viewBox%3D%220%200%2012.657%2011.314%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(0%200)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(2.657%204.657)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(5.657%2011.314)%20rotate(-135)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(6%200)%22%20fill%3D%22%23202b5f%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
				right: auto;
				left: 2rem;
			}
		}
	}
	&.\-external {
		.c-button-lv3__inner {
			&::after {
				background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.657%22%20height%3D%229.657%22%20viewBox%3D%220%200%209.657%209.657%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_87%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2087%22%20transform%3D%22translate(-1366.837%20-58.933)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_38%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2038%22%20width%3D%2210%22%20height%3D%222%22%20transform%3D%22translate(919%201014)%22%20fill%3D%22%23fff%22%2F%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(-452.858%20955.603)%20rotate(-45)%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_39%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2039%22%20width%3D%228%22%20height%3D%222%22%20transform%3D%22translate(929%201019)%22%20fill%3D%22%23fff%22%2F%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_40%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%2040%22%20width%3D%222%22%20height%3D%228%22%20transform%3D%22translate(935%201013)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E')			}
		}
	}
	&.\-gray {
		.c-button-lv3__inner {
			border: 2px solid $color-graytone01;
		}
	}
}

.c-button-lv3__inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
	padding: 1rem 3rem;
	color: $color-white;
	text-align: center;
	text-decoration: none;
	background-color: $color-maintone01;
	border-radius: 99em;
	transition: 0.3s;
	font-size: 18px;
	letter-spacing: 0.04em;
	height: 100%;
	.c-button-lv3__subtxt {
		font-size: 14px;
		display: block;
		width: 100%;
		font-weight: $weight-medium;
		font-family: $font-base;
	}
	&::after {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.657' height='11.314' viewBox='0 0 12.657 11.314'%3E%3Cg id='グループ_87' data-name='グループ 87' transform='translate(-919 -1009.343)'%3E%3Crect id='長方形_38' data-name='長方形 38' width='10' height='2' transform='translate(919 1014)' fill='%23fff'/%3E%3Cg id='グループ_21' data-name='グループ 21' transform='translate(-452.858 955.603) rotate(-45)'%3E%3Crect id='長方形_39' data-name='長方形 39' width='8' height='2' transform='translate(929 1019)' fill='%23fff'/%3E%3Crect id='長方形_40' data-name='長方形 40' width='2' height='8' transform='translate(935 1013)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		width: 12px;
		height: 11px;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
	}
	&:hover {
		background-color: $color-black;
	}
	@include mq('max', 'md') {
		font-size: 14px;
		.c-button-lv3__subtxt {
			font-size: 10px;
		}
	}
	@include mq('max', 'sm') {
		padding: 1rem;
		&::after {
			right: 0.5rem;
		}
	}
}


.c-button-icon {
	display: flex;
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	color: $color-white;
	padding: 2rem 0;
    transition: all 0.2s;
    width: 100%;
	margin: 0 auto;
	&.\-w400 {
		max-width: 400px;
	}
	&.\-w360 {
		max-width: 360px;
	}
	&.\-w250 {
		max-width: 250px;
	}
    &.\-blue {
        background-color: $color-maintone01;
    }
    &.\-red {
        background-color: $color-red;
    }
	&.\-outline {
		border: 1px solid $color-maintone01;
		background-color: $color-white;
		color: $color-maintone01;
	}
	&.\-white {
        background-color: $color-white;
		color: #333;
		border: 1px solid #333;
    }
	i {
		font-size: 2.3rem;
	}
	&:hover {
		opacity: 0.7;
	}
	.c-button-icon__text {
		padding-left: 1.5rem;
		font-weight: bold;
	}
}

.c-button-arrow {
	color: $color-white;
	padding: 2rem 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-red;
	border-radius: 50px;
	font-weight: bold;
	position: relative;
	width: 100%;
	margin: 0 auto;
	transition: all 0.2s;
	&.\-w400 {
		max-width: 400px;
	}
	&.\-w360 {
		max-width: 360px;
	}
	&.\-w250 {
		max-width: 250px;
	}
    &.\-blue {
        background-color: $color-maintone01;
    }
    &.\-red {
        background-color: $color-red;
    }
	&.\-outline {
		border: 1px solid $color-maintone01;
		background-color: $color-white;
		color: $color-maintone01;
	}
	&.\-white {
        background-color: $color-white;
		color: #333;
		border: 1px solid #333;
    }
	&:hover {
		opacity: 0.7;
	}
	i {
		position: absolute;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
		font-size: 1.2rem;
	}
}


.c-button-fav {
	display: flex;
	border: 1px solid #AAAAAA;
	color: #333;
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 0;
    transition: all 0.2s;
    width: 20rem;
	i {
		font-size: 2.3rem;
		color: $color-yellow;
	}
	&:hover {
		opacity: 0.7;
	}
	.c-button-fav__text {
		padding-left: 1.5rem;
		font-weight: bold;
	}
}

.c-upload-button {
    padding: 2rem;
    background-color: $color-white;
	border: 1px solid $color-maintone01;
	border-radius: 6px;
	color: $color-maintone01;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 60rem;
	margin: 0 auto;
	transition: all 0.3s;
	i {
		margin-right: 2rem;
		font-size: 2rem;
	}
	@include mq('max') {
		padding: 1rem;
		i {
			margin-right: 1rem;
			font-size: 1.5rem;
		}
	}
	&:hover {
		opacity: 0.8;
	}
}