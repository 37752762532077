@import "../../foundation/setting";

.modal {
    display: none;
}

.modaal-content-container {
    padding: 5rem;
    @include mq('max') {
        padding: 1rem;
    }
}

.modaal-close {
    position: absolute;
    top: -6rem;
    right: 0;
    background-color: $color-maintone01 !important;
    &:hover {
        background-color: $color-maintone01 !important;
        opacity: 0.7;
    }
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
    background: $color-white !important;
}

.modaal-inner-wrapper {
    padding: 80px 15px;
}