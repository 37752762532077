@import "../foundation/setting";

/*白線
---------------------------------------------------------- */
.border-color-white {
    border: 6px solid $color-white;
}

.c-section-border-t {
    border-top: 1px solid $color-white;
}

.c-section-border-b {
    border-bottom: 1px solid $color-white;
}