@import "../foundation/setting";


.u-text-left {
	text-align: left !important;
}
.u-text-center {
	text-align: center !important;
}
.u-text-right {
	text-align: right !important;
}


@include mq('min') {
	.u-text-left-pc {
		text-align: left !important;
	}
	.u-text-center-pc {
		text-align: center !important;
	}
	.u-text-right-pc {
		text-align: right !important;
	}
}

@include mq('max') {
	.u-text-left-sp {
		text-align: left !important;
	}
	.u-text-center-sp {
		text-align: center !important;
	}
	.u-text-right-sp {
		text-align: right !important;
	}
}

