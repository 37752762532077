@import "../foundation/setting";

.c-pagenation {
	display: flex;
	justify-content: center;
}

.c-pagenation__item  {
	border: 1px solid $color-maintone02;
	border-radius: 50%;
	cursor: pointer;
	width: 5rem;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	color: $color-white;
	background-color: $color-maintone02;
	margin: 0 0.75rem;
	&.\-is-page {
		background-color: $color-maintone01;
		border: 1px solid $color-maintone01;
	}
	&.\-arrow {
		background-color: $color-white;
		border: 1px solid $color-maintone01;
		color: $color-maintone01;
		i {
			font-size: 1rem;
		}
	}
	@include mq('max') {
		width: 4rem;
		height: 4rem;
		margin: 0 0.3rem;
		font-size: 1.8rem;
    }
}