@import "../foundation/setting";

/*メインカラー
---------------------------------------------------------- */
.c-bg-main {
    background-color: $color-maintone01;
}

.c-bg-maintone03 {
    background-color: $color-maintone03;
}

.c-bg-white {
    background-color: $color-white;
}

.c-bg-graytone04 {
    background-color: $color-graytone04;
}

.c-bg-yellowtone01 {
    background-color: #FFF7CC;
}