@import "../foundation/setting";

.o-wrapper {
	width: 100%;
	max-width: calc(140rem + 24rem);
	margin: auto;
	padding-right: 12rem;
	padding-left: 12rem;
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		&.-sp-none {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.o-wrapper-100vw {
	max-width: calc(140rem + 52rem);
}

.o-wrapper-md {
	padding-right: 10rem;
	padding-left: 10rem;
	@include mq('min', 'xl') {
		width: 100%;
		max-width: calc(112rem + 20rem);
		margin: auto;
	}
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.o-wrapper-form {
	width: 100%;
	max-width: calc(84rem + 28rem);
	margin: auto;
	padding-right: 14rem;
	padding-left: 14rem;
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.o-wrapper-fluid {
	padding-right: 0;
	padding-left: 0;
}

.o-wrapper-sp-fluid {
	@include mq('max') {
		padding-right: 0;
		padding-left: 0;
	}
}

.o-wrapper-sp-spread {
	@include mq('max') {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.o-section-wrapper {
	padding-top: 10rem;
	padding-bottom: 10rem;
	@include mq('max') {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}