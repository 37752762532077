@import "../foundation/setting";

/*Magnific Popup
---------------------------------------------------------- */
.mfp-bg {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1042;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: rgba(0, 0, 0, 0.75);
}

.mfp-wrap {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1043;
	width: 100%;
	height: 100%;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 8px;
	text-align: center;
}

.mfp-container:before {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	content: "";
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	z-index: 1045;
	display: inline-block;
	margin: 0 auto;
	text-align: left;
	vertical-align: middle;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	position: absolute;
	top: 50%;
	right: 8px;
	left: 8px;
	z-index: 1044;
	width: auto;
	margin-top: -0.8em;
	color: #ccc;
	text-align: center;
}

.mfp-preloader a {
	color: #ccc;
}

.mfp-preloader a:hover {
	color: #fff;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close,
button.mfp-arrow {
	z-index: 1046;
	display: block;
	padding: 0;
	overflow: visible;
	background: transparent;
	border: 0;
	outline: none;
	box-shadow: none;
	cursor: pointer;
	-webkit-appearance: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	padding: 0 0 18px 10px;
	color: #fff;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	font-style: normal;
	line-height: 44px;
	text-align: center;
	text-decoration: none;
	opacity: 0.65;
}

.mfp-close:hover,
.mfp-close:focus {
	opacity: 1;
}

.mfp-close:active {
	top: 1px;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: -6px;
	width: 100%;
	padding-right: 6px;
	color: #fff;
	text-align: right;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #ccc;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
}

.mfp-arrow {
	position: absolute;
	top: 50%;
	width: 90px;
	height: 110px;
	margin: 0;
	margin-top: -55px;
	padding: 0;
	opacity: 0.65;
	-webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
	margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
	opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 0;
	height: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
	content: "";
}

.mfp-arrow:after {
	top: 8px;
	border-top-width: 13px;
	border-bottom-width: 13px;
}

.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}

.mfp-arrow-left:after {
	margin-left: 31px;
	border-right: 17px solid #fff;
}

.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
	right: 0;
}

.mfp-arrow-right:after {
	margin-left: 39px;
	border-left: 17px solid #fff;
}

.mfp-arrow-right:before {
	border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	width: 100%;
	max-width: 900px;
	line-height: 0;
}

.mfp-iframe-holder .mfp-close {
	top: -40px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	overflow: hidden;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	background: #000;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Main image in popup */
img.mfp-img {
	display: block;
	box-sizing: border-box;
	width: auto;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 40px 0 40px;
	line-height: 0;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}

.mfp-figure:after {
	position: absolute;
	top: 40px;
	right: 0;
	bottom: 40px;
	left: 0;
	z-index: -1;
	display: block;
	width: auto;
	height: auto;
	background: #444;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	content: "";
}

.mfp-figure small {
	display: block;
	color: #bdbdbd;
	font-size: 12px;
	line-height: 14px;
}

.mfp-figure figure {
	margin: 0;
}

.mfp-bottom-bar {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	margin-top: -36px;
	cursor: auto;
}

.mfp-title {
	padding-right: 36px;
	color: #f3f3f3;
	line-height: 18px;
	text-align: left;
	word-wrap: break-word;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
	screen and (max-height: 300px) {
	/**
	* Remove all paddings around the image on small screen
	*/
	.mfp-img-mobile .mfp-image-holder {
		padding-right: 0;
		padding-left: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		position: fixed;
		top: auto;
		bottom: 0;
		box-sizing: border-box;
		margin: 0;
		padding: 3px 5px;
		background: rgba(0, 0, 0, 0.6);
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		top: 3px;
		right: 5px;
	}
	.mfp-img-mobile .mfp-close {
		position: fixed;
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		padding: 0;
		line-height: 35px;
		text-align: center;
		background: rgba(0, 0, 0, 0.6);
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		-ms-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		-ms-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		-ms-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-right: 6px;
		padding-left: 6px;
	}
}

/* mfp上書き ---------- */
.mfp-container {
	margin: 0 auto;
	padding: 0 20px;
}

img.mfp-img {
	padding-top: 60px;
	padding-bottom: 60px;
}

.mfp-figure:after {
	top: 60px;
	bottom: 60px;
}

.mfp-bottom-bar {
	margin-top: -46px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.4s cubic-bezier(0.23, 1, 0.58, 1);
	transition: all 0.4s cubic-bezier(0.23, 1, 0.58, 1);
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.75;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.4s cubic-bezier(0.23, 1, 0.58, 1);
	transition: all 0.4s cubic-bezier(0.23, 1, 0.58, 1);
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content,
.mfp-fade.mfp-wrap.mfp-ready .m-modal_close,
.mfp-fade.mfp-wrap.mfp-ready .m-modal_arrow {
	opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content,
.mfp-fade.mfp-wrap.mfp-removing .m-modal_close,
.mfp-fade.mfp-wrap.mfp-removing .m-modal_arrow {
	opacity: 0;
}

/* Move-horizontal */
.mfp-move-vertical .mfp-with-anim {
	transform: translateY(-50px);
	opacity: 0;
	transition: all 0.3s ease-out;
}

.mfp-move-vertical.mfp-bg {
	opacity: 0;
	transition: all 0.3s ease-out;
}

.mfp-move-vertical.mfp-ready .mfp-with-anim {
	transform: translateY(0);
	opacity: 1;
}

.mfp-move-vertical.mfp-ready.mfp-bg {
	opacity: 1;
}

.mfp-move-vertical.mfp-removing .mfp-with-anim {
	transform: translateY(-50px);
	opacity: 0;
}

.mfp-move-vertical.mfp-removing.mfp-bg {
	opacity: 0;
}
