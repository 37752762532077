@import "../foundation/setting";

.c-table {
	width: 100%;
    border-radius: 20px;
    border-spacing: 0;
    border: none;
	border-collapse: separate;
	&.-pattern1 + &.-pattern1 {
		margin-top: -1px;
	}
	&.-pattern1 {
		& > thead > tr > th,
		& > tbody > tr > th,
		& > tbody > tr > td {
			padding: 20px 40px;
			@include line-height(32, 16);
			@include mq("max") {
				padding: 15px 10px;
				font-size: 1.2rem;
				@include line-height(18, 12);
			}
		}
		& > thead > tr > th,
        & > tbody > tr > th {
            font-weight: bold;
		}
		& > thead > tr > th,
		& > tbody > tr > th {
			font-weight: 700;
		}
		& > tbody > tr > * {
		}
		& > tbody > tr > th {
			text-align: center;
			background-color: $color-graytone03;
			border-bottom: 1px solid $color-graytone01;
			border-left: 1px solid $color-graytone01;
		}
		& > tbody > tr > td {
			border-bottom: 1px solid $color-graytone01;
			border-right: 1px solid $color-graytone01;
			border-left: 1px solid $color-graytone01;
		}  
        & > tbody > tr:first-child > th {
			border-top: 1px solid $color-graytone01;
        }
        & > tbody > tr:first-child > td {
			border-top: 1px solid $color-graytone01;
        }
		& > tbody > tr:first-child>*:first-child{
			border-radius: 20px 0 0 0;
		}
		& > tbody > tr:first-child>*:last-child{
			border-radius: 0 20px 0 0;
		}
		& > tbody > tr:last-child>*:first-child{
			border-radius: 0 0 0 20px;
		}
		& > tbody > tr:last-child>*:last-child{
			border-radius: 0 0 20px 0;
		}
	}
	&.-pattern2 {
		& > thead > tr > th,
		& > tbody > tr > th,
		& > tbody > tr > td {
			padding: 30px 0;
			vertical-align: middle;
			@include line-height(32, 16);
			@include mq("max") {
				padding: 15px 10px;
				font-size: 1.2rem;
				@include line-height(18, 12);
			}
		}
		& > tbody > tr {
			&:first-child {
				th,td {
					padding-top: 0;
				}
			}
		}
		& > thead > tr > th,
        & > tbody > tr > th {
			border-bottom: 1px solid $color-graytone01;
            font-weight: bold;
		}
		& > thead > tr > th,
		& > tbody > tr > th {
			font-weight: 700;
		}
		& > thead > tr > th {
			color: $color-white;
			text-align: center;
		}
		& > thead + tbody {
			& > tr:first-child > * {
				border-top: none;
			}
		}
		& > tbody > tr > td {
			border-bottom: 1px solid $color-graytone01;
		}
		&.-vertical-pc {
			& > thead > tr > th,
			& > tbody > tr > th {
				border-bottom: 1px solid $color-graytone01;
			}
			& > tbody > tr > th {
				border-bottom: none;
				color: $color-maintone01;
				font-size: 1.8rem;
			}
			& > tbody > tr > td {
				padding-right: 2rem;
				padding-left: 2rem;
				padding-top: 0;
			}
			& > tbody > tr > th,
			& > tbody > tr > td {
				display: block;
				width: 100% !important;
				border-top: none;
			}
			& > tbody > tr:first-child > td {
				border-top: none;
			}
			& > tbody > tr:first-child th {
				border-top: none;
			}
		}
	}
	&.-sm {
		& > thead > tr > th,
		& > tbody > tr > th,
		& > tbody > tr > td {
			padding: 20px 10px;
			@include mq("max") {
				padding: 15px 5px;
			}
		}
		& > thead > tr > th {
			padding-top: 15px;
			padding-bottom: 15px;
			@include mq("max") {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	ul.c-list {
		&.-pattern1 {
			& > .c-list__item {
				&::before {
					@include mq("max") {
						top: 0.3em;
					}
				}
			}
		}
	}
	@include mq("max") {
		&.-pattern1 {
			&.-vertical-sp {
				& > thead > tr > th,
				& > tbody > tr > th {
					border-bottom: 1px solid $color-graytone01;
				}
				& > tbody > tr > th { 
					border-right: 1px solid $color-graytone01;
				}
				& > tbody > tr > td {
					border-bottom: 1px solid $color-graytone01;
				}
				& > tbody > tr > th,
				& > tbody > tr > td {
					display: block;
					width: 100% !important;
					border-top: none;
				}
				& > tbody > tr:first-child > td {
					border-top: none;
				}
				& > tbody > tr:first-child th {
					border-top: 1px solid $color-graytone01;
				}
				& > tbody > tr:first-child>*:first-child{
					border-radius: 10px 10px 0 0;
				}
				& > tbody > tr:first-child>*:last-child{
					border-radius: 0 0 0 0;
				}
				& > tbody > tr:last-child>*:first-child{
					border-radius: 0 0 0 0;
				}
				& > tbody > tr:last-child>*:last-child{
					border-radius: 0 0 10px 10px;
				}
			}
		}
		&.-pattern2 {
			&.-vertical-sp {
				& > thead > tr > th,
				& > tbody > tr > th {
					border-bottom: none;
				}
				& > tbody > tr > th,
				& > tbody > tr > td {
					display: block;
					width: 100% !important;
					border-top: none;
				}
				& > tbody > tr {
					&:first-child {
						th {
							padding-top: 0;
						}
					}
				}
				& > tbody > tr > th {
					padding: 20px 10px 10px;
				}
				& > tbody > tr > td {
					padding: 0 10px 20px;
				}
				& > tbody > tr:first-child > td {
					border-top: none;
				}
			}
		}
	}
}

.c-table > thead > tr > th,
.c-table > tbody > tr > th,
.c-table > tbody > tr > td {
	position: relative;
	word-break: break-all;
	background-clip: padding-box;
	&[data-spacer~="lr1"] {
		padding-right: 1px;
		padding-left: 1px;
	}
	&[data-spacer~="lr5"] {
		padding-right: 5px;
		padding-left: 5px;
	}
	@include mq("min") {
		&[data-spacer~="pc:lr1"] {
			padding-right: 1px;
			padding-left: 1px;
		}
		&[data-spacer~="pc:lr5"] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
	@include mq("max") {
		&[data-spacer~="sp:lr1"] {
			padding-right: 1px;
			padding-left: 1px;
		}
		&[data-spacer~="sp:lr5"] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}

.c-table > thead > tr > th,
.c-table > tbody > tr > th {
	font-weight: 500;
}

.js-scrollbar {
	.c-table {
		@include mq("max") {
			width: 750px;
		}
	}
}

.c-table__bb-none {
	& > th,
	& > td {
		border-bottom: none !important;
	}
}