@import "../foundation/setting";

/*top
---------------------------------------------------------- */
.o-spacers {
	& > [class*="o-spacer-mt-"] {
		&:first-child {
			margin-top: 0;
		}
	}
	& > [class*="o-spacer-pt-"] {
		&:first-child {
			padding-top: 0;
		}
	}
}

.o-spacer-mt-1 {
	margin-top: 1px;
	@include mq("max") {
		margin-top: 0.5px;
	}
}

.o-spacer-mt-2 {
	margin-top: 2px;
	@include mq("max") {
		margin-top: 1px;
	}
}

.o-spacer-mt-3 {
	margin-top: 3px;
	@include mq("max") {
		margin-top: 1.5px;
	}
}

.o-spacer-mt-4 {
	margin-top: 4px;
	@include mq("max") {
		margin-top: 2px;
	}
}

.o-spacer-mt-5 {
	margin-top: 5px;
	@include mq("max") {
		margin-top: 2.5px;
	}
}

.o-spacer-mt-6 {
	margin-top: 6px;
	@include mq("max") {
		margin-top: 3px;
	}
}

.o-spacer-mt-7 {
	margin-top: 7px;
	@include mq("max") {
		margin-top: 3.5px;
	}
}

.o-spacer-mt-8 {
	margin-top: 8px;
	@include mq("max") {
		margin-top: 4px;
	}
}

.o-spacer-mt-9 {
	margin-top: 9px;
	@include mq("max") {
		margin-top: 4.5px;
	}
}

.o-spacer-mt-10 {
	margin-top: 10px;
	@include mq("max") {
		margin-top: 5px;
	}
}

.o-spacer-mt-15 {
	margin-top: 15px;
	@include mq("max") {
		margin-top: 7.5px;
	}
}

.o-spacer-mt-20 {
	margin-top: 20px;
	@include mq("max") {
		margin-top: 10px;
	}
}

.o-spacer-mt-25 {
	margin-top: 25px;
	@include mq("max") {
		margin-top: 12.5px;
	}
}

.o-spacer-mt-30 {
	margin-top: 30px;
	@include mq("max") {
		margin-top: 15px;
	}
}

.o-spacer-mt-35 {
	margin-top: 35px;
	@include mq("max") {
		margin-top: 17.5px;
	}
}

.o-spacer-mt-40 {
	margin-top: 40px;
	@include mq("max") {
		margin-top: 20px;
	}
}

.o-spacer-mt-45 {
	margin-top: 45px;
	@include mq("max") {
		margin-top: 22.5px;
	}
}

.o-spacer-mt-50 {
	margin-top: 50px;
	@include mq("max") {
		margin-top: 25px;
	}
}

.o-spacer-mt-55 {
	margin-top: 55px;
	@include mq("max") {
		margin-top: 27.5px;
	}
}

.o-spacer-mt-60 {
	margin-top: 60px;
	@include mq("max") {
		margin-top: 30px;
	}
}

.o-spacer-mt-65 {
	margin-top: 65px;
	@include mq("max") {
		margin-top: 32.5px;
	}
}

.o-spacer-mt-70 {
	margin-top: 70px;
	@include mq("max") {
		margin-top: 35px;
	}
}

.o-spacer-mt-75 {
	margin-top: 70px;
	@include mq("max") {
		margin-top: 37.5px;
	}
}

.o-spacer-mt-80 {
	margin-top: 80px;
	@include mq("max") {
		margin-top: 40px;
	}
}

.o-spacer-mt-85 {
	margin-top: 85px;
	@include mq("max") {
		margin-top: 42.5px;
	}
}

.o-spacer-mt-90 {
	margin-top: 90px;
	@include mq("max") {
		margin-top: 45px;
	}
}

.o-spacer-mt-95 {
	margin-top: 95px;
	@include mq("max") {
		margin-top: 47.5px;
	}
}

.o-spacer-mt-100 {
	margin-top: 100px;
	@include mq("max") {
		margin-top: 50px;
	}
}

.o-spacer-mt-105 {
	margin-top: 105px;
	@include mq("max") {
		margin-top: 52.5px;
	}
}

.o-spacer-mt-105 {
	margin-top: 105px;
	@include mq("max") {
		margin-top: 52.5px;
	}
}

.o-spacer-mt-110 {
	margin-top: 110px;
	@include mq("max") {
		margin-top: 55px;
	}
}

.o-spacer-mt-115 {
	margin-top: 115px;
	@include mq("max") {
		margin-top: 57.5px;
	}
}

.o-spacer-mt-120 {
	margin-top: 120px;
	@include mq("max") {
		margin-top: 60px;
	}
}

.o-spacer-mt-125 {
	margin-top: 125px;
	@include mq("max") {
		margin-top: 62.5px;
	}
}

.o-spacer-mt-130 {
	margin-top: 130px;
	@include mq("max") {
		margin-top: 65px;
	}
}

.o-spacer-mt-135 {
	margin-top: 135px;
	@include mq("max") {
		margin-top: 67.5px;
	}
}

.o-spacer-mt-140 {
	margin-top: 140px;
	@include mq("max") {
		margin-top: 70px;
	}
}

.o-spacer-mt-145 {
	margin-top: 145px;
	@include mq("max") {
		margin-top: 72.5px;
	}
}

.o-spacer-mt-150 {
	margin-top: 150px;
	@include mq("max") {
		margin-top: 75px;
	}
}

.o-spacer-mt-200 {
	margin-top: 200px;
	@include mq("max") {
		margin-top: 100px;
	}
}

.o-spacer-mt-250 {
	margin-top: 250px;
	@include mq("max") {
		margin-top: 125px;
	}
}

.o-spacer-mt-300 {
	margin-top: 300px;
	@include mq("max") {
		margin-top: 150px;
	}
}

.o-spacer-pt-0 {
	padding-top: 0 !important;
}

.o-spacer-pt-1 {
	padding-top: 1px;
	@include mq("max") {
		padding-top: 0.5px;
	}
}

.o-spacer-pt-2 {
	padding-top: 2px;
	@include mq("max") {
		padding-top: 1px;
	}
}

.o-spacer-pt-3 {
	padding-top: 3px;
	@include mq("max") {
		padding-top: 1.5px;
	}
}

.o-spacer-pt-4 {
	padding-top: 4px;
	@include mq("max") {
		padding-top: 2px;
	}
}

.o-spacer-pt-5 {
	padding-top: 5px;
	@include mq("max") {
		padding-top: 2.5px;
	}
}

.o-spacer-pt-6 {
	padding-top: 6px;
	@include mq("max") {
		padding-top: 3px;
	}
}

.o-spacer-pt-7 {
	padding-top: 7px;
	@include mq("max") {
		padding-top: 3.5px;
	}
}

.o-spacer-pt-8 {
	padding-top: 8px;
	@include mq("max") {
		padding-top: 4px;
	}
}

.o-spacer-pt-9 {
	padding-top: 9px;
	@include mq("max") {
		padding-top: 4.5px;
	}
}

.o-spacer-pt-10 {
	padding-top: 10px;
	@include mq("max") {
		padding-top: 5px;
	}
}

.o-spacer-pt-15 {
	padding-top: 15px;
	@include mq("max") {
		padding-top: 7.5px;
	}
}

.o-spacer-pt-20 {
	padding-top: 20px;
	@include mq("max") {
		padding-top: 10px;
	}
}

.o-spacer-pt-25 {
	padding-top: 25px;
	@include mq("max") {
		padding-top: 12.5px;
	}
}

.o-spacer-pt-30 {
	padding-top: 30px;
	@include mq("max") {
		padding-top: 15px;
	}
}

.o-spacer-pt-35 {
	padding-top: 35px;
	@include mq("max") {
		padding-top: 17.5px;
	}
}

.o-spacer-pt-40 {
	padding-top: 40px;
	@include mq("max") {
		padding-top: 20px;
	}
}

.o-spacer-pt-45 {
	padding-top: 45px;
	@include mq("max") {
		padding-top: 22.5px;
	}
}

.o-spacer-pt-50 {
	padding-top: 50px;
	@include mq("max") {
		padding-top: 25px;
	}
}

.o-spacer-pt-55 {
	padding-top: 55px;
	@include mq("max") {
		padding-top: 27.5px;
	}
}

.o-spacer-pt-60 {
	padding-top: 60px;
	@include mq("max") {
		padding-top: 30px;
	}
}

.o-spacer-pt-65 {
	padding-top: 65px;
	@include mq("max") {
		padding-top: 32.5px;
	}
}

.o-spacer-pt-70 {
	padding-top: 70px;
	@include mq("max") {
		padding-top: 35px;
	}
}

.o-spacer-pt-75 {
	padding-top: 75px;
	@include mq("max") {
		padding-top: 37.5px;
	}
}

.o-spacer-pt-80 {
	padding-top: 80px;
	@include mq("max") {
		padding-top: 40px;
	}
}

.o-spacer-pt-90 {
	padding-top: 90px;
	@include mq("max") {
		padding-top: 45px;
	}
}

.o-spacer-pt-95 {
	padding-top: 95px;
	@include mq("max") {
		padding-top: 47.5px;
	}
}

.o-spacer-pt-100 {
	padding-top: 100px;
	@include mq("max") {
		padding-top: 50px;
	}
}

.o-spacer-pt-105 {
	padding-top: 105px;
	@include mq("max") {
		padding-top: 52.5px;
	}
}

.o-spacer-pt-110 {
	padding-top: 110px;
	@include mq("max") {
		padding-top: 55px;
	}
}

.o-spacer-pt-115 {
	padding-top: 115px;
	@include mq("max") {
		padding-top: 57.5px;
	}
}

.o-spacer-pt-120 {
	padding-top: 120px;
	@include mq("max") {
		padding-top: 60px;
	}
}

.o-spacer-pt-125 {
	padding-top: 125px;
	@include mq("max") {
		padding-top: 62.5px;
	}
}

.o-spacer-pt-130 {
	padding-top: 130px;
	@include mq("max") {
		padding-top: 65px;
	}
}

.o-spacer-pt-135 {
	padding-top: 135px;
	@include mq("max") {
		padding-top: 65px;
	}
}

.o-spacer-pt-140 {
	padding-top: 140px;
	@include mq("max") {
		padding-top: 70px;
	}
}

.o-spacer-pt-145 {
	padding-top: 145px;
	@include mq("max") {
		padding-top: 72.5px;
	}
}

.o-spacer-pt-150 {
	padding-top: 150px;
	@include mq("max") {
		padding-top: 75px;
	}
}

.o-spacer-pt-200 {
	padding-top: 200px;
	@include mq("max") {
		padding-top: 100px;
	}
}

.o-spacer-pt-250 {
	padding-top: 250px;
	@include mq("max") {
		padding-top: 125px;
	}
}

.o-spacer-pt-300 {
	padding-top: 300px;
	@include mq("max") {
		padding-top: 150px;
	}
}

@include mq("min") {
	.o-spacer-mt-1-pc {
		margin-top: 1px;
	}

	.o-spacer-mt-2-pc {
		margin-top: 2px;
	}

	.o-spacer-mt-3-pc {
		margin-top: 3px;
	}

	.o-spacer-mt-4-pc {
		margin-top: 4px;
	}

	.o-spacer-mt-5-pc {
		margin-top: 5px;
	}

	.o-spacer-mt-6-pc {
		margin-top: 6px;
	}

	.o-spacer-mt-7-pc {
		margin-top: 7px;
	}

	.o-spacer-mt-8-pc {
		margin-top: 8px;
	}

	.o-spacer-mt-9-pc {
		margin-top: 9px;
	}

	.o-spacer-mt-10-pc {
		margin-top: 10px;
	}

	.o-spacer-mt-15-pc {
		margin-top: 15px;
	}

	.o-spacer-mt-20-pc {
		margin-top: 20px;
	}

	.o-spacer-mt-25-pc {
		margin-top: 25px;
	}

	.o-spacer-mt-30-pc {
		margin-top: 30px;
	}

	.o-spacer-mt-35-pc {
		margin-top: 35px;
	}

	.o-spacer-mt-40-pc {
		margin-top: 40px;
	}

	.o-spacer-mt-45-pc {
		margin-top: 45px;
	}

	.o-spacer-mt-50-pc {
		margin-top: 50px;
	}

	.o-spacer-mt-55-pc {
		margin-top: 55px;
	}

	.o-spacer-mt-60-pc {
		margin-top: 60px;
	}

	.o-spacer-mt-60-pc {
		margin-top: 60px;
	}

	.o-spacer-mt-65-pc {
		margin-top: 65px;
	}

	.o-spacer-mt-70-pc {
		margin-top: 70px;
	}

	.o-spacer-mt-75-pc {
		margin-top: 70px;
	}

	.o-spacer-mt-80-pc {
		margin-top: 80px;
	}

	.o-spacer-mt-85-pc {
		margin-top: 85px;
	}

	.o-spacer-mt-90-pc {
		margin-top: 90px;
	}

	.o-spacer-mt-95-pc {
		margin-top: 95px;
	}

	.o-spacer-mt-100-pc {
		margin-top: 100px;
	}

	.o-spacer-mt-105-pc {
		margin-top: 105px;
	}

	.o-spacer-mt-105-pc {
		margin-top: 105px;
	}

	.o-spacer-mt-110-pc {
		margin-top: 110px;
	}

	.o-spacer-mt-115-pc {
		margin-top: 115px;
	}

	.o-spacer-mt-120-pc {
		margin-top: 120px;
	}

	.o-spacer-mt-125-pc {
		margin-top: 125px;
	}

	.o-spacer-mt-130-pc {
		margin-top: 130px;
	}

	.o-spacer-mt-135-pc {
		margin-top: 135px;
	}

	.o-spacer-mt-140-pc {
		margin-top: 140px;
	}

	.o-spacer-mt-145-pc {
		margin-top: 145px;
	}

	.o-spacer-mt-150-pc {
		margin-top: 150px;
	}

	.o-spacer-mt-200-pc {
		margin-top: 200px;
	}

	.o-spacer-mt-250-pc {
		margin-top: 250px;
	}

	.o-spacer-mt-300-pc {
		margin-top: 300px;
	}

	.o-spacer-pt-1-pc {
		padding-top: 1px;
	}

	.o-spacer-pt-2-pc {
		padding-top: 2px;
	}

	.o-spacer-pt-3-pc {
		padding-top: 3px;
	}

	.o-spacer-pt-4-pc {
		padding-top: 4px;
	}

	.o-spacer-pt-5-pc {
		padding-top: 5px;
	}

	.o-spacer-pt-6-pc {
		padding-top: 6px;
	}

	.o-spacer-pt-7-pc {
		padding-top: 7px;
	}

	.o-spacer-pt-8-pc {
		padding-top: 8px;
	}

	.o-spacer-pt-9-pc {
		padding-top: 9px;
	}

	.o-spacer-pt-10-pc {
		padding-top: 10px;
	}

	.o-spacer-pt-15-pc {
		padding-top: 15px;
	}

	.o-spacer-pt-20-pc {
		padding-top: 20px;
	}

	.o-spacer-pt-25-pc {
		padding-top: 25px;
	}

	.o-spacer-pt-30-pc {
		padding-top: 30px;
	}

	.o-spacer-pt-35-pc {
		padding-top: 35px;
	}

	.o-spacer-pt-40-pc {
		padding-top: 40px;
	}

	.o-spacer-pt-45-pc {
		padding-top: 45px;
	}

	.o-spacer-pt-50-pc {
		padding-top: 50px;
	}

	.o-spacer-pt-55-pc {
		padding-top: 55px;
	}

	.o-spacer-pt-60-pc {
		padding-top: 60px;
	}

	.o-spacer-pt-65-pc {
		padding-top: 65px;
	}

	.o-spacer-pt-70-pc {
		padding-top: 70px;
	}

	.o-spacer-pt-75-pc {
		padding-top: 75px;
	}

	.o-spacer-pt-80-pc {
		padding-top: 80px;
	}

	.o-spacer-pt-90-pc {
		padding-top: 90px;
	}

	.o-spacer-pt-95-pc {
		padding-top: 95px;
	}

	.o-spacer-pt-100-pc {
		padding-top: 100px;
	}

	.o-spacer-pt-105-pc {
		padding-top: 105px;
	}

	.o-spacer-pt-110-pc {
		padding-top: 110px;
	}

	.o-spacer-pt-115-pc {
		padding-top: 115px;
	}

	.o-spacer-pt-120-pc {
		padding-top: 120px;
	}

	.o-spacer-pt-125-pc {
		padding-top: 125px;
	}

	.o-spacer-pt-130-pc {
		padding-top: 130px;
	}

	.o-spacer-pt-135-pc {
		padding-top: 135px;
	}

	.o-spacer-pt-140-pc {
		padding-top: 140px;
	}

	.o-spacer-pt-145-pc {
		padding-top: 145px;
	}

	.o-spacer-pt-150-pc {
		padding-top: 150px;
	}

	.o-spacer-pt-200-pc {
		padding-top: 200px;
	}

	.o-spacer-pt-250-pc {
		padding-top: 250px;
	}

	.o-spacer-pt-300-pc {
		padding-top: 300px;
	}
}

@include mq("max") {
	.o-spacer-mt-1-sp {
		margin-top: 0.5px;
	}

	.o-spacer-mt-2-sp {
		margin-top: 1px;
	}

	.o-spacer-mt-3-sp {
		margin-top: 1.5px;
	}

	.o-spacer-mt-4-sp {
		margin-top: 2px;
	}

	.o-spacer-mt-5-sp {
		margin-top: 2.5px;
	}

	.o-spacer-mt-6-sp {
		margin-top: 3px;
	}

	.o-spacer-mt-7-sp {
		margin-top: 3.5px;
	}

	.o-spacer-mt-8-sp {
		margin-top: 4px;
	}

	.o-spacer-mt-9-sp {
		margin-top: 4.5px;
	}

	.o-spacer-mt-10-sp {
		margin-top: 5px;
	}

	.o-spacer-mt-15-sp {
		margin-top: 7.5px;
	}

	.o-spacer-mt-20-sp {
		margin-top: 10px;
	}

	.o-spacer-mt-25-sp {
		margin-top: 12.5px;
	}

	.o-spacer-mt-30-sp {
		margin-top: 15px;
	}

	.o-spacer-mt-35-sp {
		margin-top: 17.5px;
	}

	.o-spacer-mt-40-sp {
		margin-top: 20px;
	}

	.o-spacer-mt-45-sp {
		margin-top: 22.5px;
	}

	.o-spacer-mt-50-sp {
		margin-top: 25px;
	}

	.o-spacer-mt-55-sp {
		margin-top: 27.5px;
	}

	.o-spacer-mt-60-sp {
		margin-top: 30px;
	}

	.o-spacer-mt-65-sp {
		margin-top: 32.5px;
	}

	.o-spacer-mt-70-sp {
		margin-top: 35px;
	}

	.o-spacer-mt-75-sp {
		margin-top: 37.5px;
	}

	.o-spacer-mt-80-sp {
		margin-top: 40px;
	}

	.o-spacer-mt-85-sp {
		margin-top: 42.5px;
	}

	.o-spacer-mt-90-sp {
		margin-top: 45px;
	}

	.o-spacer-mt-95-sp {
		margin-top: 47.5px;
	}

	.o-spacer-mt-100-sp {
		margin-top: 50px;
	}

	.o-spacer-mt-105-sp {
		margin-top: 52.5px;
	}

	.o-spacer-mt-105-sp {
		margin-top: 52.5px;
	}

	.o-spacer-mt-110-sp {
		margin-top: 55px;
	}

	.o-spacer-mt-115-sp {
		margin-top: 57.5px;
	}

	.o-spacer-mt-120-sp {
		margin-top: 60px;
	}

	.o-spacer-mt-125-sp {
		margin-top: 62.5px;
	}

	.o-spacer-mt-130-sp {
		margin-top: 65px;
	}

	.o-spacer-mt-135-sp {
		margin-top: 67.5px;
	}

	.o-spacer-mt-140-sp {
		margin-top: 70px;
	}

	.o-spacer-mt-145-sp {
		margin-top: 72.5px;
	}

	.o-spacer-mt-150-sp {
		margin-top: 75px;
	}

	.o-spacer-mt-200-sp {
		margin-top: 100px;
	}

	.o-spacer-mt-250-sp {
		margin-top: 125px;
	}

	.o-spacer-mt-300-sp {
		margin-top: 150px;
	}

	.o-spacer-pt-1-sp {
		padding-top: 0.5px;
	}

	.o-spacer-pt-2-sp {
		padding-top: 1px;
	}

	.o-spacer-pt-3-sp {
		padding-top: 1.5px;
	}

	.o-spacer-pt-4-sp {
		padding-top: 2px;
	}

	.o-spacer-pt-5-sp {
		padding-top: 2.5px;
	}

	.o-spacer-pt-6-sp {
		padding-top: 3px;
	}

	.o-spacer-pt-7-sp {
		padding-top: 3.5px;
	}

	.o-spacer-pt-8-sp {
		padding-top: 4px;
	}

	.o-spacer-pt-9-sp {
		padding-top: 4.5px;
	}

	.o-spacer-pt-10-sp {
		padding-top: 5px;
	}

	.o-spacer-pt-15-sp {
		padding-top: 7.5px;
	}

	.o-spacer-pt-20-sp {
		padding-top: 10px;
	}

	.o-spacer-pt-25-sp {
		padding-top: 12.5px;
	}

	.o-spacer-pt-30-sp {
		padding-top: 15px;
	}

	.o-spacer-pt-35-sp {
		padding-top: 17.5px;
	}

	.o-spacer-pt-40-sp {
		padding-top: 20px;
	}

	.o-spacer-pt-45-sp {
		padding-top: 22.5px;
	}

	.o-spacer-pt-50-sp {
		padding-top: 25px;
	}

	.o-spacer-pt-55-sp {
		padding-top: 27.5px;
	}

	.o-spacer-pt-60-sp {
		padding-top: 30px;
	}

	.o-spacer-pt-65-sp {
		padding-top: 32.5px;
	}

	.o-spacer-pt-70-sp {
		padding-top: 35px;
	}

	.o-spacer-pt-75-sp {
		padding-top: 37.5px;
	}

	.o-spacer-pt-80-sp {
		padding-top: 40px;
	}

	.o-spacer-pt-90-sp {
		padding-top: 45px;
	}

	.o-spacer-pt-95-sp {
		padding-top: 47.5px;
	}

	.o-spacer-pt-100-sp {
		padding-top: 50px;
	}

	.o-spacer-pt-105-sp {
		padding-top: 52.5px;
	}

	.o-spacer-pt-110-sp {
		padding-top: 55px;
	}

	.o-spacer-pt-115-sp {
		padding-top: 57.5px;
	}

	.o-spacer-pt-120-sp {
		padding-top: 60px;
	}

	.o-spacer-pt-125-sp {
		padding-top: 62.5px;
	}

	.o-spacer-pt-130-sp {
		padding-top: 65px;
	}

	.o-spacer-pt-135-sp {
		padding-top: 65px;
	}

	.o-spacer-pt-140-sp {
		padding-top: 70px;
	}

	.o-spacer-pt-145-sp {
		padding-top: 72.5px;
	}

	.o-spacer-pt-150-sp {
		padding-top: 75px;
	}

	.o-spacer-pt-200-sp {
		margin-top: 100px;
	}

	.o-spacer-pt-250-sp {
		margin-top: 125px;
	}

	.o-spacer-pt-300-sp {
		margin-top: 150px;
	}
}

/*bottom
---------------------------------------------------------- */
.o-spacers {
	& > [class*="o-spacer-mb-"] {
		&:last-child {
			margin-bottom: 0;
		}
	}
	& > [class*="o-spacer-pb-"] {
		&:first-child {
			padding-bottom: 0;
		}
	}
}
.o-spacer-mb-1 {
	margin-bottom: 1px;
	@include mq("max") {
		margin-bottom: 0.5px;
	}
}

.o-spacer-mb-2 {
	margin-bottom: 2px;
	@include mq("max") {
		margin-bottom: 1px;
	}
}

.o-spacer-mb-3 {
	margin-bottom: 3px;
	@include mq("max") {
		margin-bottom: 1.5px;
	}
}

.o-spacer-mb-4 {
	margin-bottom: 4px;
	@include mq("max") {
		margin-bottom: 2px;
	}
}

.o-spacer-mb-5 {
	margin-bottom: 5px;
	@include mq("max") {
		margin-bottom: 2.5px;
	}
}

.o-spacer-mb-6 {
	margin-bottom: 6px;
	@include mq("max") {
		margin-bottom: 3px;
	}
}

.o-spacer-mb-7 {
	margin-bottom: 7px;
	@include mq("max") {
		margin-bottom: 3.5px;
	}
}

.o-spacer-mb-8 {
	margin-bottom: 8px;
	@include mq("max") {
		margin-bottom: 4px;
	}
}

.o-spacer-mb-9 {
	margin-bottom: 9px;
	@include mq("max") {
		margin-bottom: 4.5px;
	}
}

.o-spacer-mb-10 {
	margin-bottom: 10px;
	@include mq("max") {
		margin-bottom: 5px;
	}
}

.o-spacer-mb-15 {
	margin-bottom: 15px;
	@include mq("max") {
		margin-bottom: 7.5px;
	}
}

.o-spacer-mb-20 {
	margin-bottom: 20px;
	@include mq("max") {
		margin-bottom: 10px;
	}
}

.o-spacer-mb-25 {
	margin-bottom: 25px;
	@include mq("max") {
		margin-bottom: 12.5px;
	}
}

.o-spacer-mb-30 {
	margin-bottom: 30px;
	@include mq("max") {
		margin-bottom: 15px;
	}
}

.o-spacer-mb-35 {
	margin-bottom: 35px;
	@include mq("max") {
		margin-bottom: 17.5px;
	}
}

.o-spacer-mb-40 {
	margin-bottom: 40px;
	@include mq("max") {
		margin-bottom: 20px;
	}
}

.o-spacer-mb-45 {
	margin-bottom: 45px;
	@include mq("max") {
		margin-bottom: 22.5px;
	}
}

.o-spacer-mb-50 {
	margin-bottom: 50px;
	@include mq("max") {
		margin-bottom: 25px;
	}
}

.o-spacer-mb-55 {
	margin-bottom: 55px;
	@include mq("max") {
		margin-bottom: 27.5px;
	}
}

.o-spacer-mb-60 {
	margin-bottom: 60px;
	@include mq("max") {
		margin-bottom: 30px;
	}
}

.o-spacer-mb-65 {
	margin-bottom: 65px;
	@include mq("max") {
		margin-bottom: 32.5px;
	}
}

.o-spacer-mb-70 {
	margin-bottom: 70px;
	@include mq("max") {
		margin-bottom: 35px;
	}
}

.o-spacer-mb-75 {
	margin-bottom: 70px;
	@include mq("max") {
		margin-bottom: 37.5px;
	}
}

.o-spacer-mb-80 {
	margin-bottom: 80px;
	@include mq("max") {
		margin-bottom: 40px;
	}
}

.o-spacer-mb-85 {
	margin-bottom: 85px;
	@include mq("max") {
		margin-bottom: 42.5px;
	}
}

.o-spacer-mb-90 {
	margin-bottom: 90px;
	@include mq("max") {
		margin-bottom: 45px;
	}
}

.o-spacer-mb-95 {
	margin-bottom: 95px;
	@include mq("max") {
		margin-bottom: 47.5px;
	}
}

.o-spacer-mb-100 {
	margin-bottom: 100px;
	@include mq("max") {
		margin-bottom: 50px;
	}
}

.o-spacer-mb-105 {
	margin-bottom: 105px;
	@include mq("max") {
		margin-bottom: 52.5px;
	}
}

.o-spacer-mb-105 {
	margin-bottom: 105px;
	@include mq("max") {
		margin-bottom: 52.5px;
	}
}

.o-spacer-mb-110 {
	margin-bottom: 110px;
	@include mq("max") {
		margin-bottom: 55px;
	}
}

.o-spacer-mb-115 {
	margin-bottom: 115px;
	@include mq("max") {
		margin-bottom: 57.5px;
	}
}

.o-spacer-mb-120 {
	margin-bottom: 120px;
	@include mq("max") {
		margin-bottom: 60px;
	}
}

.o-spacer-mb-125 {
	margin-bottom: 125px;
	@include mq("max") {
		margin-bottom: 62.5px;
	}
}

.o-spacer-mb-130 {
	margin-bottom: 130px;
	@include mq("max") {
		margin-bottom: 65px;
	}
}

.o-spacer-mb-135 {
	margin-bottom: 135px;
	@include mq("max") {
		margin-bottom: 67.5px;
	}
}

.o-spacer-mb-140 {
	margin-bottom: 140px;
	@include mq("max") {
		margin-bottom: 70px;
	}
}

.o-spacer-mb-145 {
	margin-bottom: 145px;
	@include mq("max") {
		margin-bottom: 72.5px;
	}
}

.o-spacer-mb-150 {
	margin-bottom: 150px;
	@include mq("max") {
		margin-bottom: 75px;
	}
}

.o-spacer-mb-160 {
	margin-bottom: 160px;
	@include mq("max") {
		margin-bottom: 80px;
	}
}

.o-spacer-mb-200 {
	margin-bottom: 200px;
	@include mq("max") {
		margin-bottom: 100px;
	}
}

.o-spacer-mb-250 {
	margin-bottom: 250px;
	@include mq("max") {
		margin-bottom: 125px;
	}
}

.o-spacer-mb-300 {
	margin-bottom: 300px;
	@include mq("max") {
		margin-bottom: 150px;
	}
}

.o-spacer-pb-0 {
	padding-bottom: 0 !important;
}

.o-spacer-pb-1 {
	padding-bottom: 1px;
	@include mq("max") {
		padding-bottom: 0.5px;
	}
}

.o-spacer-pb-2 {
	padding-bottom: 2px;
	@include mq("max") {
		padding-bottom: 1px;
	}
}

.o-spacer-pb-3 {
	padding-bottom: 3px;
	@include mq("max") {
		padding-bottom: 1.5px;
	}
}

.o-spacer-pb-4 {
	padding-bottom: 4px;
	@include mq("max") {
		padding-bottom: 2px;
	}
}

.o-spacer-pb-5 {
	padding-bottom: 5px;
	@include mq("max") {
		padding-bottom: 2.5px;
	}
}

.o-spacer-pb-6 {
	padding-bottom: 6px;
	@include mq("max") {
		padding-bottom: 3px;
	}
}

.o-spacer-pb-7 {
	padding-bottom: 7px;
	@include mq("max") {
		padding-bottom: 3.5px;
	}
}

.o-spacer-pb-8 {
	padding-bottom: 8px;
	@include mq("max") {
		padding-bottom: 4px;
	}
}

.o-spacer-pb-9 {
	padding-bottom: 9px;
	@include mq("max") {
		padding-bottom: 4.5px;
	}
}

.o-spacer-pb-10 {
	padding-bottom: 10px;
	@include mq("max") {
		padding-bottom: 5px;
	}
}

.o-spacer-pb-15 {
	padding-bottom: 15px;
	@include mq("max") {
		padding-bottom: 7.5px;
	}
}

.o-spacer-pb-20 {
	padding-bottom: 20px;
	@include mq("max") {
		padding-bottom: 10px;
	}
}

.o-spacer-pb-25 {
	padding-bottom: 25px;
	@include mq("max") {
		padding-bottom: 12.5px;
	}
}

.o-spacer-pb-30 {
	padding-bottom: 30px;
	@include mq("max") {
		padding-bottom: 15px;
	}
}

.o-spacer-pb-35 {
	padding-bottom: 35px;
	@include mq("max") {
		padding-bottom: 17.5px;
	}
}

.o-spacer-pb-40 {
	padding-bottom: 40px;
	@include mq("max") {
		padding-bottom: 20px;
	}
}

.o-spacer-pb-45 {
	padding-bottom: 45px;
	@include mq("max") {
		padding-bottom: 22.5px;
	}
}

.o-spacer-pb-50 {
	padding-bottom: 50px;
	@include mq("max") {
		padding-bottom: 25px;
	}
}

.o-spacer-pb-55 {
	padding-bottom: 55px;
	@include mq("max") {
		padding-bottom: 27.5px;
	}
}

.o-spacer-pb-60 {
	padding-bottom: 60px;
	@include mq("max") {
		padding-bottom: 30px;
	}
}

.o-spacer-pb-65 {
	padding-bottom: 65px;
	@include mq("max") {
		padding-bottom: 32.5px;
	}
}

.o-spacer-pb-70 {
	padding-bottom: 70px;
	@include mq("max") {
		padding-bottom: 35px;
	}
}

.o-spacer-pb-75 {
	padding-bottom: 75px;
	@include mq("max") {
		padding-bottom: 37.5px;
	}
}

.o-spacer-pb-80 {
	padding-bottom: 80px;
	@include mq("max") {
		padding-bottom: 40px;
	}
}

.o-spacer-pb-90 {
	padding-bottom: 90px;
	@include mq("max") {
		padding-bottom: 45px;
	}
}

.o-spacer-pb-95 {
	padding-bottom: 95px;
	@include mq("max") {
		padding-bottom: 47.5px;
	}
}

.o-spacer-pb-100 {
	padding-bottom: 100px;
	@include mq("max") {
		padding-bottom: 50px;
	}
}

.o-spacer-pb-105 {
	padding-bottom: 105px;
	@include mq("max") {
		padding-bottom: 52.5px;
	}
}

.o-spacer-pb-110 {
	padding-bottom: 110px;
	@include mq("max") {
		padding-bottom: 55px;
	}
}

.o-spacer-pb-115 {
	padding-bottom: 115px;
	@include mq("max") {
		padding-bottom: 57.5px;
	}
}

.o-spacer-pb-120 {
	padding-bottom: 120px;
	@include mq("max") {
		padding-bottom: 60px;
	}
}

.o-spacer-pb-125 {
	padding-bottom: 125px;
	@include mq("max") {
		padding-bottom: 62.5px;
	}
}

.o-spacer-pb-130 {
	padding-bottom: 130px;
	@include mq("max") {
		padding-bottom: 65px;
	}
}

.o-spacer-pb-135 {
	padding-bottom: 135px;
	@include mq("max") {
		padding-bottom: 65px;
	}
}

.o-spacer-pb-140 {
	padding-bottom: 140px;
	@include mq("max") {
		padding-bottom: 70px;
	}
}

.o-spacer-pb-145 {
	padding-bottom: 145px;
	@include mq("max") {
		padding-bottom: 72.5px;
	}
}

.o-spacer-pb-150 {
	padding-bottom: 150px;
	@include mq("max") {
		padding-bottom: 75px;
	}
}

.o-spacer-pb-160 {
	padding-bottom: 160px;
	@include mq("max") {
		padding-bottom: 80px;
	}
}

.o-spacer-pb-200 {
	padding-bottom: 200px;
	@include mq("max") {
		padding-bottom: 100px;
	}
}

.o-spacer-pb-250 {
	padding-bottom: 250px;
	@include mq("max") {
		padding-bottom: 125px;
	}
}

.o-spacer-pb-300 {
	padding-bottom: 300px;
	@include mq("max") {
		padding-bottom: 150px;
	}
}

@include mq("min") {
	.o-spacer-mb-1-pc {
		margin-bottom: 1px;
	}

	.o-spacer-mb-2-pc {
		margin-bottom: 2px;
	}

	.o-spacer-mb-3-pc {
		margin-bottom: 3px;
	}

	.o-spacer-mb-4-pc {
		margin-bottom: 4px;
	}

	.o-spacer-mb-5-pc {
		margin-bottom: 5px;
	}

	.o-spacer-mb-6-pc {
		margin-bottom: 6px;
	}

	.o-spacer-mb-7-pc {
		margin-bottom: 7px;
	}

	.o-spacer-mb-8-pc {
		margin-bottom: 8px;
	}

	.o-spacer-mb-9-pc {
		margin-bottom: 9px;
	}

	.o-spacer-mb-10-pc {
		margin-bottom: 10px;
	}

	.o-spacer-mb-15-pc {
		margin-bottom: 15px;
	}

	.o-spacer-mb-20-pc {
		margin-bottom: 20px;
	}

	.o-spacer-mb-25-pc {
		margin-bottom: 25px;
	}

	.o-spacer-mb-30-pc {
		margin-bottom: 30px;
	}

	.o-spacer-mb-35-pc {
		margin-bottom: 35px;
	}

	.o-spacer-mb-40-pc {
		margin-bottom: 40px;
	}

	.o-spacer-mb-45-pc {
		margin-bottom: 45px;
	}

	.o-spacer-mb-50-pc {
		margin-bottom: 50px;
	}

	.o-spacer-mb-55-pc {
		margin-bottom: 55px;
	}

	.o-spacer-mb-60-pc {
		margin-bottom: 60px;
	}

	.o-spacer-mb-65-pc {
		margin-bottom: 65px;
	}

	.o-spacer-mb-70-pc {
		margin-bottom: 70px;
	}

	.o-spacer-mb-75-pc {
		margin-bottom: 70px;
	}

	.o-spacer-mb-80-pc {
		margin-bottom: 80px;
	}

	.o-spacer-mb-85-pc {
		margin-bottom: 85px;
	}

	.o-spacer-mb-90-pc {
		margin-bottom: 90px;
	}

	.o-spacer-mb-95-pc {
		margin-bottom: 95px;
	}

	.o-spacer-mb-100-pc {
		margin-bottom: 100px;
	}

	.o-spacer-mb-105-pc {
		margin-bottom: 105px;
	}

	.o-spacer-mb-105-pc {
		margin-bottom: 105px;
	}

	.o-spacer-mb-110-pc {
		margin-bottom: 110px;
	}

	.o-spacer-mb-115-pc {
		margin-bottom: 115px;
	}

	.o-spacer-mb-120-pc {
		margin-bottom: 120px;
	}

	.o-spacer-mb-125-pc {
		margin-bottom: 125px;
	}

	.o-spacer-mb-130-pc {
		margin-bottom: 130px;
	}

	.o-spacer-mb-135-pc {
		margin-bottom: 135px;
	}

	.o-spacer-mb-140-pc {
		margin-bottom: 140px;
	}

	.o-spacer-mb-145-pc {
		margin-bottom: 145px;
	}

	.o-spacer-mb-150-pc {
		margin-bottom: 150px;
	}

	.o-spacer-mb-160-pc {
		margin-bottom: 160px;
	}

	.o-spacer-mb-200-pc {
		margin-bottom: 200px;
	}

	.o-spacer-mb-250-pc {
		margin-bottom: 250px;
	}

	.o-spacer-mb-300-pc {
		margin-bottom: 300px;
	}

	.o-spacer-pb-0-pc {
		padding-bottom: 0 !important;
	}

	.o-spacer-pb-1-pc {
		padding-bottom: 1px;
	}

	.o-spacer-pb-2-pc {
		padding-bottom: 2px;
	}

	.o-spacer-pb-3-pc {
		padding-bottom: 3px;
	}

	.o-spacer-pb-4-pc {
		padding-bottom: 4px;
	}

	.o-spacer-pb-5-pc {
		padding-bottom: 5px;
	}

	.o-spacer-pb-6-pc {
		padding-bottom: 6px;
	}

	.o-spacer-pb-7-pc {
		padding-bottom: 7px;
	}

	.o-spacer-pb-8-pc {
		padding-bottom: 8px;
	}

	.o-spacer-pb-9-pc {
		padding-bottom: 9px;
	}

	.o-spacer-pb-10-pc {
		padding-bottom: 10px;
	}

	.o-spacer-pb-15-pc {
		padding-bottom: 15px;
	}

	.o-spacer-pb-20-pc {
		padding-bottom: 20px;
	}

	.o-spacer-pb-25-pc {
		padding-bottom: 25px;
	}

	.o-spacer-pb-30-pc {
		padding-bottom: 30px;
	}

	.o-spacer-pb-35-pc {
		padding-bottom: 35px;
	}

	.o-spacer-pb-40-pc {
		padding-bottom: 40px;
	}

	.o-spacer-pb-45-pc {
		padding-bottom: 45px;
	}

	.o-spacer-pb-50-pc {
		padding-bottom: 50px;
	}

	.o-spacer-pb-55-pc {
		padding-bottom: 55px;
	}

	.o-spacer-pb-60-pc {
		padding-bottom: 60px;
	}

	.o-spacer-pb-65-pc {
		padding-bottom: 65px;
	}

	.o-spacer-pb-70-pc {
		padding-bottom: 70px;
	}

	.o-spacer-pb-75-pc {
		padding-bottom: 75px;
	}

	.o-spacer-pb-80-pc {
		padding-bottom: 80px;
	}

	.o-spacer-pb-90-pc {
		padding-bottom: 90px;
	}

	.o-spacer-pb-95-pc {
		padding-bottom: 95px;
	}

	.o-spacer-pb-100-pc {
		padding-bottom: 100px;
	}

	.o-spacer-pb-105-pc {
		padding-bottom: 105px;
	}

	.o-spacer-pb-110-pc {
		padding-bottom: 110px;
	}

	.o-spacer-pb-115-pc {
		padding-bottom: 115px;
	}

	.o-spacer-pb-120-pc {
		padding-bottom: 120px;
	}

	.o-spacer-pb-125-pc {
		padding-bottom: 125px;
	}

	.o-spacer-pb-130-pc {
		padding-bottom: 130px;
	}

	.o-spacer-pb-135-pc {
		padding-bottom: 135px;
	}

	.o-spacer-pb-140-pc {
		padding-bottom: 140px;
	}

	.o-spacer-pb-145-pc {
		padding-bottom: 145px;
	}

	.o-spacer-pb-150-pc {
		padding-bottom: 150px;
	}

	.o-spacer-pb-160-pc {
		padding-bottom: 160px;
	}

	.o-spacer-pb-200-pc {
		padding-bottom: 200px;
	}

	.o-spacer-pb-250-pc {
		padding-bottom: 250px;
	}

	.o-spacer-pb-300-pc {
		padding-bottom: 300px;
	}
}

@include mq("max") {
	.o-spacer-mb-1-sp {
		margin-bottom: 0.5px;
	}

	.o-spacer-mb-2-sp {
		margin-bottom: 1px;
	}

	.o-spacer-mb-3-sp {
		margin-bottom: 1.5px;
	}

	.o-spacer-mb-4-sp {
		margin-bottom: 2px;
	}

	.o-spacer-mb-5-sp {
		margin-bottom: 2.5px;
	}

	.o-spacer-mb-6-sp {
		margin-bottom: 3px;
	}

	.o-spacer-mb-7-sp {
		margin-bottom: 3.5px;
	}

	.o-spacer-mb-8-sp {
		margin-bottom: 4px;
	}

	.o-spacer-mb-9-sp {
		margin-bottom: 4.5px;
	}

	.o-spacer-mb-10-sp {
		margin-bottom: 5px;
	}

	.o-spacer-mb-15-sp {
		margin-bottom: 7.5px;
	}

	.o-spacer-mb-20-sp {
		margin-bottom: 10px;
	}

	.o-spacer-mb-25-sp {
		margin-bottom: 12.5px;
	}

	.o-spacer-mb-30-sp {
		margin-bottom: 15px;
	}

	.o-spacer-mb-35-sp {
		margin-bottom: 17.5px;
	}

	.o-spacer-mb-40-sp {
		margin-bottom: 20px;
	}

	.o-spacer-mb-45-sp {
		margin-bottom: 22.5px;
	}

	.o-spacer-mb-50-sp {
		margin-bottom: 25px;
	}

	.o-spacer-mb-55-sp {
		margin-bottom: 27.5px;
	}

	.o-spacer-mb-60-sp {
		margin-bottom: 30px;
	}

	.o-spacer-mb-65-sp {
		margin-bottom: 32.5px;
	}

	.o-spacer-mb-70-sp {
		margin-bottom: 35px;
	}

	.o-spacer-mb-75-sp {
		margin-bottom: 37.5px;
	}

	.o-spacer-mb-80-sp {
		margin-bottom: 40px;
	}

	.o-spacer-mb-85-sp {
		margin-bottom: 42.5px;
	}

	.o-spacer-mb-90-sp {
		margin-bottom: 45px;
	}

	.o-spacer-mb-95-sp {
		margin-bottom: 47.5px;
	}

	.o-spacer-mb-100-sp {
		margin-bottom: 50px;
	}

	.o-spacer-mb-105-sp {
		margin-bottom: 52.5px;
	}

	.o-spacer-mb-105-sp {
		margin-bottom: 52.5px;
	}

	.o-spacer-mb-110-sp {
		margin-bottom: 55px;
	}

	.o-spacer-mb-115-sp {
		margin-bottom: 57.5px;
	}

	.o-spacer-mb-120-sp {
		margin-bottom: 60px;
	}

	.o-spacer-mb-125-sp {
		margin-bottom: 62.5px;
	}

	.o-spacer-mb-130-sp {
		margin-bottom: 65px;
	}

	.o-spacer-mb-135-sp {
		margin-bottom: 67.5px;
	}

	.o-spacer-mb-140-sp {
		margin-bottom: 70px;
	}

	.o-spacer-mb-145-sp {
		margin-bottom: 72.5px;
	}

	.o-spacer-mb-150-sp {
		margin-bottom: 75px;
	}

	.o-spacer-mb-160-sp {
		margin-bottom: 80px;
	}

	.o-spacer-mb-200-sp {
		margin-bottom: 100px;
	}

	.o-spacer-mb-250-sp {
		margin-bottom: 125px;
	}

	.o-spacer-mb-300-sp {
		margin-bottom: 150px;
	}

	.o-spacer-pb-1-sp {
		padding-bottom: 0.5px;
	}

	.o-spacer-pb-2-sp {
		padding-bottom: 1px;
	}

	.o-spacer-pb-3-sp {
		padding-bottom: 1.5px;
	}

	.o-spacer-pb-4-sp {
		padding-bottom: 2px;
	}

	.o-spacer-pb-5-sp {
		padding-bottom: 2.5px;
	}

	.o-spacer-pb-6-sp {
		padding-bottom: 3px;
	}

	.o-spacer-pb-7-sp {
		padding-bottom: 3.5px;
	}

	.o-spacer-pb-8-sp {
		padding-bottom: 4px;
	}

	.o-spacer-pb-9-sp {
		padding-bottom: 4.5px;
	}

	.o-spacer-pb-10-sp {
		padding-bottom: 5px;
	}

	.o-spacer-pb-15-sp {
		padding-bottom: 7.5px;
	}

	.o-spacer-pb-20-sp {
		padding-bottom: 10px;
	}

	.o-spacer-pb-25-sp {
		padding-bottom: 12.5px;
	}

	.o-spacer-pb-30-sp {
		padding-bottom: 15px;
	}

	.o-spacer-pb-35-sp {
		padding-bottom: 17.5px;
	}

	.o-spacer-pb-40-sp {
		padding-bottom: 20px;
	}

	.o-spacer-pb-45-sp {
		padding-bottom: 22.5px;
	}

	.o-spacer-pb-50-sp {
		padding-bottom: 25px;
	}

	.o-spacer-pb-55-sp {
		padding-bottom: 27.5px;
	}

	.o-spacer-pb-60-sp {
		padding-bottom: 30px;
	}

	.o-spacer-pb-65-sp {
		padding-bottom: 32.5px;
	}

	.o-spacer-pb-70-sp {
		padding-bottom: 35px;
	}

	.o-spacer-pb-75-sp {
		padding-bottom: 37.5px;
	}

	.o-spacer-pb-80-sp {
		padding-bottom: 40px;
	}

	.o-spacer-pb-90-sp {
		padding-bottom: 45px;
	}

	.o-spacer-pb-95-sp {
		padding-bottom: 47.5px;
	}

	.o-spacer-pb-100-sp {
		padding-bottom: 50px;
	}

	.o-spacer-pb-105-sp {
		padding-bottom: 52.5px;
	}

	.o-spacer-pb-110-sp {
		padding-bottom: 55px;
	}

	.o-spacer-pb-115-sp {
		padding-bottom: 57.5px;
	}

	.o-spacer-pb-120-sp {
		padding-bottom: 60px;
	}

	.o-spacer-pb-125-sp {
		padding-bottom: 62.5px;
	}

	.o-spacer-pb-130-sp {
		padding-bottom: 65px;
	}

	.o-spacer-pb-135-sp {
		padding-bottom: 65px;
	}

	.o-spacer-pb-140-sp {
		padding-bottom: 70px;
	}

	.o-spacer-pb-145-sp {
		padding-bottom: 72.5px;
	}

	.o-spacer-pb-150-sp {
		padding-bottom: 75px;
	}

	.o-spacer-pb-160-sp {
		padding-bottom: 80px;
	}

	.o-spacer-pb-200-sp {
		padding-bottom: 100px;
	}

	.o-spacer-pb-250-sp {
		padding-bottom: 125px;
	}

	.o-spacer-pb-300-sp {
		padding-bottom: 150px;
	}
}

/*left
---------------------------------------------------------- */
.o-spacers {
	& > [class*="o-spacer-ml-"] {
		&:first-child {
			margin-left: 0;
		}
	}
	& > [class*="o-spacer-pl-"] {
		&:first-child {
			padding-left: 0;
		}
	}
}

.o-spacer-ml-1 {
	margin-left: 1px;
	@include mq("max") {
		margin-left: 0.5px;
	}
}

.o-spacer-ml-2 {
	margin-left: 2px;
	@include mq("max") {
		margin-left: 1px;
	}
}

.o-spacer-ml-3 {
	margin-left: 3px;
	@include mq("max") {
		margin-left: 1.5px;
	}
}

.o-spacer-ml-4 {
	margin-left: 4px;
	@include mq("max") {
		margin-left: 2px;
	}
}

.o-spacer-ml-5 {
	margin-left: 5px;
	@include mq("max") {
		margin-left: 2.5px;
	}
}

.o-spacer-ml-6 {
	margin-left: 6px;
	@include mq("max") {
		margin-left: 3px;
	}
}

.o-spacer-ml-7 {
	margin-left: 7px;
	@include mq("max") {
		margin-left: 3.5px;
	}
}

.o-spacer-ml-8 {
	margin-left: 8px;
	@include mq("max") {
		margin-left: 4px;
	}
}

.o-spacer-ml-9 {
	margin-left: 9px;
	@include mq("max") {
		margin-left: 4.5px;
	}
}

.o-spacer-ml-10 {
	margin-left: 10px;
	@include mq("max") {
		margin-left: 5px;
	}
}

.o-spacer-ml-15 {
	margin-left: 15px;
	@include mq("max") {
		margin-left: 7.5px;
	}
}

.o-spacer-ml-20 {
	margin-left: 20px;
	@include mq("max") {
		margin-left: 10px;
	}
}

.o-spacer-ml-25 {
	margin-left: 25px;
	@include mq("max") {
		margin-left: 12.5px;
	}
}

.o-spacer-ml-30 {
	margin-left: 30px;
	@include mq("max") {
		margin-left: 15px;
	}
}

.o-spacer-ml-35 {
	margin-left: 35px;
	@include mq("max") {
		margin-left: 17.5px;
	}
}

.o-spacer-ml-40 {
	margin-left: 40px;
	@include mq("max") {
		margin-left: 20px;
	}
}

.o-spacer-ml-45 {
	margin-left: 45px;
	@include mq("max") {
		margin-left: 22.5px;
	}
}

.o-spacer-ml-50 {
	margin-left: 50px;
	@include mq("max") {
		margin-left: 25px;
	}
}

.o-spacer-ml-55 {
	margin-left: 55px;
	@include mq("max") {
		margin-left: 27.5px;
	}
}

.o-spacer-ml-60 {
	margin-left: 60px;
	@include mq("max") {
		margin-left: 30px;
	}
}

.o-spacer-ml-65 {
	margin-left: 65px;
	@include mq("max") {
		margin-left: 32.5px;
	}
}

.o-spacer-ml-70 {
	margin-left: 70px;
	@include mq("max") {
		margin-left: 35px;
	}
}

.o-spacer-ml-75 {
	margin-left: 70px;
	@include mq("max") {
		margin-left: 37.5px;
	}
}

.o-spacer-ml-80 {
	margin-left: 80px;
	@include mq("max") {
		margin-left: 40px;
	}
}

.o-spacer-ml-85 {
	margin-left: 85px;
	@include mq("max") {
		margin-left: 42.5px;
	}
}
.o-spacer-ml-90 {
	margin-left: 90px;
	@include mq("max") {
		margin-left: 45px;
	}
}

.o-spacer-ml-95 {
	margin-left: 95px;
	@include mq("max") {
		margin-left: 47.5px;
	}
}

.o-spacer-ml-100 {
	margin-left: 100px;
	@include mq("max") {
		margin-left: 50px;
	}
}

.o-spacer-ml-105 {
	margin-left: 105px;
	@include mq("max") {
		margin-left: 52.5px;
	}
}

.o-spacer-ml-105 {
	margin-left: 105px;
	@include mq("max") {
		margin-left: 52.5px;
	}
}

.o-spacer-ml-110 {
	margin-left: 110px;
	@include mq("max") {
		margin-left: 55px;
	}
}

.o-spacer-ml-115 {
	margin-left: 115px;
	@include mq("max") {
		margin-left: 57.5px;
	}
}

.o-spacer-ml-120 {
	margin-left: 120px;
	@include mq("max") {
		margin-left: 60px;
	}
}

.o-spacer-ml-125 {
	margin-left: 125px;
	@include mq("max") {
		margin-left: 62.5px;
	}
}

.o-spacer-ml-130 {
	margin-left: 130px;
	@include mq("max") {
		margin-left: 65px;
	}
}

.o-spacer-ml-135 {
	margin-left: 135px;
	@include mq("max") {
		margin-left: 67.5px;
	}
}

.o-spacer-ml-140 {
	margin-left: 140px;
	@include mq("max") {
		margin-left: 70px;
	}
}

.o-spacer-ml-145 {
	margin-left: 145px;
	@include mq("max") {
		margin-left: 72.5px;
	}
}

.o-spacer-ml-150 {
	margin-left: 150px;
	@include mq("max") {
		margin-left: 75px;
	}
}

.o-spacer-pl-1 {
	padding-left: 1px;
	@include mq("max") {
		padding-left: 0.5px;
	}
}

.o-spacer-pl-2 {
	padding-left: 2px;
	@include mq("max") {
		padding-left: 1px;
	}
}

.o-spacer-pl-3 {
	padding-left: 3px;
	@include mq("max") {
		padding-left: 1.5px;
	}
}

.o-spacer-pl-4 {
	padding-left: 4px;
	@include mq("max") {
		padding-left: 2px;
	}
}

.o-spacer-pl-5 {
	padding-left: 5px;
	@include mq("max") {
		padding-left: 2.5px;
	}
}

.o-spacer-pl-6 {
	padding-left: 6px;
	@include mq("max") {
		padding-left: 3px;
	}
}

.o-spacer-pl-7 {
	padding-left: 7px;
	@include mq("max") {
		padding-left: 3.5px;
	}
}

.o-spacer-pl-8 {
	padding-left: 8px;
	@include mq("max") {
		padding-left: 4px;
	}
}

.o-spacer-pl-9 {
	padding-left: 9px;
	@include mq("max") {
		padding-left: 4.5px;
	}
}

.o-spacer-pl-10 {
	padding-left: 10px;
	@include mq("max") {
		padding-left: 5px;
	}
}

.o-spacer-pl-15 {
	padding-left: 15px;
	@include mq("max") {
		padding-left: 7.5px;
	}
}

.o-spacer-pl-20 {
	padding-left: 20px;
	@include mq("max") {
		padding-left: 10px;
	}
}

.o-spacer-pl-25 {
	padding-left: 25px;
	@include mq("max") {
		padding-left: 12.5px;
	}
}

.o-spacer-pl-30 {
	padding-left: 30px;
	@include mq("max") {
		padding-left: 15px;
	}
}

.o-spacer-pl-35 {
	padding-left: 35px;
	@include mq("max") {
		padding-left: 17.5px;
	}
}

.o-spacer-pl-40 {
	padding-left: 40px;
	@include mq("max") {
		padding-left: 20px;
	}
}

.o-spacer-pl-45 {
	padding-left: 45px;
	@include mq("max") {
		padding-left: 22.5px;
	}
}

.o-spacer-pl-50 {
	padding-left: 50px;
	@include mq("max") {
		padding-left: 25px;
	}
}

.o-spacer-pl-55 {
	padding-left: 55px;
	@include mq("max") {
		padding-left: 27.5px;
	}
}

.o-spacer-pl-60 {
	padding-left: 60px;
	@include mq("max") {
		padding-left: 30px;
	}
}

.o-spacer-pl-65 {
	padding-left: 65px;
	@include mq("max") {
		padding-left: 32.5px;
	}
}

.o-spacer-pl-70 {
	padding-left: 70px;
	@include mq("max") {
		padding-left: 35px;
	}
}

.o-spacer-pl-75 {
	padding-left: 75px;
	@include mq("max") {
		padding-left: 37.5px;
	}
}

.o-spacer-pl-80 {
	padding-left: 80px;
	@include mq("max") {
		padding-left: 40px;
	}
}

.o-spacer-pl-90 {
	padding-left: 90px;
	@include mq("max") {
		padding-left: 45px;
	}
}

.o-spacer-pl-95 {
	padding-left: 95px;
	@include mq("max") {
		padding-left: 47.5px;
	}
}

.o-spacer-pl-100 {
	padding-left: 100px;
	@include mq("max") {
		padding-left: 50px;
	}
}

.o-spacer-pl-105 {
	padding-left: 105px;
	@include mq("max") {
		padding-left: 52.5px;
	}
}

.o-spacer-pl-110 {
	padding-left: 110px;
	@include mq("max") {
		padding-left: 55px;
	}
}

.o-spacer-pl-115 {
	padding-left: 115px;
	@include mq("max") {
		padding-left: 57.5px;
	}
}

.o-spacer-pl-120 {
	padding-left: 120px;
	@include mq("max") {
		padding-left: 60px;
	}
}

.o-spacer-pl-125 {
	padding-left: 125px;
	@include mq("max") {
		padding-left: 62.5px;
	}
}

.o-spacer-pl-130 {
	padding-left: 130px;
	@include mq("max") {
		padding-left: 65px;
	}
}

.o-spacer-pl-135 {
	padding-left: 135px;
	@include mq("max") {
		padding-left: 65px;
	}
}

.o-spacer-pl-140 {
	padding-left: 140px;
	@include mq("max") {
		padding-left: 70px;
	}
}

.o-spacer-pl-145 {
	padding-left: 145px;
	@include mq("max") {
		padding-left: 72.5px;
	}
}

.o-spacer-pl-150 {
	padding-left: 150px;
	@include mq("max") {
		padding-left: 75px;
	}
}

.o-spacer-pl-200 {
	padding-left: 200px;
	@include mq("max") {
		padding-left: 100px;
	}
}

.o-spacer-pl-250 {
	padding-left: 250px;
	@include mq("max") {
		padding-left: 125px;
	}
}

.o-spacer-pl-300 {
	padding-left: 300px;
	@include mq("max") {
		padding-left: 150px;
	}
}

@include mq("min") {
	.o-spacer-ml-1-pc {
		margin-left: 1px;
	}

	.o-spacer-ml-2-pc {
		margin-left: 2px;
	}

	.o-spacer-ml-3-pc {
		margin-left: 3px;
	}

	.o-spacer-ml-4-pc {
		margin-left: 4px;
	}

	.o-spacer-ml-5-pc {
		margin-left: 5px;
	}

	.o-spacer-ml-6-pc {
		margin-left: 6px;
	}

	.o-spacer-ml-7-pc {
		margin-left: 7px;
	}

	.o-spacer-ml-8-pc {
		margin-left: 8px;
	}

	.o-spacer-ml-9-pc {
		margin-left: 9px;
	}

	.o-spacer-ml-10-pc {
		margin-left: 10px;
	}

	.o-spacer-ml-15-pc {
		margin-left: 15px;
	}

	.o-spacer-ml-20-pc {
		margin-left: 20px;
	}

	.o-spacer-ml-25-pc {
		margin-left: 25px;
	}

	.o-spacer-ml-30-pc {
		margin-left: 30px;
	}

	.o-spacer-ml-35-pc {
		margin-left: 35px;
	}

	.o-spacer-ml-40-pc {
		margin-left: 40px;
	}

	.o-spacer-ml-45-pc {
		margin-left: 45px;
	}

	.o-spacer-ml-50-pc {
		margin-left: 50px;
	}

	.o-spacer-ml-55-pc {
		margin-left: 55px;
	}

	.o-spacer-ml-60-pc {
		margin-left: 60px;
	}

	.o-spacer-ml-65-pc {
		margin-left: 65px;
	}

	.o-spacer-ml-70-pc {
		margin-left: 70px;
	}

	.o-spacer-ml-75-pc {
		margin-left: 70px;
	}

	.o-spacer-ml-80-pc {
		margin-left: 80px;
	}

	.o-spacer-ml-85-pc {
		margin-left: 85px;
	}

	.o-spacer-ml-90-pc {
		margin-left: 90px;
	}

	.o-spacer-ml-95-pc {
		margin-left: 95px;
	}

	.o-spacer-ml-100-pc {
		margin-left: 100px;
	}

	.o-spacer-ml-105-pc {
		margin-left: 105px;
	}

	.o-spacer-ml-105-pc {
		margin-left: 105px;
	}

	.o-spacer-ml-110-pc {
		margin-left: 110px;
	}

	.o-spacer-ml-115-pc {
		margin-left: 115px;
	}

	.o-spacer-ml-120-pc {
		margin-left: 120px;
	}

	.o-spacer-ml-125-pc {
		margin-left: 125px;
	}

	.o-spacer-ml-130-pc {
		margin-left: 130px;
	}

	.o-spacer-ml-135-pc {
		margin-left: 135px;
	}

	.o-spacer-ml-140-pc {
		margin-left: 140px;
	}

	.o-spacer-ml-145-pc {
		margin-left: 145px;
	}

	.o-spacer-ml-150-pc {
		margin-left: 150px;
	}

	.o-spacer-ml-200-sp {
		margin-left: 100px;
	}

	.o-spacer-ml-250-sp {
		margin-left: 125px;
	}

	.o-spacer-ml-300-sp {
		margin-left: 150px;
	}

	.o-spacer-pl-1-pc {
		padding-left: 1px;
	}

	.o-spacer-pl-2-pc {
		padding-left: 2px;
	}

	.o-spacer-pl-3-pc {
		padding-left: 3px;
	}

	.o-spacer-pl-4-pc {
		padding-left: 4px;
	}

	.o-spacer-pl-5-pc {
		padding-left: 5px;
	}

	.o-spacer-pl-6-pc {
		padding-left: 6px;
	}

	.o-spacer-pl-7-pc {
		padding-left: 7px;
	}

	.o-spacer-pl-8-pc {
		padding-left: 8px;
	}

	.o-spacer-pl-9-pc {
		padding-left: 9px;
	}

	.o-spacer-pl-10-pc {
		padding-left: 10px;
	}

	.o-spacer-pl-15-pc {
		padding-left: 15px;
	}

	.o-spacer-pl-20-pc {
		padding-left: 20px;
	}

	.o-spacer-pl-25-pc {
		padding-left: 25px;
	}

	.o-spacer-pl-30-pc {
		padding-left: 30px;
	}

	.o-spacer-pl-35-pc {
		padding-left: 35px;
	}

	.o-spacer-pl-40-pc {
		padding-left: 40px;
	}

	.o-spacer-pl-45-pc {
		padding-left: 45px;
	}

	.o-spacer-pl-50-pc {
		padding-left: 50px;
	}

	.o-spacer-pl-55-pc {
		padding-left: 55px;
	}

	.o-spacer-pl-60-pc {
		padding-left: 60px;
	}

	.o-spacer-pl-65-pc {
		padding-left: 65px;
	}

	.o-spacer-pl-70-pc {
		padding-left: 70px;
	}

	.o-spacer-pl-75-pc {
		padding-left: 75px;
	}

	.o-spacer-pl-80-pc {
		padding-left: 80px;
	}

	.o-spacer-pl-90-pc {
		padding-left: 90px;
	}

	.o-spacer-pl-95-pc {
		padding-left: 95px;
	}

	.o-spacer-pl-100-pc {
		padding-left: 100px;
	}

	.o-spacer-pl-105-pc {
		padding-left: 105px;
	}

	.o-spacer-pl-110-pc {
		padding-left: 110px;
	}

	.o-spacer-pl-115-pc {
		padding-left: 115px;
	}

	.o-spacer-pl-120-pc {
		padding-left: 120px;
	}

	.o-spacer-pl-125-pc {
		padding-left: 125px;
	}

	.o-spacer-pl-130-pc {
		padding-left: 130px;
	}

	.o-spacer-pl-135-pc {
		padding-left: 135px;
	}

	.o-spacer-pl-140-pc {
		padding-left: 140px;
	}

	.o-spacer-pl-145-pc {
		padding-left: 145px;
	}

	.o-spacer-pl-150-pc {
		padding-left: 150px;
	}
}

@include mq("max") {
	.o-spacer-ml-1-sp {
		margin-left: 0.5px;
	}

	.o-spacer-ml-2-sp {
		margin-left: 1px;
	}

	.o-spacer-ml-3-sp {
		margin-left: 1.5px;
	}

	.o-spacer-ml-4-sp {
		margin-left: 2px;
	}

	.o-spacer-ml-5-sp {
		margin-left: 2.5px;
	}

	.o-spacer-ml-6-sp {
		margin-left: 3px;
	}

	.o-spacer-ml-7-sp {
		margin-left: 3.5px;
	}

	.o-spacer-ml-8-sp {
		margin-left: 4px;
	}

	.o-spacer-ml-9-sp {
		margin-left: 4.5px;
	}

	.o-spacer-ml-10-sp {
		margin-left: 5px;
	}

	.o-spacer-ml-15-sp {
		margin-left: 7.5px;
	}

	.o-spacer-ml-20-sp {
		margin-left: 10px;
	}

	.o-spacer-ml-25-sp {
		margin-left: 12.5px;
	}

	.o-spacer-ml-30-sp {
		margin-left: 15px;
	}

	.o-spacer-ml-35-sp {
		margin-left: 17.5px;
	}

	.o-spacer-ml-40-sp {
		margin-left: 20px;
	}

	.o-spacer-ml-45-sp {
		margin-left: 22.5px;
	}

	.o-spacer-ml-50-sp {
		margin-left: 25px;
	}

	.o-spacer-ml-55-sp {
		margin-left: 27.5px;
	}

	.o-spacer-ml-60-sp {
		margin-left: 30px;
	}

	.o-spacer-ml-65-sp {
		margin-left: 32.5px;
	}

	.o-spacer-ml-70-sp {
		margin-left: 35px;
	}

	.o-spacer-ml-75-sp {
		margin-left: 37.5px;
	}

	.o-spacer-ml-80-sp {
		margin-left: 40px;
	}

	.o-spacer-ml-85-sp {
		margin-left: 42.5px;
	}

	.o-spacer-ml-90-sp {
		margin-left: 45px;
	}

	.o-spacer-ml-95-sp {
		margin-left: 47.5px;
	}

	.o-spacer-ml-100-sp {
		margin-left: 50px;
	}

	.o-spacer-ml-105-sp {
		margin-left: 52.5px;
	}

	.o-spacer-ml-105-sp {
		margin-left: 52.5px;
	}

	.o-spacer-ml-110-sp {
		margin-left: 55px;
	}

	.o-spacer-ml-115-sp {
		margin-left: 57.5px;
	}

	.o-spacer-ml-120-sp {
		margin-left: 60px;
	}

	.o-spacer-ml-125-sp {
		margin-left: 62.5px;
	}

	.o-spacer-ml-130-sp {
		margin-left: 65px;
	}

	.o-spacer-ml-135-sp {
		margin-left: 67.5px;
	}

	.o-spacer-ml-140-sp {
		margin-left: 70px;
	}

	.o-spacer-ml-145-sp {
		margin-left: 72.5px;
	}

	.o-spacer-ml-150-sp {
		margin-left: 75px;
	}

	.o-spacer-ml-200-sp {
		margin-left: 100px;
	}

	.o-spacer-ml-250-sp {
		margin-left: 125px;
	}

	.o-spacer-ml-300-sp {
		margin-left: 150px;
	}

	.o-spacer-pl-1-sp {
		padding-left: 0.5px;
	}

	.o-spacer-pl-2-sp {
		padding-left: 1px;
	}

	.o-spacer-pl-3-sp {
		padding-left: 1.5px;
	}

	.o-spacer-pl-4-sp {
		padding-left: 2px;
	}

	.o-spacer-pl-5-sp {
		padding-left: 2.5px;
	}

	.o-spacer-pl-6-sp {
		padding-left: 3px;
	}

	.o-spacer-pl-7-sp {
		padding-left: 3.5px;
	}

	.o-spacer-pl-8-sp {
		padding-left: 4px;
	}

	.o-spacer-pl-9-sp {
		padding-left: 4.5px;
	}

	.o-spacer-pl-10-sp {
		padding-left: 5px;
	}

	.o-spacer-pl-15-sp {
		padding-left: 7.5px;
	}

	.o-spacer-pl-20-sp {
		padding-left: 10px;
	}

	.o-spacer-pl-25-sp {
		padding-left: 12.5px;
	}

	.o-spacer-pl-30-sp {
		padding-left: 15px;
	}

	.o-spacer-pl-35-sp {
		padding-left: 17.5px;
	}

	.o-spacer-pl-40-sp {
		padding-left: 20px;
	}

	.o-spacer-pl-45-sp {
		padding-left: 22.5px;
	}

	.o-spacer-pl-50-sp {
		padding-left: 25px;
	}

	.o-spacer-pl-55-sp {
		padding-left: 27.5px;
	}

	.o-spacer-pl-60-sp {
		padding-left: 30px;
	}

	.o-spacer-pl-65-sp {
		padding-left: 32.5px;
	}

	.o-spacer-pl-70-sp {
		padding-left: 35px;
	}

	.o-spacer-pl-75-sp {
		padding-left: 37.5px;
	}

	.o-spacer-pl-80-sp {
		padding-left: 40px;
	}

	.o-spacer-pl-90-sp {
		padding-left: 45px;
	}

	.o-spacer-pl-95-sp {
		padding-left: 47.5px;
	}

	.o-spacer-pl-100-sp {
		padding-left: 50px;
	}

	.o-spacer-pl-105-sp {
		padding-left: 52.5px;
	}

	.o-spacer-pl-110-sp {
		padding-left: 55px;
	}

	.o-spacer-pl-115-sp {
		padding-left: 57.5px;
	}

	.o-spacer-pl-120-sp {
		padding-left: 60px;
	}

	.o-spacer-pl-125-sp {
		padding-left: 62.5px;
	}

	.o-spacer-pl-130-sp {
		padding-left: 65px;
	}

	.o-spacer-pl-135-sp {
		padding-left: 65px;
	}

	.o-spacer-pl-140-sp {
		padding-left: 70px;
	}

	.o-spacer-pl-145-sp {
		padding-left: 72.5px;
	}

	.o-spacer-pl-150-sp {
		padding-left: 75px;
	}

	.o-spacer-pl-200-sp {
		padding-left: 100px;
	}

	.o-spacer-pl-250-sp {
		padding-left: 125px;
	}

	.o-spacer-pl-300-sp {
		padding-left: 150px;
	}
}

/*right
---------------------------------------------------------- */
.o-spacers {
	& > [class*="o-spacer-mr-"] {
		&:last-child {
			margin-right: 0;
		}
	}
	& > [class*="o-spacer-pr-"] {
		&:last-child {
			padding-right: 0;
		}
	}
}

.o-spacer-mr-1 {
	margin-right: 1px;
	@include mq("max") {
		margin-right: 0.5px;
	}
}

.o-spacer-mr-2 {
	margin-right: 2px;
	@include mq("max") {
		margin-right: 1px;
	}
}

.o-spacer-mr-3 {
	margin-right: 3px;
	@include mq("max") {
		margin-right: 1.5px;
	}
}

.o-spacer-mr-4 {
	margin-right: 4px;
	@include mq("max") {
		margin-right: 2px;
	}
}

.o-spacer-mr-5 {
	margin-right: 5px;
	@include mq("max") {
		margin-right: 2.5px;
	}
}

.o-spacer-mr-6 {
	margin-right: 6px;
	@include mq("max") {
		margin-right: 3px;
	}
}

.o-spacer-mr-7 {
	margin-right: 7px;
	@include mq("max") {
		margin-right: 3.5px;
	}
}

.o-spacer-mr-8 {
	margin-right: 8px;
	@include mq("max") {
		margin-right: 4px;
	}
}

.o-spacer-mr-9 {
	margin-right: 9px;
	@include mq("max") {
		margin-right: 4.5px;
	}
}

.o-spacer-mr-10 {
	margin-right: 10px;
	@include mq("max") {
		margin-right: 5px;
	}
}

.o-spacer-mr-15 {
	margin-right: 15px;
	@include mq("max") {
		margin-right: 7.5px;
	}
}

.o-spacer-mr-20 {
	margin-right: 20px;
	@include mq("max") {
		margin-right: 10px;
	}
}

.o-spacer-mr-25 {
	margin-right: 25px;
	@include mq("max") {
		margin-right: 12.5px;
	}
}

.o-spacer-mr-30 {
	margin-right: 30px;
	@include mq("max") {
		margin-right: 15px;
	}
}

.o-spacer-mr-35 {
	margin-right: 35px;
	@include mq("max") {
		margin-right: 17.5px;
	}
}

.o-spacer-mr-40 {
	margin-right: 40px;
	@include mq("max") {
		margin-right: 20px;
	}
}

.o-spacer-mr-45 {
	margin-right: 45px;
	@include mq("max") {
		margin-right: 22.5px;
	}
}

.o-spacer-mr-50 {
	margin-right: 50px;
	@include mq("max") {
		margin-right: 25px;
	}
}

.o-spacer-mr-55 {
	margin-right: 55px;
	@include mq("max") {
		margin-right: 27.5px;
	}
}

.o-spacer-mr-60 {
	margin-right: 60px;
	@include mq("max") {
		margin-right: 30px;
	}
}

.o-spacer-mr-65 {
	margin-right: 65px;
	@include mq("max") {
		margin-right: 32.5px;
	}
}

.o-spacer-mr-70 {
	margin-right: 70px;
	@include mq("max") {
		margin-right: 35px;
	}
}

.o-spacer-mr-75 {
	margin-right: 70px;
	@include mq("max") {
		margin-right: 37.5px;
	}
}

.o-spacer-mr-80 {
	margin-right: 80px;
	@include mq("max") {
		margin-right: 40px;
	}
}

.o-spacer-mr-85 {
	margin-right: 85px;
	@include mq("max") {
		margin-right: 42.5px;
	}
}

.o-spacer-mr-90 {
	margin-right: 90px;
	@include mq("max") {
		margin-right: 45px;
	}
}

.o-spacer-mr-95 {
	margin-right: 95px;
	@include mq("max") {
		margin-right: 47.5px;
	}
}

.o-spacer-mr-100 {
	margin-right: 100px;
	@include mq("max") {
		margin-right: 50px;
	}
}

.o-spacer-mr-105 {
	margin-right: 105px;
	@include mq("max") {
		margin-right: 52.5px;
	}
}

.o-spacer-mr-105 {
	margin-right: 105px;
	@include mq("max") {
		margin-right: 52.5px;
	}
}

.o-spacer-mr-110 {
	margin-right: 110px;
	@include mq("max") {
		margin-right: 55px;
	}
}

.o-spacer-mr-115 {
	margin-right: 115px;
	@include mq("max") {
		margin-right: 57.5px;
	}
}

.o-spacer-mr-120 {
	margin-right: 120px;
	@include mq("max") {
		margin-right: 60px;
	}
}

.o-spacer-mr-125 {
	margin-right: 125px;
	@include mq("max") {
		margin-right: 62.5px;
	}
}

.o-spacer-mr-130 {
	margin-right: 130px;
	@include mq("max") {
		margin-right: 65px;
	}
}

.o-spacer-mr-135 {
	margin-right: 135px;
	@include mq("max") {
		margin-right: 67.5px;
	}
}

.o-spacer-mr-140 {
	margin-right: 140px;
	@include mq("max") {
		margin-right: 70px;
	}
}

.o-spacer-mr-145 {
	margin-right: 145px;
	@include mq("max") {
		margin-right: 72.5px;
	}
}

.o-spacer-mr-150 {
	margin-right: 150px;
	@include mq("max") {
		margin-right: 75px;
	}
}

.o-spacer-pr-1 {
	padding-right: 1px;
	@include mq("max") {
		padding-right: 0.5px;
	}
}

.o-spacer-pr-2 {
	padding-right: 2px;
	@include mq("max") {
		padding-right: 1px;
	}
}

.o-spacer-pr-3 {
	padding-right: 3px;
	@include mq("max") {
		padding-right: 1.5px;
	}
}

.o-spacer-pr-4 {
	padding-right: 4px;
	@include mq("max") {
		padding-right: 2px;
	}
}

.o-spacer-pr-5 {
	padding-right: 5px;
	@include mq("max") {
		padding-right: 2.5px;
	}
}

.o-spacer-pr-6 {
	padding-right: 6px;
	@include mq("max") {
		padding-right: 3px;
	}
}

.o-spacer-pr-7 {
	padding-right: 7px;
	@include mq("max") {
		padding-right: 3.5px;
	}
}

.o-spacer-pr-8 {
	padding-right: 8px;
	@include mq("max") {
		padding-right: 4px;
	}
}

.o-spacer-pr-9 {
	padding-right: 9px;
	@include mq("max") {
		padding-right: 4.5px;
	}
}

.o-spacer-pr-10 {
	padding-right: 10px;
	@include mq("max") {
		padding-right: 5px;
	}
}

.o-spacer-pr-15 {
	padding-right: 15px;
	@include mq("max") {
		padding-right: 7.5px;
	}
}

.o-spacer-pr-20 {
	padding-right: 20px;
	@include mq("max") {
		padding-right: 10px;
	}
}

.o-spacer-pr-25 {
	padding-right: 25px;
	@include mq("max") {
		padding-right: 12.5px;
	}
}

.o-spacer-pr-30 {
	padding-right: 30px;
	@include mq("max") {
		padding-right: 15px;
	}
}

.o-spacer-pr-35 {
	padding-right: 35px;
	@include mq("max") {
		padding-right: 17.5px;
	}
}

.o-spacer-pr-40 {
	padding-right: 40px;
	@include mq("max") {
		padding-right: 20px;
	}
}

.o-spacer-pr-45 {
	padding-right: 45px;
	@include mq("max") {
		padding-right: 22.5px;
	}
}

.o-spacer-pr-50 {
	padding-right: 50px;
	@include mq("max") {
		padding-right: 25px;
	}
}

.o-spacer-pr-55 {
	padding-right: 55px;
	@include mq("max") {
		padding-right: 27.5px;
	}
}

.o-spacer-pr-60 {
	padding-right: 60px;
	@include mq("max") {
		padding-right: 30px;
	}
}

.o-spacer-pr-65 {
	padding-right: 65px;
	@include mq("max") {
		padding-right: 32.5px;
	}
}

.o-spacer-pr-70 {
	padding-right: 70px;
	@include mq("max") {
		padding-right: 35px;
	}
}

.o-spacer-pr-75 {
	padding-right: 75px;
	@include mq("max") {
		padding-right: 37.5px;
	}
}

.o-spacer-pr-80 {
	padding-right: 80px;
	@include mq("max") {
		padding-right: 40px;
	}
}

.o-spacer-pr-90 {
	padding-right: 90px;
	@include mq("max") {
		padding-right: 45px;
	}
}

.o-spacer-pr-95 {
	padding-right: 95px;
	@include mq("max") {
		padding-right: 47.5px;
	}
}

.o-spacer-pr-100 {
	padding-right: 100px;
	@include mq("max") {
		padding-right: 50px;
	}
}

.o-spacer-pr-105 {
	padding-right: 105px;
	@include mq("max") {
		padding-right: 52.5px;
	}
}

.o-spacer-pr-110 {
	padding-right: 110px;
	@include mq("max") {
		padding-right: 55px;
	}
}

.o-spacer-pr-115 {
	padding-right: 115px;
	@include mq("max") {
		padding-right: 57.5px;
	}
}

.o-spacer-pr-120 {
	padding-right: 120px;
	@include mq("max") {
		padding-right: 60px;
	}
}

.o-spacer-pr-125 {
	padding-right: 125px;
	@include mq("max") {
		padding-right: 62.5px;
	}
}

.o-spacer-pr-130 {
	padding-right: 130px;
	@include mq("max") {
		padding-right: 65px;
	}
}

.o-spacer-pr-135 {
	padding-right: 135px;
	@include mq("max") {
		padding-right: 65px;
	}
}

.o-spacer-pr-140 {
	padding-right: 140px;
	@include mq("max") {
		padding-right: 70px;
	}
}

.o-spacer-pr-145 {
	padding-right: 145px;
	@include mq("max") {
		padding-right: 72.5px;
	}
}

.o-spacer-pr-150 {
	padding-right: 150px;
	@include mq("max") {
		padding-right: 75px;
	}
}

@include mq("min") {
	.o-spacer-mr-1-pc {
		margin-right: 1px;
	}

	.o-spacer-mr-2-pc {
		margin-right: 2px;
	}

	.o-spacer-mr-3-pc {
		margin-right: 3px;
	}

	.o-spacer-mr-4-pc {
		margin-right: 4px;
	}

	.o-spacer-mr-5-pc {
		margin-right: 5px;
	}

	.o-spacer-mr-6-pc {
		margin-right: 6px;
	}

	.o-spacer-mr-7-pc {
		margin-right: 7px;
	}

	.o-spacer-mr-8-pc {
		margin-right: 8px;
	}

	.o-spacer-mr-9-pc {
		margin-right: 9px;
	}

	.o-spacer-mr-10-pc {
		margin-right: 10px;
	}

	.o-spacer-mr-15-pc {
		margin-right: 15px;
	}

	.o-spacer-mr-20-pc {
		margin-right: 20px;
	}

	.o-spacer-mr-25-pc {
		margin-right: 25px;
	}

	.o-spacer-mr-30-pc {
		margin-right: 30px;
	}

	.o-spacer-mr-35-pc {
		margin-right: 35px;
	}

	.o-spacer-mr-40-pc {
		margin-right: 40px;
	}

	.o-spacer-mr-45-pc {
		margin-right: 45px;
	}

	.o-spacer-mr-50-pc {
		margin-right: 50px;
	}

	.o-spacer-mr-55-pc {
		margin-right: 55px;
	}

	.o-spacer-mr-60-pc {
		margin-right: 60px;
	}

	.o-spacer-mr-65-pc {
		margin-right: 65px;
	}

	.o-spacer-mr-70-pc {
		margin-right: 70px;
	}

	.o-spacer-mr-75-pc {
		margin-right: 70px;
	}

	.o-spacer-mr-80-pc {
		margin-right: 80px;
	}

	.o-spacer-mr-85-pc {
		margin-right: 85px;
	}

	.o-spacer-mr-90-pc {
		margin-right: 90px;
	}

	.o-spacer-mr-95-pc {
		margin-right: 95px;
	}

	.o-spacer-mr-100-pc {
		margin-right: 100px;
	}

	.o-spacer-mr-105-pc {
		margin-right: 105px;
	}

	.o-spacer-mr-105-pc {
		margin-right: 105px;
	}

	.o-spacer-mr-110-pc {
		margin-right: 110px;
	}

	.o-spacer-mr-115-pc {
		margin-right: 115px;
	}

	.o-spacer-mr-120-pc {
		margin-right: 120px;
	}

	.o-spacer-mr-125-pc {
		margin-right: 125px;
	}

	.o-spacer-mr-130-pc {
		margin-right: 130px;
	}

	.o-spacer-mr-135-pc {
		margin-right: 135px;
	}

	.o-spacer-mr-140-pc {
		margin-right: 140px;
	}

	.o-spacer-mr-145-pc {
		margin-right: 145px;
	}

	.o-spacer-mr-150-pc {
		margin-right: 150px;
	}

	.o-spacer-mr-200-pc {
		margin-right: 200px;
	}

	.o-spacer-mr-250-pc {
		margin-right: 250px;
	}

	.o-spacer-mr-300-pc {
		margin-right: 300px;
	}

	.o-spacer-pr-1-pc {
		padding-right: 1px;
	}

	.o-spacer-pr-2-pc {
		padding-right: 2px;
	}

	.o-spacer-pr-3-pc {
		padding-right: 3px;
	}

	.o-spacer-pr-4-pc {
		padding-right: 4px;
	}

	.o-spacer-pr-5-pc {
		padding-right: 5px;
	}

	.o-spacer-pr-6-pc {
		padding-right: 6px;
	}

	.o-spacer-pr-7-pc {
		padding-right: 7px;
	}

	.o-spacer-pr-8-pc {
		padding-right: 8px;
	}

	.o-spacer-pr-9-pc {
		padding-right: 9px;
	}

	.o-spacer-pr-10-pc {
		padding-right: 10px;
	}

	.o-spacer-pr-15-pc {
		padding-right: 15px;
	}

	.o-spacer-pr-20-pc {
		padding-right: 20px;
	}

	.o-spacer-pr-25-pc {
		padding-right: 25px;
	}

	.o-spacer-pr-30-pc {
		padding-right: 30px;
	}

	.o-spacer-pr-35-pc {
		padding-right: 35px;
	}

	.o-spacer-pr-40-pc {
		padding-right: 40px;
	}

	.o-spacer-pr-45-pc {
		padding-right: 45px;
	}

	.o-spacer-pr-50-pc {
		padding-right: 50px;
	}

	.o-spacer-pr-55-pc {
		padding-right: 55px;
	}

	.o-spacer-pr-60-pc {
		padding-right: 60px;
	}

	.o-spacer-pr-65-pc {
		padding-right: 65px;
	}

	.o-spacer-pr-70-pc {
		padding-right: 70px;
	}

	.o-spacer-pr-75-pc {
		padding-right: 75px;
	}

	.o-spacer-pr-80-pc {
		padding-right: 80px;
	}

	.o-spacer-pr-90-pc {
		padding-right: 90px;
	}

	.o-spacer-pr-95-pc {
		padding-right: 95px;
	}

	.o-spacer-pr-100-pc {
		padding-right: 100px;
	}

	.o-spacer-pr-105-pc {
		padding-right: 105px;
	}

	.o-spacer-pr-110-pc {
		padding-right: 110px;
	}

	.o-spacer-pr-115-pc {
		padding-right: 115px;
	}

	.o-spacer-pr-120-pc {
		padding-right: 120px;
	}

	.o-spacer-pr-125-pc {
		padding-right: 125px;
	}

	.o-spacer-pr-130-pc {
		padding-right: 130px;
	}

	.o-spacer-pr-135-pc {
		padding-right: 135px;
	}

	.o-spacer-pr-140-pc {
		padding-right: 140px;
	}

	.o-spacer-pr-145-pc {
		padding-right: 145px;
	}

	.o-spacer-pr-150-pc {
		padding-right: 150px;
	}

	.o-spacer-pr-200-pc {
		padding-right: 200px;
	}

	.o-spacer-pr-250-pc {
		padding-right: 250px;
	}

	.o-spacer-pr-300-pc {
		padding-right: 300px;
	}
}

@include mq("max") {
	.o-spacer-mr-1-sp {
		margin-right: 0.5px;
	}

	.o-spacer-mr-2-sp {
		margin-right: 1px;
	}

	.o-spacer-mr-3-sp {
		margin-right: 1.5px;
	}

	.o-spacer-mr-4-sp {
		margin-right: 2px;
	}

	.o-spacer-mr-5-sp {
		margin-right: 2.5px;
	}

	.o-spacer-mr-6-sp {
		margin-right: 3px;
	}

	.o-spacer-mr-7-sp {
		margin-right: 3.5px;
	}

	.o-spacer-mr-8-sp {
		margin-right: 4px;
	}

	.o-spacer-mr-9-sp {
		margin-right: 4.5px;
	}

	.o-spacer-mr-10-sp {
		margin-right: 5px;
	}

	.o-spacer-mr-15-sp {
		margin-right: 7.5px;
	}

	.o-spacer-mr-20-sp {
		margin-right: 10px;
	}

	.o-spacer-mr-25-sp {
		margin-right: 12.5px;
	}

	.o-spacer-mr-30-sp {
		margin-right: 15px;
	}

	.o-spacer-mr-35-sp {
		margin-right: 17.5px;
	}

	.o-spacer-mr-40-sp {
		margin-right: 20px;
	}

	.o-spacer-mr-45-sp {
		margin-right: 22.5px;
	}

	.o-spacer-mr-50-sp {
		margin-right: 25px;
	}

	.o-spacer-mr-55-sp {
		margin-right: 27.5px;
	}

	.o-spacer-mr-60-sp {
		margin-right: 30px;
	}

	.o-spacer-mr-65-sp {
		margin-right: 32.5px;
	}

	.o-spacer-mr-70-sp {
		margin-right: 35px;
	}

	.o-spacer-mr-75-sp {
		margin-right: 37.5px;
	}

	.o-spacer-mr-80-sp {
		margin-right: 40px;
	}

	.o-spacer-mr-85-sp {
		margin-right: 42.5px;
	}

	.o-spacer-mr-90-sp {
		margin-right: 45px;
	}

	.o-spacer-mr-95-sp {
		margin-right: 47.5px;
	}

	.o-spacer-mr-100-sp {
		margin-right: 50px;
	}

	.o-spacer-mr-105-sp {
		margin-right: 52.5px;
	}

	.o-spacer-mr-105-sp {
		margin-right: 52.5px;
	}

	.o-spacer-mr-110-sp {
		margin-right: 55px;
	}

	.o-spacer-mr-115-sp {
		margin-right: 57.5px;
	}

	.o-spacer-mr-120-sp {
		margin-right: 60px;
	}

	.o-spacer-mr-125-sp {
		margin-right: 62.5px;
	}

	.o-spacer-mr-130-sp {
		margin-right: 65px;
	}

	.o-spacer-mr-135-sp {
		margin-right: 67.5px;
	}

	.o-spacer-mr-140-sp {
		margin-right: 70px;
	}

	.o-spacer-mr-145-sp {
		margin-right: 72.5px;
	}

	.o-spacer-mr-150-sp {
		margin-right: 75px;
	}

	.o-spacer-mr-200-sp {
		margin-right: 100px;
	}

	.o-spacer-mr-250-sp {
		margin-right: 125px;
	}

	.o-spacer-mr-300-sp {
		margin-right: 150px;
	}

	.o-spacer-pr-1-sp {
		padding-right: 0.5px;
	}

	.o-spacer-pr-2-sp {
		padding-right: 1px;
	}

	.o-spacer-pr-3-sp {
		padding-right: 1.5px;
	}

	.o-spacer-pr-4-sp {
		padding-right: 2px;
	}

	.o-spacer-pr-5-sp {
		padding-right: 2.5px;
	}

	.o-spacer-pr-6-sp {
		padding-right: 3px;
	}

	.o-spacer-pr-7-sp {
		padding-right: 3.5px;
	}

	.o-spacer-pr-8-sp {
		padding-right: 4px;
	}

	.o-spacer-pr-9-sp {
		padding-right: 4.5px;
	}

	.o-spacer-pr-10-sp {
		padding-right: 5px;
	}

	.o-spacer-pr-15-sp {
		padding-right: 7.5px;
	}

	.o-spacer-pr-20-sp {
		padding-right: 10px;
	}

	.o-spacer-pr-25-sp {
		padding-right: 12.5px;
	}

	.o-spacer-pr-30-sp {
		padding-right: 15px;
	}

	.o-spacer-pr-35-sp {
		padding-right: 17.5px;
	}

	.o-spacer-pr-40-sp {
		padding-right: 20px;
	}

	.o-spacer-pr-45-sp {
		padding-right: 22.5px;
	}

	.o-spacer-pr-50-sp {
		padding-right: 25px;
	}

	.o-spacer-pr-55-sp {
		padding-right: 27.5px;
	}

	.o-spacer-pr-60-sp {
		padding-right: 30px;
	}

	.o-spacer-pr-65-sp {
		padding-right: 32.5px;
	}

	.o-spacer-pr-70-sp {
		padding-right: 35px;
	}

	.o-spacer-pr-75-sp {
		padding-right: 37.5px;
	}

	.o-spacer-pr-80-sp {
		padding-right: 40px;
	}

	.o-spacer-pr-90-sp {
		padding-right: 45px;
	}

	.o-spacer-pr-95-sp {
		padding-right: 47.5px;
	}

	.o-spacer-pr-100-sp {
		padding-right: 50px;
	}

	.o-spacer-pr-105-sp {
		padding-right: 52.5px;
	}

	.o-spacer-pr-110-sp {
		padding-right: 55px;
	}

	.o-spacer-pr-115-sp {
		padding-right: 57.5px;
	}

	.o-spacer-pr-120-sp {
		padding-right: 60px;
	}

	.o-spacer-pr-125-sp {
		padding-right: 62.5px;
	}

	.o-spacer-pr-130-sp {
		padding-right: 65px;
	}

	.o-spacer-pr-135-sp {
		padding-right: 65px;
	}

	.o-spacer-pr-140-sp {
		padding-right: 70px;
	}

	.o-spacer-pr-145-sp {
		padding-right: 72.5px;
	}

	.o-spacer-pr-150-sp {
		padding-right: 75px;
	}

	.o-spacer-pr-200-sp {
		padding-right: 100px;
	}

	.o-spacer-pr-250-sp {
		padding-right: 125px;
	}

	.o-spacer-pr-300-sp {
		padding-right: 150px;
	}
}
