@import "../foundation/setting";

.c-list {
	&.\-horizontal {
		display: flex;
		flex-wrap: wrap;
		margin: -10px 0 0 -30px;
		.c-list__item {
			display: inline-block;
			margin: 10px 0 0 30px;
			vertical-align: top;
		}
	}
	&:not(.-horizontal,.-normal) {
		& > .c-list__item {
			&:not(:first-child) {
				margin-top: 8px;
				@include mq("max") {
					margin-top: 5px;
				}
			}
		}
	}
	@include mq("min") {
		&.-horizontal-pc {
			display: flex;
			flex-wrap: wrap;
			margin: -10px 0 0 -30px;
			.c-list__item {
				margin: 10px 0 0 30px;
			}
		}
	}
	@include mq("max") {
		&.-horizontal-sp {
			display: flex;
			flex-wrap: wrap;
			margin: -10px 0 0 -30px;
			& > .c-list__item {
				margin: 10px 0 0 30px;
			}
		}
	}
	&[data-gap] {
		& > .c-list__item {
			&:not(:first-child) {
				margin-top: var(--gap-top);
			}
		}
	}
	&[data-gap~="40"] {
		--gap-top: 40px;
	}
	&[data-gap~="30"] {
		--gap-top: 30px;
	}
	&[data-gap~="20"] {
		--gap-top: 20px;
	}
	&[data-gap~="10"] {
		--gap-top: 10px;
	}
	@include mq("min") {
		&[data-gap~="pc:40"] {
			--gap-top: 40px;
		}
		&[data-gap~="pc:30"] {
			--gap-top: 30px;
		}
		&[data-gap~="pc:20"] {
			--gap-top: 20px;
		}
		&[data-gap~="pc:10"] {
			--gap-top: 10px;
		}
		&[data-gap~="pc:5"] {
			--gap-top: 5px;
		}
	}
	@include mq("max") {
		&[data-gap~="sp:40"] {
			--gap-top: 40px;
		}
		&[data-gap~="sp:30"] {
			--gap-top: 30px;
		}
		&[data-gap~="sp:20"] {
			--gap-top: 20px;
		}
		&[data-gap~="sp:15"] {
			--gap-top: 15px;
		}
		&[data-gap~="sp:10"] {
			--gap-top: 10px;
		}
		&[data-gap~="sp:5"] {
			--gap-top: 5px;
		}
	}
}

/*ul
----------------------------------------*/
ul.c-list:not(.-normal) {
	.c-list__item {
		position: relative;
	}
    & > .c-list__item {
			padding-left: 18px;
			line-height: 1.7;
			&::before {
                position: absolute;
                top: 0.6em;
                left: 0;
                display: block;
                width: 8px;
                height: 8px;
                border-color: transparent;
                border-style: solid;
                border-width: 1px;
				border-radius: 50%;
                content: "";
                background-color: $color-maintone01;
		}
	}
}

ul.c-list {
	&.\-normal {
		padding-left: 2rem;
		& > .c-list__item {
			list-style-type: disc;
			margin-top: 2px;
		}
	}
}

/*ol
----------------------------------------*/
ol.c-list {
	&[data-gap] {
		& > .c-list__item {
			&:not(:last-child) {
				&:after {
					margin-top: var(--gap-top);
				}
			}
		}
	}
	.c-list__item {
		display: table-row;
		list-style-type: decimal;
		counter-increment: table-ol;
		&:not(:last-child) {
			&:after {
				margin-top: 10px;
				@include mq("max") {
					margin-top: 5px;
				}
			}
		}
	}
	.c-list__item:before {
		display: table-cell;
        padding-right: 1rem;
		color: $color-maintone01;
		font-weight: bold;
		white-space: nowrap;
		text-align: right;
		content: counter(table-ol) ".";
	}
	&.\-parentheses  {
		.c-list__item:before {
			display: table-cell;
			padding-right: 1rem;
			color: $color-maintone01;
			font-weight: bold;
			white-space: nowrap;
			text-align: right;
			content: "(" counter(table-ol) ") ";
		}
	}
	.c-list__item:after {
		display: block;
		content: "";
	}
	.c-list__item-inner {
		display: block;
	}
	&[data-gap~="40"] {
		--gap-top: 40px;
	}
	&[data-gap~="30"] {
		--gap-top: 30px;
	}
	&[data-gap~="20"] {
		--gap-top: 20px;
	}
	&[data-gap~="10"] {
		--gap-top: 10px;
	}
	@include mq("min") {
		&[data-gap~="pc:40"] {
			--gap-top: 40px;
		}
		&[data-gap~="pc:30"] {
			--gap-top: 30px;
		}
		&[data-gap~="pc:20"] {
			--gap-top: 20px;
		}
		&[data-gap~="pc:10"] {
			--gap-top: 10px;
		}
		&[data-gap~="pc:5"] {
			--gap-top: 5px;
		}
	}
	@include mq("max") {
		&[data-gap~="sp:40"] {
			--gap-top: 40px;
		}
		&[data-gap~="sp:30"] {
			--gap-top: 30px;
		}
		&[data-gap~="sp:20"] {
			--gap-top: 20px;
		}
		&[data-gap~="sp:15"] {
			--gap-top: 15px;
		}
		&[data-gap~="sp:10"] {
			--gap-top: 10px;
		}
		&[data-gap~="sp:5"] {
			--gap-top: 5px;
		}
	}
}

/*dl
----------------------------------------*/
dl.c-list {
	&.\-vertical {
		.c-list__item {
			display: flex;
			align-items: center;
			& > dt {
				margin-right: 1rem;
			}
		}
	}
	.c-list__item {
		margin-top: 8px;
		& > dt {
			background-color: $color-graytone03;	
			padding: 0.5rem 1rem;
			font-weight: bold;
			display: inline-block;
		}
	}
	&:not(.-vertical ) {
		.c-list__item {
			& > dd {
				margin-top: 5px;
			}
		}
	}
	@include mq("max") {
		flex-direction: column;
		align-items: start;
		.c-list__item {
			margin-top: 5px;
			& > dt {
				margin-right: 0;
				margin-bottom: 0.5rem;
			}
		}
		&.\-vertical {
			.c-list__item {
				flex-direction: column;
				align-items: start;
				& > dt {
					margin-right: 0;
					margin-bottom: 0.5rem;
				}
			}
		}
		&:not(.-vertical ) {
			.c-list__item {
				& > dd {
					margin-top: 0;
				}
			}
		}
	}
}