@import "../foundation/setting";

.l-footer {
	position: relative;
	margin-top: 12rem;
	background-color: $color-graytone04;
	padding: 5rem 0 2rem 0;
}

.l-footer__inner {
	@include mq('max') {
	}
}

.l-footer__body {
	width: 100%;
	max-width: calc(1180px + 32rem);
	margin: auto;
	padding-right: 16rem;
	padding-left: 16rem;
	display: grid;
	grid-template-columns: auto 1fr auto;
	@include mq('max') {
		padding-right: 2rem;
		padding-left: 2rem;
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
	}
}

.l-footer__logo {
	padding-right: 7rem;
	@include mq('max', 'md') {
		padding-right: 0;
		margin-bottom: 2rem;
		text-align: center;
		img {
			width: 20%;
		}
	}
}

.l-footer__menu-nav-list-main {
	display: flex;
	flex-wrap: wrap;
	.l-footer__menu-nav-item {
		padding-left: 2.6rem;
		position: relative;
		margin-bottom: 1rem;
		transition: all .2s;
		&:hover {
			color: $color-maintone01;
		}
		&:not(:last-child) {
			padding-right: 5rem;
		}
		&::before {
			content: '';
			display: block;
			width: 1.3rem;
			height: 0.4rem;
			background-color: $color-maintone01;
			border-radius: 50px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}
	@include mq('max', 'md') {
		display: grid;
		grid-template-columns: auto auto;
		font-size: 1.4rem;
		margin-bottom: 2rem;
		.l-footer__menu-nav-item {
			padding-left: 2rem;
			&:not(:last-child) {
				padding-right: 0;
			}
		}
	}
}

.l-footer__menu-snav-list-sub {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	font-size: 1.4rem;
	.l-footer__menu-snav-item {
		padding-right: 5rem;
		transition: all .2s;
		&:hover {
			color: $color-maintone01;
		}
	}
	@include mq('max', 'md') {
		display: grid;
		grid-template-columns: auto auto;
		grid-row-gap: 1rem;
		.l-footer__menu-snav-item {
			&:not(:last-child) {
				padding-right: 0;
			}
		}
	}
}

.l-footer__icon-list-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	@include mq('max', 'md') {
		flex-direction: row;
		justify-content: center;
		img {
			&:first-child {
				margin-right: 1rem;
			}
		}
	}
}

/*copyright
---------------------------------------------------------- */
.l-footer__copyright {
	margin-top: 8rem;
	text-align: center;
	font-size: 1rem;
	@include mq('max') {
		margin-top: 4rem;
	}
}

.l-footer__copyright-small {
	font-size: 1.2rem;
	@include mq('max') {
		font-size: 1rem;
	}
}

/*pagetop
---------------------------------------------------------- */
.l-footer__page-top {
	position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 12rem;
    height: 12rem;
    margin: auto;
    text-align: center;
	transition: .3s;
	opacity: 0;
	z-index: 99;
	&.is-scroll-down {
		opacity: 1;
	}
	@include mq('max') {
		bottom: 1rem;
    	right: 1rem;
		width: 8rem;
   		height: 8rem;
	}
}

.l-footer__page-top-inner {
	svg {
		width: 1rem;
		fill: $color-maintone01;
		transform: rotate(180deg);
	}
}