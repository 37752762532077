@import "../foundation/setting";

.c-headline-lv2 {
	color: $color-maintone01;
	font-weight: bold;
	font-size: 3.6rem;
	@include line-height(58, 36);
	@include mq('max') {
		font-size: 2.4rem;
	}
	@include mq('max', 'md') {
		font-size: 2rem;
	}
}

.c-headline-lv3 {
	position: relative;
	z-index: 1;
	color: $color-maintone01;
	font-weight: bold;
	font-size: 2.4rem;
	@include line-height(42, 30);
	@include mq('max') {
		font-size: 2rem;
	}
	@include mq('max', 'md') {
		font-size: 1.8rem;
	}
}