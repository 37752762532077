@import "../foundation/setting";



/*all
---------------------------------------------------------- */
.u-margin-0    {margin:  0 !important;}



/*top
---------------------------------------------------------- */
.u-margin-t-0   {margin-top:  0 !important;}
.u-margin-t-1   {margin-top:  1px !important;}
.u-margin-t-2   {margin-top:  2px !important;}
.u-margin-t-3   {margin-top:  3px !important;}
.u-margin-t-4   {margin-top:  4px !important;}
.u-margin-t-5   {margin-top:  5px !important;}
.u-margin-t-10  {margin-top: 1rem !important;}
.u-margin-t-15  {margin-top: 1.5rem !important;}
.u-margin-t-20  {margin-top: 2rem !important;}
.u-margin-t-25  {margin-top: 2.5rem !important;}
.u-margin-t-30  {margin-top: 3rem !important;}
.u-margin-t-35  {margin-top: 3.5rem !important;}
.u-margin-t-40  {margin-top: 4rem !important;}
.u-margin-t-45  {margin-top: 4.5rem !important;}
.u-margin-t-50  {margin-top: 5rem !important;}
.u-margin-t-55  {margin-top: 5.5rem !important;}
.u-margin-t-60  {margin-top: 6rem !important;}
.u-margin-t-65  {margin-top: 6.5rem !important;}
.u-margin-t-70  {margin-top: 7rem !important;}
.u-margin-t-75  {margin-top: 7.5rem !important;}
.u-margin-t-80  {margin-top: 8rem !important;}
.u-margin-t-85  {margin-top: 8.5rem !important;}
.u-margin-t-90  {margin-top: 9rem !important;}
.u-margin-t-95  {margin-top: 9.5rem !important;}
.u-margin-t-100 {margin-top: 10rem !important;}


@include mq('min') {
	.u-margin-t-pc-0  {margin-top:  0 !important;}
	.u-margin-t-pc-1  {margin-top:  1px !important;}
	.u-margin-t-pc-2  {margin-top:  2px !important;}
	.u-margin-t-pc-3  {margin-top:  3px !important;}
	.u-margin-t-pc-4  {margin-top:  4px !important;}
	.u-margin-t-pc-5  {margin-top:  5px !important;}
	.u-margin-t-pc-10 {margin-top: 1rem !important;}
	.u-margin-t-pc-15 {margin-top: 1.5rem !important;}
	.u-margin-t-pc-20 {margin-top: 2rem !important;}
	.u-margin-t-pc-25 {margin-top: 2.5rem !important;}
	.u-margin-t-pc-30 {margin-top: 3rem !important;}
	.u-margin-t-pc-35 {margin-top: 3.5rem !important;}
	.u-margin-t-pc-40 {margin-top: 4rem !important;}
	.u-margin-t-pc-45 {margin-top: 4.5rem !important;}
	.u-margin-t-pc-50 {margin-top: 5rem !important;}
	.u-margin-t-pc-55  {margin-top:5.5rem !important;}
	.u-margin-t-pc-60 {margin-top: 6rem !important;}
	.u-margin-t-pc-65 {margin-top: 6.5rem !important;}
	.u-margin-t-pc-70 {margin-top: 7rem !important;}
	.u-margin-t-pc-75 {margin-top: 7.5rem !important;}
	.u-margin-t-pc-80 {margin-top: 8rem !important;}
	.u-margin-t-pc-85 {margin-top: 8.5rem !important;}
	.u-margin-t-pc-90 {margin-top: 9rem !important;}
	.u-margin-t-pc-95 {margin-top: 9.5rem !important;}
	.u-margin-t-pc-100 {margin-top: 10rem !important;}
}

@include mq('max') {
	.u-margin-t-sp-0  {margin-top:  0 !important;}
	.u-margin-t-sp-1  {margin-top:  1px !important;}
	.u-margin-t-sp-2  {margin-top:  2px !important;}
	.u-margin-t-sp-3  {margin-top:  3px !important;}
	.u-margin-t-sp-4  {margin-top:  4px !important;}
	.u-margin-t-sp-5  {margin-top:  5px !important;}
	.u-margin-t-sp-10 {margin-top: 1rem !important;}
	.u-margin-t-sp-15 {margin-top: 1.5rem !important;}
	.u-margin-t-sp-20 {margin-top: 2rem !important;}
	.u-margin-t-sp-25 {margin-top: 2.5rem !important;}
	.u-margin-t-sp-30 {margin-top: 3rem !important;}
	.u-margin-t-sp-35 {margin-top: 3.5rem !important;}
	.u-margin-t-sp-40 {margin-top: 4rem !important;}
	.u-margin-t-sp-45 {margin-top: 4.5rem !important;}
	.u-margin-t-sp-50 {margin-top: 5rem !important;}
	.u-margin-t-sp-55  {margin-top:5.5rem !important;}
	.u-margin-t-sp-60 {margin-top: 6rem !important;}
	.u-margin-t-sp-65 {margin-top: 6.5rem !important;}
	.u-margin-t-sp-70 {margin-top: 7rem !important;}
	.u-margin-t-sp-75 {margin-top: 7.5rem !important;}
	.u-margin-t-sp-80 {margin-top: 8rem !important;}
	.u-margin-t-sp-85 {margin-top: 8.5rem !important;}
	.u-margin-t-sp-90 {margin-top: 9rem !important;}
	.u-margin-t-sp-95 {margin-top: 9.5rem !important;}
	.u-margin-t-sp-100 {margin-top: 10rem !important;}
}



/*bottom
---------------------------------------------------------- */
.u-margin-b-0   {margin-bottom:  0 !important;}
.u-margin-b-1   {margin-bottom:  1px !important;}
.u-margin-b-2   {margin-bottom:  2px !important;}
.u-margin-b-3   {margin-bottom:  3px !important;}
.u-margin-b-4   {margin-bottom:  4px !important;}
.u-margin-b-5   {margin-bottom:  5px !important;}
.u-margin-b-10  {margin-bottom: 1rem !important;}
.u-margin-b-15  {margin-bottom: 1.5rem !important;}
.u-margin-b-20  {margin-bottom: 2rem !important;}
.u-margin-b-25  {margin-bottom: 2.5rem !important;}
.u-margin-b-30  {margin-bottom: 3rem !important;}
.u-margin-b-35  {margin-bottom: 3.5rem !important;}
.u-margin-b-40  {margin-bottom: 4rem !important;}
.u-margin-b-45  {margin-bottom: 4.5rem !important;}
.u-margin-b-50  {margin-bottom: 5rem !important;}
.u-margin-b-55  {margin-bottom: 5.5rem !important;}
.u-margin-b-60  {margin-bottom: 6rem !important;}
.u-margin-b-65  {margin-bottom: 6.5rem !important;}
.u-margin-b-70  {margin-bottom: 7rem !important;}
.u-margin-b-75  {margin-bottom: 7.5rem !important;}
.u-margin-b-80  {margin-bottom: 8rem !important;}
.u-margin-b-85  {margin-bottom: 8.5rem !important;}
.u-margin-b-90  {margin-bottom: 9rem !important;}
.u-margin-b-95  {margin-bottom: 9.5rem !important;}
.u-margin-b-100 {margin-bottom: 10rem !important;}
.u-margin-b-120 {margin-bottom: 12rem !important;}


@include mq('min') {
	.u-margin-b-pc-0  {margin-bottom:  0 !important;}
	.u-margin-b-pc-1  {margin-bottom:  1px !important;}
	.u-margin-b-pc-2  {margin-bottom:  2px !important;}
	.u-margin-b-pc-3  {margin-bottom:  3px !important;}
	.u-margin-b-pc-4  {margin-bottom:  4px !important;}
	.u-margin-b-pc-5  {margin-bottom:  5px !important;}
	.u-margin-b-pc-10 {margin-bottom: 1rem !important;}
	.u-margin-b-pc-15 {margin-bottom: 1.5rem !important;}
	.u-margin-b-pc-20 {margin-bottom: 2rem !important;}
	.u-margin-b-pc-25 {margin-bottom: 2.5rem !important;}
	.u-margin-b-pc-30 {margin-bottom: 3rem !important;}
	.u-margin-b-pc-35 {margin-bottom: 3.5rem !important;}
	.u-margin-b-pc-40 {margin-bottom: 4rem !important;}
	.u-margin-b-pc-45 {margin-bottom: 4.5rem !important;}
	.u-margin-b-pc-50 {margin-bottom: 5rem !important;}
	.u-margin-b-pc-55  {margin-bottom: 5.5rem !important;}
	.u-margin-b-pc-60 {margin-bottom: 6rem !important;}
	.u-margin-b-pc-65 {margin-bottom: 6.5rem !important;}
	.u-margin-b-pc-70 {margin-bottom: 7rem !important;}
	.u-margin-b-pc-75 {margin-bottom: 7.5rem !important;}
	.u-margin-b-pc-80 {margin-bottom: 8rem !important;}
	.u-margin-b-pc-85 {margin-bottom: 8.5rem !important;}
	.u-margin-b-pc-90 {margin-bottom: 9rem !important;}
	.u-margin-b-pc-95 {margin-bottom: 9.5rem !important;}
	.u-margin-b-pc-100 {margin-bottom: 10rem !important;}
	.u-margin-b-pc-120 {margin-bottom: 12rem !important;}
}

@include mq('max') {
	.u-margin-b-sp-0  {margin-bottom:  0 !important;}
	.u-margin-b-sp-1  {margin-bottom:  1px !important;}
	.u-margin-b-sp-2  {margin-bottom:  2px !important;}
	.u-margin-b-sp-3  {margin-bottom:  3px !important;}
	.u-margin-b-sp-4  {margin-bottom:  4px !important;}
	.u-margin-b-sp-5  {margin-bottom:  5px !important;}
	.u-margin-b-sp-10 {margin-bottom: 1rem !important;}
	.u-margin-b-sp-15 {margin-bottom: 1.5rem !important;}
	.u-margin-b-sp-20 {margin-bottom: 2rem !important;}
	.u-margin-b-sp-25 {margin-bottom: 2.5rem !important;}
	.u-margin-b-sp-30 {margin-bottom: 3rem !important;}
	.u-margin-b-sp-35 {margin-bottom: 3.5rem !important;}
	.u-margin-b-sp-40 {margin-bottom: 4rem !important;}
	.u-margin-b-sp-45 {margin-bottom: 4.5rem !important;}
	.u-margin-b-sp-50 {margin-bottom: 5rem !important;}
	.u-margin-b-sp-55  {margin-bottom: 5.5rem !important;}
	.u-margin-b-sp-60 {margin-bottom: 6rem !important;}
	.u-margin-b-sp-65 {margin-bottom: 6.5rem !important;}
	.u-margin-b-sp-70 {margin-bottom: 7rem !important;}
	.u-margin-b-sp-75 {margin-bottom: 7.5rem !important;}
	.u-margin-b-sp-80 {margin-bottom: 8rem !important;}
	.u-margin-b-sp-85 {margin-bottom: 8.5rem !important;}
	.u-margin-b-sp-90 {margin-bottom: 9rem !important;}
	.u-margin-b-sp-95 {margin-bottom: 9.5rem !important;}
	.u-margin-b-sp-100 {margin-bottom: 10rem !important;}
	.u-margin-b-sp-120 {margin-bottom: 12rem !important;}
}




/*right
---------------------------------------------------------- */
.u-margin-r-0   {margin-right: 0 !important;}
.u-margin-r-5   {margin-right: 5px !important;}
.u-margin-r-10  {margin-right: 1rem !important;}
.u-margin-r-15  {margin-right: 1.5rem !important;}
.u-margin-r-20  {margin-right: 2rem !important;}
.u-margin-r-25  {margin-right: 2.5rem !important;}
.u-margin-r-30  {margin-right: 3rem !important;}
.u-margin-r-35  {margin-right: 3.5rem !important;}
.u-margin-r-40  {margin-right: 4rem !important;}
.u-margin-r-45  {margin-right: 4.5rem !important;}
.u-margin-r-50  {margin-right: 5rem !important;}
.u-margin-r-55  {margin-right: 5.5rem !important;}
.u-margin-r-60  {margin-right: 6rem !important;}
.u-margin-r-65  {margin-right: 6.5rem !important;}
.u-margin-r-70  {margin-right: 7rem !important;}
.u-margin-r-75  {margin-right: 7.5rem !important;}
.u-margin-r-80  {margin-right: 8rem !important;}
.u-margin-r-85  {margin-right: 8.5rem !important;}
.u-margin-r-90  {margin-right: 9rem !important;}
.u-margin-r-95  {margin-right: 9.5rem !important;}
.u-margin-r-100 {margin-right: 10rem !important;}


@include mq('min') {
	.u-margin-r-pc-0  {margin-right:  0 !important;}
	.u-margin-r-pc-5  {margin-right:  5px !important;}
	.u-margin-r-pc-10 {margin-right: 1rem !important;}
	.u-margin-r-pc-15 {margin-right: 1.5rem !important;}
	.u-margin-r-pc-20 {margin-right: 2rem !important;}
	.u-margin-r-pc-25 {margin-right: 2.5rem !important;}
	.u-margin-r-pc-30 {margin-right: 3rem !important;}
	.u-margin-r-pc-35 {margin-right: 3.5rem !important;}
	.u-margin-r-pc-40 {margin-right: 4rem !important;}
	.u-margin-r-pc-45 {margin-right: 4.5rem !important;}
	.u-margin-r-pc-50 {margin-right: 5rem !important;}
	.u-margin-r-pc-55  {margin-right: 5.5rem !important;}
	.u-margin-r-pc-60 {margin-right: 6rem !important;}
	.u-margin-r-pc-65 {margin-right: 6.5rem !important;}
	.u-margin-r-pc-70 {margin-right: 7rem !important;}
	.u-margin-r-pc-75 {margin-right: 7.5rem !important;}
	.u-margin-r-pc-80 {margin-right: 8rem !important;}
	.u-margin-r-pc-85 {margin-right: 8.5rem !important;}
	.u-margin-r-pc-90 {margin-right: 9rem !important;}
	.u-margin-r-pc-95 {margin-right: 9.5rem !important;}
	.u-margin-r-pc-100 {margin-right: 10rem !important;}
}

@include mq('max') {
	.u-margin-r-sp-0  {margin-right:  0 !important;}
	.u-margin-r-sp-5  {margin-right:  5px !important;}
	.u-margin-r-sp-10 {margin-right: 1rem !important;}
	.u-margin-r-sp-15 {margin-right: 1.5rem !important;}
	.u-margin-r-sp-20 {margin-right: 2rem !important;}
	.u-margin-r-sp-25 {margin-right: 2.5rem !important;}
	.u-margin-r-sp-30 {margin-right: 3rem !important;}
	.u-margin-r-sp-35 {margin-right: 3.5rem !important;}
	.u-margin-r-sp-40 {margin-right: 4rem !important;}
	.u-margin-r-sp-45 {margin-right: 4.5rem !important;}
	.u-margin-r-sp-50 {margin-right: 5rem !important;}
	.u-margin-r-sp-55  {margin-right: 5.5rem !important;}
	.u-margin-r-sp-60 {margin-right: 6rem !important;}
	.u-margin-r-sp-65 {margin-right: 6.5rem !important;}
	.u-margin-r-sp-70 {margin-right: 7rem !important;}
	.u-margin-r-sp-75 {margin-right: 7.5rem !important;}
	.u-margin-r-sp-80 {margin-right: 8rem !important;}
	.u-margin-r-sp-85 {margin-right: 8.5rem !important;}
	.u-margin-r-sp-90 {margin-right: 9rem !important;}
	.u-margin-r-sp-95 {margin-right: 9.5rem !important;}
	.u-margin-r-sp-100 {margin-right: 10rem !important;}
}



/*left
---------------------------------------------------------- */
.u-margin-l-0   {margin-left: 0 !important;}
.u-margin-l-5   {margin-left: 5px !important;}
.u-margin-l-10  {margin-left:1rem !important;}
.u-margin-l-15  {margin-left:1.5rem !important;}
.u-margin-l-20  {margin-left:2rem !important;}
.u-margin-l-25  {margin-left:2.5rem !important;}
.u-margin-l-30  {margin-left:3rem !important;}
.u-margin-l-35  {margin-left:3.5rem !important;}
.u-margin-l-40  {margin-left:4rem !important;}
.u-margin-l-45  {margin-left:4.5rem !important;}
.u-margin-l-50  {margin-left:5rem !important;}
.u-margin-l-55  {margin-left:5.5rem !important;}
.u-margin-l-60  {margin-left:6rem !important;}
.u-margin-l-65  {margin-left:6.5rem !important;}
.u-margin-l-70  {margin-left:7rem !important;}
.u-margin-l-75  {margin-left:7.5rem !important;}
.u-margin-l-80  {margin-left:8rem !important;}
.u-margin-l-85  {margin-left:8.5rem !important;}
.u-margin-l-90  {margin-left:9rem !important;}
.u-margin-l-95  {margin-left:9.5rem !important;}
.u-margin-l-100 {margin-left:10rem !important;}


@include mq('min') {
	.u-margin-l-pc-0  {margin-left:  0 !important;}
	.u-margin-l-pc-5  {margin-left:  5px !important;}
	.u-margin-l-pc-10 {margin-left: 1rem !important;}
	.u-margin-l-pc-15 {margin-left: 1.5rem !important;}
	.u-margin-l-pc-20 {margin-left: 2rem !important;}
	.u-margin-l-pc-25 {margin-left: 2.5rem !important;}
	.u-margin-l-pc-30 {margin-left: 3rem !important;}
	.u-margin-l-pc-35 {margin-left: 3.5rem !important;}
	.u-margin-l-pc-40 {margin-left: 4rem !important;}
	.u-margin-l-pc-45 {margin-left: 4.5rem !important;}
	.u-margin-l-pc-50 {margin-left: 5rem !important;}
	.u-margin-l-pc-55  {margin-left: 5.5rem !important;}
	.u-margin-l-pc-60 {margin-left: 6rem !important;}
	.u-margin-l-pc-65 {margin-left: 6.5rem !important;}
	.u-margin-l-pc-70 {margin-left: 7rem !important;}
	.u-margin-l-pc-75 {margin-left: 7.5rem !important;}
	.u-margin-l-pc-80 {margin-left: 8rem !important;}
	.u-margin-l-pc-85 {margin-left: 8.5rem !important;}
	.u-margin-l-pc-90 {margin-left: 9rem !important;}
	.u-margin-l-pc-95 {margin-left: 9.5rem !important;}
	.u-margin-l-pc-100 {margin-left: 10rem !important;}
}

@include mq('max') {
	.u-margin-l-sp-0  {margin-left:  0 !important;}
	.u-margin-l-sp-5  {margin-left:  5px !important;}
	.u-margin-l-sp-10 {margin-left: 1rem !important;}
	.u-margin-l-sp-15 {margin-left: 1.5rem !important;}
	.u-margin-l-sp-20 {margin-left: 2rem !important;}
	.u-margin-l-sp-25 {margin-left: 2.5rem !important;}
	.u-margin-l-sp-30 {margin-left: 3rem !important;}
	.u-margin-l-sp-35 {margin-left: 3.5rem !important;}
	.u-margin-l-sp-40 {margin-left: 4rem !important;}
	.u-margin-l-sp-45 {margin-left: 4.5rem !important;}
	.u-margin-l-sp-50 {margin-left: 5rem !important;}
	.u-margin-l-sp-55  {margin-left: 5.5rem !important;}
	.u-margin-l-sp-60 {margin-left: 6rem !important;}
	.u-margin-l-sp-65 {margin-left: 6.5rem !important;}
	.u-margin-l-sp-70 {margin-left: 7rem !important;}
	.u-margin-l-sp-75 {margin-left: 7.5rem !important;}
	.u-margin-l-sp-80 {margin-left: 8rem !important;}
	.u-margin-l-sp-85 {margin-left: 8.5rem !important;}
	.u-margin-l-sp-90 {margin-left: 9rem !important;}
	.u-margin-l-sp-95 {margin-left: 9.5rem !important;}
	.u-margin-l-sp-100 {margin-left: 10rem !important;}
}



/*auto
---------------------------------------------------------- */
.u-margin-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}


@include mq('min') {
	.u-margin-auto-pc {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

@include mq('max') {
	.u-margin-auto-sp {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}
