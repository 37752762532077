@import "../foundation/setting";


.o-ratio {
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
}

.o-ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: block;
	width: 100%;
	height: 100%;
	margin: auto;
	border-radius: inherit;
}

.o-ratio > img,
.o-ratio > video,
.o-ratio > picture img {
	@include object-fit(cover, center center);
}

.o-ratio.\--ratio-2by3 {
	padding-top: 150%;
}

.o-ratio.\--ratio-3by4 {
	padding-top: 133.3333333333%;
}

.o-ratio.\--ratio-6by5 {
	padding-top: 120%;
}

.o-ratio.\--ratio-1by1 {
	padding-top: 100%;
}

.o-ratio.\--ratio-4by3 {
	padding-top: 75%;
}

.o-ratio.\--ratio-3by2 {
	padding-top: 66.6666666667%;;
}

.o-ratio.\--ratio-16by9 {
	padding-top: 56.25%;
}

.o-ratio.\--ratio-2by1 {
	padding-top: 50%;
}

.o-ratio.\--portrait {
	background-color: $color-graytone04;
	& > img,
	& > picture img {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		display: block;
		width: auto;
		height: 100%;
		margin: auto;
		border-radius: inherit;
		transform:translate(-50%, -50%);
	}
}

.o-frame {
	.o-ratio.\--portrait {
		background-color: #fff;
	}
}
