@import "../foundation/setting";

.c-search {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-column-gap: 5rem;
    @include mq('max') {
        grid-template-columns: 1fr;
    }
}

.c-search__label {
	font-size: 1.8rem;
	font-weight: bold;
	padding-left: 2.6rem;
	position: relative;
	margin-bottom: 1rem;
    display: block;
    line-height: 1.5;
	&::before {
		content: '';
		display: block;
		width: 1.3rem;
		height: 0.4rem;
		background-color: $color-maintone01;
		border-radius: 50px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
}


.c-search {
    select,
    input[type="text"] {
        width: 100%;
        background-color: $color-white;
        border: 1px solid #5CB6CE;
        border-radius: 0.8rem;
        padding: 1.8rem 2.5rem;
    }
    select {
        -webkit-appearance: none;
        appearance: none;
    }
    select::-ms-expand {
        display: none;
    }
}

.c-search {
    input[type="checkbox"] {
        padding-left: 30px;
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &::before,
        &::after {
            content: "";
            display: block; 
            position: absolute;
        }
        &::before {
            width: 24px;
            height: 24px;
            background-color: $color-white;
			border: 1px solid $color-graytone01;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
        }
        &::after {
            border-bottom: 3px solid $color-maintone01;
            border-left: 3px solid $color-maintone01;
            opacity: 0;
            height: 0.6rem;
            width: 1.1rem;
            transform: rotate(-45deg);
            top: 5px;
            left: 6px;
            @include mq('max') {
                top: 0.5rem;
            }
        }
        &:checked {
            &::after {
                opacity: 1;
            }
        }
    }
    .c-form__checkbox-wrap {
        .c-form__checkbox-item {
            margin-bottom: 2rem;
            align-items: center;
            &:first-child {
                margin-top: 2rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        } 
    }
}

.c-search__item-input {
    &.\-select {
        position: relative;
        &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 8px solid $color-maintone01;
            content: "";
            position: absolute;
            right: 9px;
            top: 50%;
            width: 0;
            transform: translateY(-50%);
        }
    }
}

.c-search__checkbox-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 4rem;
	grid-row-gap: 3rem;
	background-color: $color-white;
	border: 1px solid $color-maintone01;
	border-radius: 0.8rem;
	padding: 3rem;
    @include mq('max') {
        grid-template-columns: 1fr;
        grid-row-gap: 2rem;
    }
}

.c-search__button {
	display: flex;
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	background-color: $color-maintone01;
	color: $color-white;
	padding: 2rem 0;
    transition: all 0.2s;
	i {
		font-size: 2.3rem;
	}
	&:hover {
		opacity: 0.7;
	}
}

.c-search__button-text {
	padding-left: 1.5rem;
	font-weight: bold;
}