@import "../foundation/setting";

.c-form-flow__list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15%;
    margin-top: 3rem;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        background-color: $color-maintone01;
        height: 2px;
        width: 100%;
    }
    @include mq('max', 'md') {
        grid-column-gap: 5%;
    }
}

.c-form-flow__item {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    line-height: 1;
    background-color: $color-graytone01;
    &.\-is-page {
        &::before {
            content: '';
            position: absolute;
            top: -2.4rem;
            right: 50%;
            transform: translateX(50%);
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            background-color: $color-maintone01;
            @include mq('max', 'md') {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}

.c-form-flow__item-en {
    font-family: $font-en;
    font-size: 2rem;
    margin-bottom: 1rem;
    @include mq('max', 'md') {
        font-size: 1.6rem;
    }
}

.c-form-flow__item-num {
    font-size: 3rem;
    @include mq('max', 'md') {
        font-size: 2rem;
    }
}

.c-form-flow__item-ja {
    font-size: 2.4rem;
    font-weight: $weight-bold;
    @include mq('max', 'md') {
        font-size: 1.8rem;
    }
}

.c-form {
    select,
    input[type="text"],
    textarea {
        background-color: #fff;
        border: 1px solid $color-graytone01;
        border-radius: 6px;
        width: 100%;
        padding: 1.5rem 3rem;
        margin-top: 1rem;
        display: block;
        font-size: 1.6rem;
        box-sizing: border-box;
        @include mq('max') {
            font-size: 1.4rem;
            width: 100%;
        }
    }
    input[type="text"] {
        @include mq('max') {
            font-size: 1.4rem;
            padding: 1.4rem;
        }
    }
    .c-form__item-input {
        &.\-error {
            select,
            input[type="text"],
            textarea {
                border: 1px solid $color-red;
            }
        }
    }
    textarea {
        resize: vertical;
    }
    select {
        appearance: none;
        text-align: center;
        &::-ms-expand {
            display: none; //IE
        }
    }
}

input[type="text"],
textarea,
select {
    &.c-form__valid {
        border: 1px solid $color-maintone01;
        background-color: #eaecf2;
    }
    &.c-form__invalid {
        border: 1px solid $color-red;
    }
}

.c-form {
    input[type="checkbox"] {
        padding-left: 40px;
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &::before,
        &::after {
            content: "";
            display: block; 
            position: absolute;
        }
        &::before {
            width: 24px;
            height: 24px;
            background-color: $color-white;
            border: 1px solid $color-graytone01;
            transform: translateY(-50%);
            top: 50%;
            left: 0;
        }
        &::after {
            border-bottom: 3px solid $color-maintone01;
            border-left: 3px solid $color-maintone01;
            opacity: 0;
            height: 0.6rem;
            width: 1.1rem;
            transform: rotate(-45deg);
            top: 5px;
            left: 6px;
            @include mq('max') {
                top: 0.5rem;
            }
        }
        &:checked {
            &::after {
                opacity: 1;
            }
        }
    }
    .c-form__item-select {
        position: relative;
        &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 8px solid $color-maintone01;
            content: "";
            position: absolute;
            right: 9px;
            top: 50%;
            width: 0;
            transform: translateY(-50%);
        }
    }
    .c-form__item-radio {
        display: flex;
        flex-wrap: wrap;
        gap: .3em 2em;
        border: none;
        .c-form__item-radio-item {
            display: flex;
            align-items: center;
        }
        label {
            display: flex;
            align-items: center;
            gap: 0 .5em;
            position: relative;
            cursor: pointer;
            &::before,
            &::after {
                border-radius: 50%;
                content: '';
            }
            &::before {
                width: 28px;
                height: 28px;
                border: 2px solid #AAAAAA;
                box-sizing: border-box;
                background-color: $color-white;
            }
            &::after {
                position: absolute;
                top: 50%;
                left: 14px;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
            }
            &:has(:checked)::after {
                background-color: $color-maintone01;
            }
            input[type="radio"] {
                display: none;
            }
        }
        input:checked + label::after {
            background-color: $color-maintone01;
        }
    }
    .radio-002 {
        display: flex;
        flex-wrap: wrap;
        gap: .3em 2em;
        border: none;
    }
    .c-form__checkbox-wrap {
        .c-form__checkbox-item {
            margin-bottom: 2rem;
            align-items: center;
            &:first-child {
                margin-top: 2rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        } 
    }
    .c-form__privacy {
        .c-form__checkbox-item {
            justify-content: center;
            align-items: center;
            @include mq('max') {
                justify-content: left;
            }
        }
        input[type="checkbox"] {
            &::before {
                border: 2px solid $color-maintone01;
            }
            &::after {
                left: 7px;
                @include mq('max') {
                    top: auto;
                    bottom: 9px;
                }
            }
        }
    }
}

.c-form__item {
    position: relative;
}

.c-form__item-error {
    color: $color-red;
    font-weight: bold;
}

.c-form__checkbox-item {
    label {
        display: flex;
    }
}

.c-form__checkbox-text {
    display: block;
    line-height: 1.5;
}

.c-form__label {
    font-weight: $weight-bold;
}

.c-form__input-label {
    font-size: 1.3rem;
    padding: 0 0.5rem;
    border-radius: 6px;
    &.\-required {
        color: $color-white;
        background-color: $color-red;
        border: 1px solid $color-red;
    }
    &.\-optional {
        color: $color-maintone01;
        background-color: $color-white;
        border: 1px solid $color-maintone01;
    }
}




.c-form__required {
    color: $color-red;
    border: 1px solid $color-red;
}
.c-form__optional {
    color: $color-maintone01;
    border: 1px solid $color-maintone01;
}

.c-form__text {
    &:first-of-type {
        margin-top: 1rem;
    }
    &.-error {
        color: $color-red;
    }
}

.c-form__item-input {
    position: relative;
}

.c-form__checkbox-item {
    display: flex;
    align-items: center;
}

.c-form__valid-label,
.c-form__invalid-label {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    line-height: 1;
    color: $color-white;
    font-weight: $weight-bold;
}

.c-form__valid-label {
    border: 1px solid $color-maintone01;
    background-color: $color-maintone01;
}

.c-form__invalid-label {
    border: 1px solid $color-red;
    background-color: $color-red;
}


.c-form__privacy {
    background-color: $color-white;
    padding: 4rem;
    text-align: center;
    margin: 10rem 0;
    @include mq('max') {
        text-align: left;
        padding: 3rem 2rem;
    }
}

.c-form__privacy-text {
    margin-bottom: 3.5rem;
    a {
        text-decoration: underline;
    }
    @include mq('max') {
        margin-bottom: 2rem;
    }
}

.c-form__confilm-text {
    padding-top: 1rem;
}

.c-thanks {
    position: relative;
    margin-top: 15rem;
    margin-bottom: 27rem;
    @include mq('max') {
        margin-top: 8rem;
        margin-bottom: 5rem;
    }
}

.c-thanks__img {
    position: absolute;
    max-width: 1920px;
    width: 100vw;
    top: -3rem;
    right: 50%;
    transform: translateX(50%);
    padding: 0 6.4rem;
    z-index: -1;
    @include mq('max') {
        top: auto;
        bottom: -3rem;
        padding: 0 2rem;
    }
}

.c-form__btn-wrap {
    display: flex;
    justify-content: center;
    .c-button-lv3 {
        margin-right: 3rem;
        margin-left: 3rem;
    }
    @include mq('max', 'md') {
        display: block;
        text-align: center;
        .c-button-lv3 {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 1rem;
        }
    }
}

.c-login-form__error {
    display: flex;
    align-items: center;
    color: $color-red;
    font-weight: bold;
    img {
        margin-right: 8px;
    }
}

.c-form__privacy-box {
    background-color: $color-white;
    padding: 3rem;
    width: 100%;
    height: 30rem;
    overflow-y: scroll;
    @include mq('max') {
        padding: 2rem 1rem;
    }
}

/* ポップアップフォーム */

.c-form-icon-plus {
    color: $color-white;
    display: block;
    width: 3rem;
	height: 3rem;
    border-radius: 50%;
	position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    background-color: $color-maintone01;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 1.4rem;
        right: 1rem;
        width: 1rem;
        height: 0.2rem;
        border-radius: 50px;
        background-color: currentColor;
    }
    &::after {
        transform: rotate(90deg);
    }
    @include mq('max') {
        right: 1rem;
    }
}

.c-search-form__item-modal-wrap {
    display: grid;
    grid-template-columns: 30rem 1fr;
    grid-column-gap: 2rem;
    align-items: center;
    @include mq('max') {
        grid-template-columns: 1fr;
    }
}

.modaal-content-container {
    .c-form {
        background-color: transparent;
        padding: 0;
        border-radius: none;
        margin-bottom: 0;
    }
    .c-table {
        @include mq("max") {
            &.-pattern2 {
                &.-vertical-sp {
                    & > tbody > tr > td {
                        padding-left: 4rem;
                    }
                }
            }
        }
    }
}

.c-modal-btn {
    background-color: $color-white;
    border: 1px solid $color-graytone01;
    border-radius: 6px;
    display: block;
    max-width: 30rem;
    width: 100%;
    text-align: center;
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    font-size: 1.6rem;
    position: relative;
    @include mq('max') {
        font-size: 1.2rem;
        padding: 1.5rem 4rem 1.5rem 1.5rem;
    }
}