@import "../foundation/setting";

.js-tab__trigger,
.js-tab__sub-trigger {
	cursor: pointer;
}

.js-tab__content,
.js-tab__sub-content {
	height: 0;
	overflow: hidden;
}

.js-tab__content[data-link-current="true"] {
	height: auto;
}

button {
	&.c-tab-genre__target {
		background-color: transparent;
		border: none;
		outline: none;
		appearance: none;
		&[disabled] {
			cursor: unset;
		}
	}
}

.c-tab__trigger-wrap {
    display: flex;
	justify-content: space-between;
	gap: 5px;
    .c-tab__trigger {
        text-align: center;
        padding: 2rem;
        font-size: 1.8rem;
        font-weight: bold;
        color: $color-white;
		border-radius: 20px 20px 0 0;
		position: relative;
		transition: all .3s;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.5;
		width: 100%;
        &[data-link-current="true"] {
            background-color: $color-white;
			color: $color-maintone01;
			border: 1px solid $color-maintone01;
			border-bottom: 1px solid $color-white;
			border-bottom: none;
			transform: translateY(1px);
			z-index: 2;
        }
        &[data-link-current="false"] {
			background-color: $color-maintone01;
			border: 1px solid $color-maintone01;
            color: $color-white;
			transform: translateY(10px);
			&:hover {
				transform: translateY(0);
			}
        }
    }
	@include mq('max', 'md') {
		margin: 0 10px;
		.c-tab__trigger {
			font-size: 1.4rem;
			padding: 1rem;
		}
	}
	@include mq('max', 'sm') {
		margin: 0;
		.c-tab__trigger {
			font-size: 1.4rem;
			padding: 1rem 0.2rem;
		}
	}
}

.c-tab__content {
	position: relative;
	z-index: 1;
	background-color: $color-white;
	&[data-link-current="true"] {
		border: 1px solid $color-maintone01;
		border-radius: 0 0 20px 20px;
	}
}

.c-tab__content-inner {
	padding: 6rem;
	@include mq('max') {
		padding: 4rem 2rem;
	}
}