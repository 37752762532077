@import "../foundation/setting";

.l-root {
	overflow: hidden;
}

.l-container {
	@include mq('max', 'lg') {
		padding: 0;
	}
}

.l-main {
	margin-top: 11.8rem;
    @include mq("max") {
        margin-top: 7rem; 
	}
}