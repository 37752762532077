@import "../foundation/setting";

.c-page-header {
    position: relative;
    background-color: $color-maintone01;
}

.c-page-title {

}

.c-page-header__inner {
    height: 42rem;
    border-bottom: 1px solid $color-white;
    @include mq("max") {
        height: auto;  
        border-bottom: none; 
	}
}

.c-breadcrumb {
    padding: 2rem 0;
    padding-bottom: 7rem;
    @include mq("max") {
        padding-bottom: 3rem;
	}
}

.c-breadcrumb__list {
    display: flex;
    @include mq("max") {
        overflow-x: auto;
        overflow-y: hidden;
        font-size: 0;
        white-space: nowrap;
	}
}

.c-breadcrumb__item {
    position: relative;
    font-size: 14px;
    color: #777777;
    padding-right: 2rem;
    margin-right: 1.5rem;
    &:after {
        content: '';
        background-image: url(../img/common/breadcrumb-arrow-right.png);
        background-position: center;
        background-repeat: no-repeat;
        width: 5px;
        height: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
    a {
        color: $color-maintone01;
    }
    i {
        margin-right: 2rem;
    }
    &:last-child:after {
        content: none;
    }
    @include mq("max") {
        font-size: 12px;
        flex-shrink: 0;
    }
}

.c-page__title {
    position: relative;
    padding: 7rem 0;
    @include mq("max", "xxl") {
	}
    @include mq("max") {
        padding: 4rem 0;
	}
    @include mq("max", "sm") {
    }
}

.c-page__title-ja {
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    letter-spacing: 0.075rem;
    z-index: 1;
    @include mq("max") {
        font-size: 2rem;
	}
}

.c-page__title-en {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    letter-spacing: 0.02em;
    font-size: 16rem;
    line-height: 0.7;
    opacity: 0.1;
    color: $color-white;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    @include mq("max") {
        font-size: 15vw;
	}
}