@import "../foundation/setting";

[class^="c-icon-"],
[class*="c-icon-"] {
	font-weight: normal;
	font-family: "icomoon";
	font-style: normal;
	font-variant: normal;
	font-variant: normal;
	line-height: 1;
	letter-spacing: 0;
	text-transform: none;
	speak: none;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c-icon-arrow-right:before {
	content: icon($icon-arrow-right);
}

.c-icon-arrow-left:before {
	content: icon($icon-arrow-left);
}

.c-icon-arrow-top:before {
	content: icon($icon-arrow-top);
}

.c-icon-human:before {
	content: icon($icon-human);
}

.c-icon-search:before {
	content: icon($icon-search);
}

.c-icon-key:before {
	content: icon($icon-key);
}

.c-icon-key-open:before {
	content: icon($icon-key-open);
}

.c-icon-download:before {
	content: icon($icon-download);
}

.c-icon-upload:before {
	content: icon($icon-upload);
}

.c-icon-star:before {
	content: icon($icon-star);
}